import React from 'react';
import _ from 'lodash';

import { ApolloClient } from '../../api/graphql/client';
import { Feature, useFeatureMap } from '../../lib/features/buildFeatureMap';
import { getMerchantName } from '../../lib/merchants/merchants';
import { getWebSocketApiUrl } from '../../lib/products/websockets';
import { getLocaleCode } from '../../Locales';

type ProductDiscoveryConversationMessage = {
    id: string;
    content: string;
    role: 'system' | 'user' | 'assistant';
    timestamp: number;
};
export interface MerchantProductOffer {
    merchantId: string | null;
    merchantProductOfferId: string | null;
    originalTitle: string;
    processedWebpageUrl: string | null;
    mainImageUrls: {
        defaultSize: string;
    };
    priceInformation: {
        displayPriceAmount: {
            currency: string;
            valueInCents: number;
        };
    };
    contextualInformation: {
        originalMerchantName: string | null;
    };
}

export type ProductDiscoveryConversationMessageWithProducts = ProductDiscoveryConversationMessage & {
    merchantProductOffers?: MerchantProductOffer[];
};

interface AssistantStreamResponse {
    chunk: string;
}

type GetProductDiscoveryConversationIdPayload = {
    action: 'getProductDiscoveryConversationId';
    userId: string | undefined;
};

type CloseProductDiscoveryConversationPayload = {
    action: 'closeProductDiscoveryConversation';
    userId: string | undefined;
    conversationId: string;
};

type StreamProductDiscoveryMessagePayload = {
    action: 'streamProductDiscoveryMessage';
    userId: string | undefined;
    timestamp: number;
    conversationId: string;
    message: string;
    localeCode: string;
    shouldLogAllData: boolean;
};

type WebSocketMessagePayload =
    | GetProductDiscoveryConversationIdPayload
    | CloseProductDiscoveryConversationPayload
    | StreamProductDiscoveryMessagePayload;

function sendWebSocketMessage({ webSocket, payload }: { webSocket: WebSocket; payload: WebSocketMessagePayload }) {
    webSocket.send(JSON.stringify(payload));
}

export function useHandleConnectionWithAssistant({
    userId,
    webSocketRef,
    messageIdCounterRef,
    setMessages,
    setIsAssistantThinking,
    conversationId,
    setConversationId,
}: {
    userId: string | undefined;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
    messageIdCounterRef: React.MutableRefObject<number>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[]>>;
    setIsAssistantThinking: (value: boolean) => void;
    conversationId: string;
    setConversationId: (value: string) => void;
}) {
    const featureMap = useFeatureMap();
    const isSandboxEnvironment = !!featureMap?.[Feature.useProductDiscoverySandboxEnvironment];
    const currentAssistantMessageRef = React.useRef<ProductDiscoveryConversationMessageWithProducts | undefined>(undefined);
    /**
     * If the web socket is not connected when the user tries to send a message for some reason,
     * we force a reconnection and rely on the `startPayload` to send the message right after.
     */
    function initializeWebSocket({ startPayload }: { startPayload?: WebSocketMessagePayload }) {
        if (!userId) return;
        if (isSandboxEnvironment) console.log('Initializing WebSocket connection...');
        const websocketUrl = getWebSocketApiUrl();
        const webSocket = new WebSocket(websocketUrl + '?userId=' + userId);
        webSocketRef.current = webSocket;
        webSocket.onopen = () => handleWebSocketOpen({ webSocket, userId, startPayload, isSandboxEnvironment });
        webSocket.onmessage = (event: MessageEvent) =>
            handleWebSocketMessage({
                event,
                isSandboxEnvironment,
                setConversationId,
                currentAssistantMessageRef,
                messageIdCounterRef,
                setMessages,
                setIsAssistantThinking,
            });
        webSocket.onerror = (error) => {
            if (isSandboxEnvironment) console.error('WebSocket error:', error);
        };
        webSocket.onclose = (event: CloseEvent) => handleWebSocketClose({ event, isSandboxEnvironment });
    }
    function forceWebSocketReconnection({
        conversationIdToClose,
        startPayload,
    }: {
        conversationIdToClose?: string;
        startPayload?: WebSocketMessagePayload;
    }) {
        reconnectWebSocket({
            userId,
            webSocketRef,
            initializeWebSocket,
            setConversationId,
            conversationIdToClose,
            startPayload,
        });
    }
    React.useEffect(() => {
        if (!userId) return;
        initializeWebSocket({});
    }, [userId]);
    React.useEffect(() => {
        if (!userId || !conversationId) return;
        const handleCloseConversationBeforeUnload = () => {
            if (webSocketRef.current?.readyState === WebSocket.OPEN) {
                sendWebSocketMessage({
                    webSocket: webSocketRef.current,
                    payload: {
                        action: 'closeProductDiscoveryConversation',
                        userId,
                        conversationId,
                    },
                });
                // We add a 1000 code to the close method to indicate a normal closure
                webSocketRef.current.close(1000);
            }
        };
        window.addEventListener('beforeunload', () => handleCloseConversationBeforeUnload());
    }, [userId, conversationId]);
    return { forceWebSocketReconnection };
}

function handleWebSocketOpen({
    webSocket,
    userId,
    startPayload,
    isSandboxEnvironment,
}: {
    webSocket: WebSocket;
    userId: string;
    startPayload?: WebSocketMessagePayload;
    isSandboxEnvironment: boolean;
}) {
    if (startPayload) {
        // If we have a `startPayload` it means we want to continue the conversation with the same `conversationId`
        sendWebSocketMessage({ webSocket, payload: startPayload });
        return;
    }
    if (isSandboxEnvironment) console.log('Fetching a new conversation ID...');
    sendWebSocketMessage({
        webSocket,
        payload: {
            action: 'getProductDiscoveryConversationId',
            userId,
        },
    });
}

function handleWebSocketMessage({
    event,
    isSandboxEnvironment,
    setConversationId,
    currentAssistantMessageRef,
    messageIdCounterRef,
    setMessages,
    setIsAssistantThinking,
}: {
    event: MessageEvent;
    isSandboxEnvironment: boolean;
    setConversationId: (value: string) => void;
    currentAssistantMessageRef: React.MutableRefObject<ProductDiscoveryConversationMessageWithProducts | undefined>;
    messageIdCounterRef: React.MutableRefObject<number>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[]>>;
    setIsAssistantThinking: (value: boolean) => void;
}) {
    const data = JSON.parse(event.data);
    if (data.productDiscoveryConversationId) {
        if (isSandboxEnvironment)
            console.log('Received product discovery conversation ID:', data.productDiscoveryConversationId);
        setConversationId(data.productDiscoveryConversationId);
    } else if ((data.content && data.role === 'assistant') || isAssistantStreamResponse(data))
        handleAssistantMessage({
            response: data,
            isSandboxEnvironment,
            currentAssistantMessageRef,
            messageIdCounterRef,
            setMessages,
            setIsAssistantThinking,
        });
    else if (isSandboxEnvironment) console.warn('Unknown message format:', JSON.stringify(data));
}

function handleWebSocketClose({ event, isSandboxEnvironment }: { event: CloseEvent; isSandboxEnvironment: boolean }) {
    if (isSandboxEnvironment) {
        if (event.code === 1000) console.log(`WebSocket closed with code: ${event.code}`);
        else console.error(`WebSocket closed with code: ${event.code}`);
    }
}

function reconnectWebSocket({
    userId,
    webSocketRef,
    initializeWebSocket,
    setConversationId,
    conversationIdToClose,
    startPayload,
}: {
    userId: string | undefined;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
    initializeWebSocket: ({ startPayload }: { startPayload?: WebSocketMessagePayload }) => void;
    setConversationId: (value: string) => void;
    conversationIdToClose?: string;
    startPayload?: WebSocketMessagePayload;
}) {
    if (webSocketRef.current) {
        if (conversationIdToClose) {
            sendWebSocketMessage({
                webSocket: webSocketRef.current,
                payload: {
                    action: 'closeProductDiscoveryConversation',
                    userId,
                    conversationId: conversationIdToClose,
                },
            });
            setConversationId('');
        }
        // We add a 1000 code to the close method to indicate a normal closure
        webSocketRef.current.close(1000);
    }
    initializeWebSocket({ startPayload });
}

/**
 * The API sends back either chunks of a message when sending back the streamed assistant response,
 * or the full message when the assistant has finished processing the user's message. The full message also
 * contains the merchant product offers, that we use to display the products within the conversation.
 */
function handleAssistantMessage({
    response,
    isSandboxEnvironment,
    currentAssistantMessageRef,
    messageIdCounterRef,
    setMessages,
    setIsAssistantThinking,
}: {
    response: ProductDiscoveryConversationMessageWithProducts | AssistantStreamResponse;
    isSandboxEnvironment: boolean;
    currentAssistantMessageRef: React.MutableRefObject<ProductDiscoveryConversationMessageWithProducts | undefined>;
    messageIdCounterRef: React.MutableRefObject<number>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[]>>;
    setIsAssistantThinking: (value: boolean) => void;
}) {
    if (isAssistantStreamResponse(response))
        handleStreamAssistantMessage({
            response,
            currentAssistantMessageRef,
            messageIdCounterRef,
            setMessages,
        });
    else if (response.content && response.role === 'assistant')
        handleFullAssistantMessage({
            response,
            isSandboxEnvironment,
            currentAssistantMessageRef,
            setMessages,
            setIsAssistantThinking,
        });
    else if (isSandboxEnvironment) console.warn('Received assistant content but no current assistant message:', response);
}

function isAssistantStreamResponse(response: any): response is AssistantStreamResponse {
    return _.has(response, 'chunk');
}

function handleStreamAssistantMessage({
    response,
    currentAssistantMessageRef,
    messageIdCounterRef,
    setMessages,
}: {
    response: AssistantStreamResponse;
    currentAssistantMessageRef: React.MutableRefObject<ProductDiscoveryConversationMessageWithProducts | undefined>;
    messageIdCounterRef: React.MutableRefObject<number>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[]>>;
}) {
    if (!response.chunk) return;
    if (currentAssistantMessageRef.current === undefined) {
        const assistantMessage: ProductDiscoveryConversationMessageWithProducts = {
            id: messageIdCounterRef.current.toString(),
            timestamp: Date.now(),
            content: response.chunk,
            role: 'assistant',
        };
        currentAssistantMessageRef.current = assistantMessage;
        setMessages((prevMessages) =>
            prevMessages.map((message) =>
                message.id === currentAssistantMessageRef.current?.id ? assistantMessage : message
            )
        );
    } else {
        const updatedMessage = {
            ...currentAssistantMessageRef.current,
            content: (currentAssistantMessageRef.current.content || '') + response.chunk,
        };
        currentAssistantMessageRef.current = updatedMessage;
        setMessages((prevMessages) =>
            prevMessages.map((message) => (message.id === currentAssistantMessageRef.current?.id ? updatedMessage : message))
        );
    }
}

function handleFullAssistantMessage({
    response,
    isSandboxEnvironment,
    currentAssistantMessageRef,
    setMessages,
    setIsAssistantThinking,
}: {
    response: ProductDiscoveryConversationMessageWithProducts;
    isSandboxEnvironment: boolean;
    currentAssistantMessageRef: React.MutableRefObject<ProductDiscoveryConversationMessageWithProducts | undefined>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[]>>;
    setIsAssistantThinking: (value: boolean) => void;
}) {
    if (currentAssistantMessageRef.current) {
        const updatedMessage = {
            ...currentAssistantMessageRef.current,
            content: response.content || currentAssistantMessageRef.current.content || '',
            merchantProductOffers: response.merchantProductOffers || [],
        };
        currentAssistantMessageRef.current = undefined;
        setMessages((prevMessages) =>
            prevMessages.map((message) => (message.id === updatedMessage.id ? updatedMessage : message))
        );
        setIsAssistantThinking(false);
    } else if (isSandboxEnvironment) console.warn('Received assistant content but no current assistant message:', response);
}

export function useSendMessage({
    userId,
    webSocketRef,
    messageIdCounterRef,
    setMessages,
    conversationId,
    inputText,
    setInputText,
    forceWebSocketReconnection,
    messagesListRef,
    setIsAssistantThinking,
    firstMessageToSend,
    setFirstMessageToSend,
}: {
    userId: string | undefined;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
    messageIdCounterRef: React.MutableRefObject<number>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[]>>;
    conversationId: string;
    inputText: string;
    setInputText: React.Dispatch<React.SetStateAction<string>>;
    forceWebSocketReconnection: ({
        conversationIdToClose,
        startPayload,
    }: {
        conversationIdToClose?: string;
        startPayload?: WebSocketMessagePayload;
    }) => void;
    messagesListRef: React.MutableRefObject<HTMLDivElement | null>;
    setIsAssistantThinking: (value: boolean) => void;
    firstMessageToSend: string | undefined;
    setFirstMessageToSend: (value: string) => void;
}) {
    const featureMap = useFeatureMap();
    const isSandboxEnvironment = !!featureMap?.[Feature.useProductDiscoverySandboxEnvironment];
    const localeCode = React.useMemo(() => getLocaleCode(), []);
    useSendMessageOnConversationInit({
        userId,
        webSocketRef,
        messageIdCounterRef,
        setMessages,
        conversationId,
        firstMessageToSend,
        forceWebSocketReconnection,
        localeCode,
        isSandboxEnvironment,
    });
    const sendMessage = (suggestedMessage?: string | undefined) => {
        const messageToSend = suggestedMessage ? suggestedMessage : inputText;
        if (messageToSend.trim().length === 0) return;
        if (!conversationId || !webSocketRef.current) {
            // If the `conversationId` is not created yet, we store the message and send it as soon as the connection is open
            setFirstMessageToSend(messageToSend);
            return;
        }
        // We can send the message right away
        const userMessage: ProductDiscoveryConversationMessageWithProducts = {
            id: messageIdCounterRef.current.toString(),
            timestamp: Date.now(),
            content: messageToSend,
            role: 'user',
        };
        setMessages((prevMessages) => [
            ...prevMessages,
            userMessage,
            { id: generateUniqueMessageId(messageIdCounterRef), content: '', role: 'assistant', timestamp: Date.now() },
        ]);
        const payload: WebSocketMessagePayload = {
            action: 'streamProductDiscoveryMessage',
            userId,
            timestamp: Date.now(),
            conversationId,
            message: messageToSend,
            localeCode,
            shouldLogAllData: true,
        };
        // If the web socket is not connected when the user tries to send a message, we force a reconnection and send the message right after
        if (webSocketRef.current.readyState !== WebSocket.OPEN) {
            if (isSandboxEnvironment) console.warn('WebSocket connection is not open. Reconnecting...');
            forceWebSocketReconnection({ startPayload: payload });
        } else sendWebSocketMessage({ webSocket: webSocketRef.current, payload });
        setInputText('');
        setIsAssistantThinking(true);
        setTimeout(
            () => messagesListRef.current?.scrollTo({ top: messagesListRef.current.scrollHeight, behavior: 'smooth' }),
            100
        ); // We scroll to the end of the list after a delay to ensure the message is rendered
    };
    return sendMessage;
}

/**
 * If the first message has been entered by the user before the connection was initialized, we send it as soon the connection is open.
 * To do so, this hook relies on the `firstMessageToSend` and the `conversationId` states:
 * - If `conversationId` is not yet initialized when the first message was entered, the message is saved in `firstMessageToSend`,
 *   allowing this effect to fake the sending of the message, waiting for the conversation ID to be created.
 * - When `conversationId` is initialized, this effect re-renders and sends the first message immediately.
 */
function useSendMessageOnConversationInit({
    userId,
    webSocketRef,
    messageIdCounterRef,
    setMessages,
    conversationId,
    firstMessageToSend,
    forceWebSocketReconnection,
    localeCode,
    isSandboxEnvironment,
}: {
    userId: string | undefined;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
    messageIdCounterRef: React.MutableRefObject<number>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[]>>;
    conversationId: string;
    firstMessageToSend: string | undefined;
    forceWebSocketReconnection: ({
        conversationIdToClose,
        startPayload,
    }: {
        conversationIdToClose?: string;
        startPayload?: WebSocketMessagePayload;
    }) => void;
    localeCode: string;
    isSandboxEnvironment: boolean;
}) {
    React.useEffect(() => {
        if (!firstMessageToSend) return;
        if (conversationId && webSocketRef.current) {
            // As soon as the `conversationId` is initialized, we send the first message
            const payload: WebSocketMessagePayload = {
                action: 'streamProductDiscoveryMessage',
                userId,
                timestamp: Date.now(),
                conversationId,
                message: firstMessageToSend,
                localeCode,
                shouldLogAllData: true,
            };
            if (webSocketRef.current.readyState !== WebSocket.OPEN) {
                if (isSandboxEnvironment) console.warn('WebSocket connection is not open. Reconnecting...');
                forceWebSocketReconnection({ startPayload: payload });
            } else sendWebSocketMessage({ webSocket: webSocketRef.current, payload });
            if (isSandboxEnvironment) console.log('Sending the first message after connection was initialized');
            return;
        }
        // This is a safeguard to make sure this hook is only called for the first message to send
        if (messageIdCounterRef.current > 0) return;
        if (isSandboxEnvironment) console.log('The first message has been entered before the connection was initialized');
        const userMessage: ProductDiscoveryConversationMessageWithProducts = {
            id: messageIdCounterRef.current.toString(),
            timestamp: Date.now(),
            content: firstMessageToSend,
            role: 'user',
        };
        setMessages([
            userMessage,
            { id: generateUniqueMessageId(messageIdCounterRef), content: '', role: 'assistant', timestamp: Date.now() },
        ]);
    }, [conversationId, firstMessageToSend]);
}

function generateUniqueMessageId(messageIdCounterRef: React.MutableRefObject<number>) {
    messageIdCounterRef.current += 1;
    return messageIdCounterRef.current.toString();
}

export function useResetConversation({
    conversationId,
    setMessages,
    messageIdCounterRef,
    setIsAssistantThinking,
    setFirstMessageToSend,
    forceWebSocketReconnection,
    setShouldShowScrollToBottomButton,
    setUserFeedbackMap,
}: {
    conversationId: string;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[]>>;
    messageIdCounterRef: React.MutableRefObject<number>;
    setIsAssistantThinking: (value: boolean) => void;
    setFirstMessageToSend: (value: string | undefined) => void;
    forceWebSocketReconnection: ({
        conversationIdToClose,
        startPayload,
    }: {
        conversationIdToClose?: string;
        startPayload?: WebSocketMessagePayload;
    }) => void;
    setShouldShowScrollToBottomButton: (value: boolean) => void;
    setUserFeedbackMap: (value: { [id: string]: 'positive' | 'negative' | null }) => void;
}) {
    return React.useCallback(() => {
        setMessages([]);
        messageIdCounterRef.current = 0;
        setIsAssistantThinking(false);
        setFirstMessageToSend(undefined);
        // We restart the web socket connection to create a new conversation ID, so that the previous conversation doesn't impact the new one
        forceWebSocketReconnection({ conversationIdToClose: conversationId });
        setShouldShowScrollToBottomButton(false);
        setUserFeedbackMap({});
    }, [conversationId]);
}

export type MerchantIdToMerchantNameMap = {
    [merchantId: string]: string | undefined;
};

export function useMerchantIdToMerchantNameMap({
    apolloClient,
    messages,
}: {
    apolloClient: ApolloClient | undefined;
    messages: ProductDiscoveryConversationMessageWithProducts[];
}) {
    const [merchantIdToMerchantNameMap, setMerchantIdToMerchantNameMap] = React.useState<MerchantIdToMerchantNameMap>({});
    React.useEffect(() => {
        if (!apolloClient || !messages.length) return;
        const lastSuggestedMerchantProductOffers = messages[messages.length - 1].merchantProductOffers;
        if (!lastSuggestedMerchantProductOffers) return;
        const merchantIdsToFetch = lastSuggestedMerchantProductOffers
            .map((offer) => offer.merchantId)
            .filter(
                (merchantId) => !!merchantId && !merchantIdToMerchantNameMap[merchantId] // We only fetch a merchant name if it doesn't already exist in the map
            );
        const fetchMerchantNames = async () => {
            const merchantNames = await Promise.all(
                merchantIdsToFetch.map((merchantId) => getMerchantName(apolloClient, merchantId as string))
            );
            const newMerchantIdToMerchantNameMap = merchantNames.reduce(
                (newMap: { [merchantId: string]: string | undefined }, { merchantId, merchantName }) => {
                    newMap[merchantId] = merchantName;
                    return newMap;
                },
                {}
            );
            setMerchantIdToMerchantNameMap({ ...merchantIdToMerchantNameMap, ...newMerchantIdToMerchantNameMap });
        };
        fetchMerchantNames();
    }, [messages]);
    return merchantIdToMerchantNameMap;
}

export function useScrollToBottom({
    messages,
    messagesListRef,
}: {
    messages: ProductDiscoveryConversationMessageWithProducts[];
    messagesListRef: React.MutableRefObject<HTMLDivElement | null>;
}) {
    const [shouldShowScrollToBottomButton, setShouldShowScrollToBottomButton] = React.useState(false);
    React.useEffect(() => {
        if (messagesListRef.current && messagesListRef.current.scrollHeight > messagesListRef.current.clientHeight)
            setShouldShowScrollToBottomButton(true);
    }, [messages]);
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        const currentPosition = scrollTop + clientHeight;
        // We show the scroll-to-bottom button only if the user is not at the bottom
        if (currentPosition < scrollHeight - 10) setShouldShowScrollToBottomButton(true);
        else setShouldShowScrollToBottomButton(false);
    };
    return { shouldShowScrollToBottomButton, setShouldShowScrollToBottomButton, handleScroll };
}

export function useSuggestedProductsDebugLogs({
    messages,
}: {
    messages: ProductDiscoveryConversationMessageWithProducts[];
}) {
    const featureMap = useFeatureMap();
    const isSandboxEnvironment = !!featureMap?.[Feature.useProductDiscoverySandboxEnvironment];
    React.useEffect(() => {
        if (!isSandboxEnvironment || messages.length === 0) return;
        const newSuggestedMerchantProductOffers = messages[messages.length - 1].merchantProductOffers;
        if (!newSuggestedMerchantProductOffers) return;
        console.log('Suggested MPOs:', JSON.stringify(newSuggestedMerchantProductOffers, null, 2));
    }, [messages, isSandboxEnvironment]);
}
