import gql from 'graphql-tag';

import { Region } from '../../../lib/auth/region';

export const userFragment = gql`
    fragment User on User {
        userId
        region
        email
        registeredAt
        onboardedAt
        code
        referrerCode
        phoneNumber
        phoneNumberStatus
        userInfo {
            firstName
            lastName
            age
            gender
            birthDate
        }
        userBankDetails {
            iban
            bic
        }
        passwordType
    }
`;

export enum Gender {
    Male = 'M',
    Female = 'F',
    Other = 'O',
}

export enum PhoneNumberStatus {
    undefined = 'undefined',
    pending = 'pending',
    confirmed = 'confirmed',
}

export enum PasswordType {
    manuallyDefined = 'manually_defined',
    generated = 'generated',
}

export interface UserInfo {
    __typename: 'UserInfo';
    firstName: string | null;
    lastName: string | null;
    age: number | null;
    gender: Gender | null;
    birthDate: string | null;
}

export interface UserBankDetails {
    __typename: 'UserBankDetails';
    iban: string | null;
    bic: string | null;
}

export interface User {
    __typename: 'User';
    userId: string;
    region: Region | null;
    email: string;
    registeredAt: number;
    onboardedAt: number | null;
    code: string;
    referrerCode: string | null;
    userInfo: UserInfo | null;
    userBankDetails: UserBankDetails | null;
    passwordType: PasswordType | null;
    phoneNumber: string | null;
    phoneNumberStatus: PhoneNumberStatus | null;
}

export const dataIdFromUser = (user?: User) => {
    return `User:current`;
};
