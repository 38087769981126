import * as React from 'react';
import { Image, Text, View, TextStyle } from 'react-native';

import { InstallationStep } from '../../../lib/mobileSafariExtension/mobileSafariExtension';
import { getLocalizedTexts } from '../../../Locales';
import { newDesignSystemFontStyles, normalizeHeight, styles } from './Styles';
import HighlightedText from '../../common/HighlightedText';
import color from '../../../style/color';
import { redirectToPrivacyPolicy } from './RedirectLinks';

const logo = { uri: '/assets/images/logos/lightning-square.svg' };
const warningIcon = { uri: '/assets/images/icons/warning.svg' };

export function Header({ step }: { step: InstallationStep }) {
    return (
        <View style={styles.containerHeader}>
            <Image {...getHeaderIconImage(step)} />
            <View style={styles.containerHeaderText}>
                <Text style={newDesignSystemFontStyles.titleXL}>{getTitleText(step)}</Text>
                <Subtitle step={step} />
            </View>
        </View>
    );
}

function Subtitle({ step }: { step: InstallationStep }) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped;
    switch (step) {
        case InstallationStep.authorizeStep:
        case InstallationStep.authorizeWithBannerStep:
            return <AuthorizationSubtitle text={texts.authorizeStep.headerSubtitle} />;
        case InstallationStep.missingAllWebsitesAuthorization:
        case InstallationStep.missingAllWebsitesAuthorizationAfterRetry:
            return <AuthorizationSubtitle text={texts.missingAuthorization.headerSubtitle} />;
        case InstallationStep.conclusionStep:
            return <Text style={newDesignSystemFontStyles.paragraphSmall}>{texts.conclusion.headerSubtitle}</Text>;
        default:
            return null;
    }
}

function AuthorizationSubtitle({ text }: { text: string }) {
    return (
        <HighlightedText
            style={[newDesignSystemFontStyles.paragraphXS, { color: color.boulder }]}
            highlightedStyle={{ textDecorationLine: 'underline', textUnderlineOffset: normalizeHeight(3) } as TextStyle}
            onPressHighlightedTexts={[redirectToPrivacyPolicy]}>
            {text}
        </HighlightedText>
    );
}

function getHeaderIconImage(step: InstallationStep) {
    switch (step) {
        case InstallationStep.missingAllWebsitesAuthorization:
        case InstallationStep.missingAllWebsitesAuthorizationAfterRetry:
            return { source: warningIcon, style: styles.warningHeaderIcon };
        default:
            return { source: logo, style: styles.logoHeaderIcon };
    }
}

function getTitleText(step: InstallationStep) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped;
    switch (step) {
        case InstallationStep.activateStepPreamble:
        case InstallationStep.activateStep:
            return texts.activateStep.headerTitle;
        case InstallationStep.authorizeStep:
        case InstallationStep.authorizeWithBannerStep:
            return texts.authorizeStep.headerTitle;
        case InstallationStep.missingAllWebsitesAuthorization:
        case InstallationStep.missingAllWebsitesAuthorizationAfterRetry:
            return texts.missingAuthorization.headerTitle;
        case InstallationStep.conclusionStep:
            return texts.conclusion.headerTitle;
        default:
            throw new Error(`Invalid step: ${step}`);
    }
}
