import React from 'react';

import { useHistory, useLocation } from 'react-router';
import { checkIsSignedIn } from '../common/cognito';
import { getCognitoAuthenticationData } from '../../lib/common/cognito';

const OAUTH_REDIRECT_EXPIRATION_IN_MS = 1000 * 60 * 5; // 5 minutes, after which the redirect data is considered expired
const OAUTH_REDIRECT_DATA_LOCAL_STORAGE_KEY = 'oauthRedirectData';

/**
 * The OAuth redirect URL must be one of these URLs.
 * This is to prevent phishing attacks where an attacker could redirect the user to a malicious site.
 */
const AUTHORIZED_OAUTH_REDIRECT_URLS = new Set([
    'jokoextension://', // URL scheme of our macOS Safari extension
    /**
     * IMPORTANT: The OAuth mechanism needs to be strengthened if we want to add standard web URLs here.
     *
     * Indeed, this is considered a major vulnerability to pass authentication tokens as URL parameters in an HTTP or HTTPS request.
     * Access tokens and refresh tokens are highly sensitive. When placed in the URL, they can be leaked through browser history,
     * logs, referrer headers, or even third-party monitoring services.
     * An attacker who gains access to these tokens could impersonate the user.
     *
     * Today, this is not an issue but we don't use a standard redirect URL. Instead, we use a custom URL scheme (jokoextension://)
     * that redirects to our macOS app corresponding to the Safari extension without making any HTTP request.
     *
     * If we want to use different redirect URLs, we'll need to modify our approach. Instead of passing authentication tokens directly,
     * we should return a short-lived code in the redirect URL.
     * We'll then need to create an API endpoint allowing the client to exchange this temporary code for the actual authentication tokens.
     */
]);

/**
 * The web app can be used as an OAuth client.
 * In this case, it is opened with a URL parameter `oauthRedirectUrl` which is the URL to redirect to after the user has signed in.
 * This hook handles the redirection to the OAuth redirect URL after the user has signed in.
 */
export function useOauthRedirect() {
    const history = useHistory();
    const location = useLocation();
    React.useEffect(() => {
        const oauthRedirectUrlFromUrlParams = new URLSearchParams(location?.search)?.get('oauthRedirectUrl');
        if (oauthRedirectUrlFromUrlParams && AUTHORIZED_OAUTH_REDIRECT_URLS.has(oauthRedirectUrlFromUrlParams)) {
            // If the user is already signed in, redirect immediately
            if (checkIsSignedIn()) redirectAndClearOauthRedirectData(oauthRedirectUrlFromUrlParams, history);
            // Otherwise, store the URL to redirect to after the user has signed in
            else {
                const oauthRedirectData = JSON.stringify({
                    url: oauthRedirectUrlFromUrlParams,
                    timestampInMs: Date.now(),
                });
                localStorage.setItem(OAUTH_REDIRECT_DATA_LOCAL_STORAGE_KEY, oauthRedirectData);
            }
        } else if (checkIsSignedIn()) {
            const oauthRedirectDataString = localStorage.getItem(OAUTH_REDIRECT_DATA_LOCAL_STORAGE_KEY);
            if (oauthRedirectDataString) {
                try {
                    const oauthRedirectData = JSON.parse(oauthRedirectDataString);
                    if (Date.now() - oauthRedirectData.timestampInMs < OAUTH_REDIRECT_EXPIRATION_IN_MS)
                        redirectAndClearOauthRedirectData(oauthRedirectData.url, history);
                } catch (error) {
                    console.error('Failed to parse oauthRedirectData', error);
                }
            }
        }
    }, [location]);
}

function redirectAndClearOauthRedirectData(redirectUrl: string, history: any) {
    localStorage.removeItem(OAUTH_REDIRECT_DATA_LOCAL_STORAGE_KEY);
    const authenticationData: { [key: string]: string } = getCognitoAuthenticationData();
    const urlParameters = new URLSearchParams(authenticationData);
    history.push('/sign-in-succeeded');
    setTimeout(() => {
        window.location.href = `${redirectUrl}?${urlParameters.toString()}`;
    }, 2000);
}
