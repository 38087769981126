import * as React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import { font } from '../../../style/text';
import color from '../../../style/color';

function OfferDetailSection({ title, children }: { title: string; children: React.ReactNode }) {
    return (
        <View style={styles.containerSection}>
            <View style={styles.containerSectionTitle}>
                <Text style={styles.textSectionTitle}>{title}</Text>
            </View>
            {children}
        </View>
    );
}

export default OfferDetailSection;

const styles = StyleSheet.create({
    containerSection: {
        marginHorizontal: 40,
        marginBottom: 20,
        top: -2,
        zIndex: -1,
    },
    containerSectionTitle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    textSectionTitle: {
        fontFamily: font.ambitBold,
        fontSize: 18,
        lineHeight: 20,
        color: color.black,
    },
});
