import gql from 'graphql-tag';

export const legalUpdatesToBeReviewedFragment = gql`
    fragment LegalUpdatesToBeReviewed on LegalUpdatesToBeReviewed {
        type
        requiresConsent
        legalUpdates {
            legalUpdateId
            summaryOfChanges
        }
    }
`;

export enum LegalUpdateType {
    termsOfService = 'termsOfService',
    privacyPolicy = 'privacyPolicy',
    termsOfServiceAndPrivacyPolicy = 'termsOfServiceAndPrivacyPolicy',
}

export interface LegalUpdatesToBeReviewed {
    __typename: 'LegalUpdatesToBeReviewed';
    type: LegalUpdateType;
    requiresConsent: boolean;
    legalUpdates: LegalUpdate[];
}

export interface LegalUpdate {
    __typename: 'LegalUpdate';
    legalUpdateId: string;
    summaryOfChanges: string;
}

export const dataIdFromLegalUpdate = (legalUpdate: LegalUpdate) => {
    return `LegalUpdate:${legalUpdate.legalUpdateId}`;
};

export const dataIdFromLegalUpdatesToBeReviewed = (legalUpdatesToBeReviewed?: LegalUpdatesToBeReviewed) => {
    return `LegalUpdatesToBeReviewed:current`;
};
