import { logEventWithoutAuthentication } from '../../api/rest/events';
import { OnlineOfferActivationInfo } from '../../api/rest/offers';
import { logAmplitudeEventWithoutAuthentication } from '../events/amplitudeEvents';

const { v1: uuidv1 } = require('uuid');

export type AffiliateLinkLoadingMechanismData = {
    userId: string;
    isTemporaryUser: boolean;
    offerId: string;
    merchantId: string;
    personalizedAffiliateLink: string;
    triggeringEventId: string;
    triggeringEventType?: string;
    triggeringEventPlatform?: string;
    triggeringEventTimestamp?: number;
};

// Note that affiliate link loading attempts made in the universal offer activation page might come from other events and from other platforms
export enum AffiliateLinkLoadingTriggeringEventType {
    offerComponent = 'offerComponent',
    offerDetails = 'offerDetails',
}

/**
 * Note that we introduce the notion of affiliate loading mechanism for consistency with the mobile app and browser extension codebase
 * When the affiliate link loading mechanism is launched we might make several loading attempts (today we only launch single loading attempts in the web app, but not on other platforms)
 * Each attempt will use a different affiliate link generated from a "default" affiliate link that is specific to the offer
 * The default affiliate link is given by the `personalizedAffiliateLink` field of the offer object retrieved from our API
 * We give a unique ID to the event that triggered the affiliate link loading mechanism to be able to link attempts together
 */
export function generateNewAffiliateLinkLoadingMechanismData({
    userId,
    isTemporaryUser,
    offerActivationInfo,
    triggeringEventType,
    triggeringEventPlatform,
    triggeringEventTimestamp,
}: {
    userId: string;
    isTemporaryUser: boolean;
    offerActivationInfo: OnlineOfferActivationInfo;
    triggeringEventType: string | undefined;
    triggeringEventPlatform: string | undefined;
    triggeringEventTimestamp: number | undefined;
}): AffiliateLinkLoadingMechanismData | undefined {
    const {
        partialOffer: { offerId, merchantId },
        personalizedAffiliateLink,
    } = offerActivationInfo;
    if (!merchantId || !personalizedAffiliateLink) return undefined;
    const triggeringEventId = uuidv1();
    return {
        userId,
        isTemporaryUser,
        offerId,
        merchantId,
        personalizedAffiliateLink,
        triggeringEventId,
        triggeringEventType,
        triggeringEventPlatform,
        triggeringEventTimestamp,
    };
}

type AffiliateLinkLoadingAttemptData = {
    userId: string;
    isTemporaryUser: boolean;
    offerId: string;
    merchantId: string;
    clickId: string;
    affiliateLinkUrl: string;
    triggeringEventId: string;
    triggeringEventType?: string;
    triggeringEventPlatform?: string;
    triggeringEventTimestamp?: number;
    affiliateLinkLoadingMethod: AffiliateLinkLoadingMethod;
    affiliateLinkLoadingTimestamp: number;
    attemptNumber: number;
};

export enum AffiliateLinkLoadingMethod {
    directLoading = 'directLoading',
}

/**
 * This function is very close to the one used in the mobile app and in the browser extension codebase for consistency
 * As described above, when the affiliate link loading mechanism is launched we might make several loading attempts
 * Each attempt will use a different affiliate link
 * What will change from one link to another is the Sub ID, which is a custom ID passed to the affiliate platform to track each attempt
 * We generate new affiliate links from a "default" affiliate link that is specific to the offer
 * The default affiliate link is given by the `personalizedAffiliateLink` field of the offer object retrieved from our API
 * This default link contains the User ID as a Sub ID (which would allow to link a transaction to the user but not to a specific attempt)
 * To generate a new link, we simply generate a Click ID and we replace the User ID with this Click ID in `personalizedAffiliateLink`
 * Note that in order to be taken into account when an affiliate transaction is retrieved, the Click ID must satisfy two constraints:
 * - It must have the `c-<UUID>` format (e.g. `c-11111111-1111-1111-1111-111111111111`)
 * - We must log a `clickedAffiliateLink` event in the `userEvents` table with this `clickId` in the event's payload
 */
export function generateNewAffiliateLinkLoadingAttemptData(
    {
        userId,
        isTemporaryUser,
        offerId,
        merchantId,
        personalizedAffiliateLink,
        triggeringEventId,
        triggeringEventType,
        triggeringEventPlatform,
        triggeringEventTimestamp,
    }: AffiliateLinkLoadingMechanismData,
    affiliateLinkLoadingMethod: AffiliateLinkLoadingMethod,
    attemptNumber: number
): AffiliateLinkLoadingAttemptData {
    const clickId = `c-${uuidv1()}`;
    let affiliateLinkUrl = personalizedAffiliateLink.replace(userId, clickId);
    const affiliateLinkLoadingTimestamp = Date.now() / 1000;
    return {
        userId,
        isTemporaryUser,
        offerId,
        merchantId,
        clickId,
        affiliateLinkUrl,
        triggeringEventId,
        triggeringEventType,
        triggeringEventPlatform,
        triggeringEventTimestamp,
        affiliateLinkLoadingMethod,
        affiliateLinkLoadingTimestamp,
        attemptNumber,
    };
}

/**
 * As described above, in order to be able to match an affiliate transaction to a user when a transaction is retrieved
 * we must log a `clickedAffiliateLink` event in the `userEvents` table with the `clickId` that has been passed as a Sub ID in the affiliate link in the event's payload
 */
export async function logClickedAffiliateLinkEventsWithoutAuthentication(
    {
        userId,
        isTemporaryUser,
        offerId,
        merchantId,
        clickId,
        affiliateLinkUrl,
        triggeringEventId,
        triggeringEventType,
        triggeringEventPlatform,
        triggeringEventTimestamp,
        affiliateLinkLoadingMethod,
        affiliateLinkLoadingTimestamp,
        attemptNumber,
    }: AffiliateLinkLoadingAttemptData,
    hasRedirectUrl: boolean,
    parametersRemovedFromRedirectUrl: string[],
    isDevStackMode: boolean
): Promise<{ status: 'success' | 'failure' }> {
    const payload = {
        offerId,
        merchantId,
        clickId,
        affiliateLinkUrl,
        triggeringEventId,
        triggeringEventType,
        triggeringEventPlatform,
        triggeringEventTimestamp,
        affiliateLinkLoadingMethod,
        affiliateLinkLoadingTimestamp,
        attemptNumber,
        hasRedirectUrl,
        parametersRemovedFromRedirectUrl,
        // The properties below are deprecated and are logged for backward compatibility
        from: triggeringEventPlatform,
    };
    const properties = {
        ...payload,
        affiliateLinkUrl: '', // There is a bug when we try to pass affiliateLinkUrl to Amplitude
        temporaryUserId: isTemporaryUser ? userId : undefined, // For temporary users, we log the userId as an Amplitude event property in place of the usual `userId` field, in order to maintain a single event stream with the future replacement regular account
    };
    const { status } = await logEventWithoutAuthentication(
        userId,
        { type: 'clickedAffiliateLink', payload },
        isDevStackMode
    );
    logAmplitudeEventWithoutAuthentication(
        {
            name: 'Offers - Clicked Affiliate Link',
            properties,
        },
        { userId: isTemporaryUser ? undefined : userId }
    );
    return { status };
}
