import gql from 'graphql-tag';

import { Gender } from '../fragments/user';
import { ApolloClient } from '../client';

export const updateUserMutation = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            userInfo {
                firstName
                lastName
                gender
                age
                birthDate
            }
            userBankDetails {
                iban
                bic
            }
            onboardedAt
            referrerCode
            email
            phoneNumber
            phoneNumberStatus
        }
    }
`;

interface UpdateUserMutationResponse {
    __typename: 'Mutation';
    updateUser: {
        __typename: 'User';
        userInfo: {
            __typename: 'UserInfo';
            firstName: string | null;
            lastName: string | null;
            gender: string | null;
            age: Number | null;
            birthDate: string | null;
        } | null;
        userBankDetails: {
            __typename: 'UserBankDetails';
            iban: string | null;
            bic: string | null;
        } | null;
        onboardedAt: number | null;
        referrerCode: string | null;
        email: string | null;
        phoneNumber: string | null;
        phoneNumberStatus: string | null;
    };
}

export type UpdateUserInput = {
    email?: string;
    password?: string;
    firstName?: string;
    lastName?: string;
    gender?: Gender;
    age?: number;
    birthDate?: string;
    referrerCode?: string;
    onboardedAt?: number;
    phoneNumber?: string;
    phoneNumberStatus?: string;
    iban?: string;
    bic?: string;
};

export async function updateUser(client: ApolloClient, input: UpdateUserInput) {
    await client.mutate<UpdateUserMutationResponse, { input: UpdateUserInput }>({
        mutation: updateUserMutation,
        variables: { input },
    });
}
