/**
 * The `palette.ts` file defines the base color palettes with various shades, from lightest ('25') to darkest ('900').
 * Each palette is a collection of these shades for different colors.
 *
 * The `color.ts` file uses these palettes to define semantic tokens for UI elements, such as `primary`, `secondary`,
 * `positive`, and `warning`. These tokens make it easy to apply consistent colors based on their function or state. They represent colors from our brand book.
 *
 * Separating base colors from their application ensures better maintainability, as changes in `palette.ts` automatically
 * update the colors used in `color.ts`.
 */

import * as palette from './palette';
import { Color } from './palette';

type BackgroundColorAlias =
    // Core
    | 'default'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'subtle'
    | 'light'
    // Sentiment
    | 'positive'
    | 'positiveLight'
    | 'warning'
    | 'warningLight'
    | 'negative'
    | 'negativeLight'
    // States
    | 'primaryPressed'
    | 'primaryDisabled'
    | 'secondaryPressed'
    | 'secondaryDisabled'
    | 'tertiaryPressed'
    | 'tertiaryDisabled'
    | 'positivePressed'
    | 'positiveDisabled'
    | 'negativePressed'
    | 'negativeDisabled'
    // Brand
    | 'green'
    | 'greenLight'
    | 'purpleLight'
    | 'blueLight'
    | 'overlay'
    | 'translucent';

/** Content is used for texts */
type ContentColorAlias =
    // Core
    | 'default'
    | 'primary'
    | 'secondary'
    /** Navigation color is used in navigation components, for example headers */
    | 'navigation'
    // Sentiment
    | 'positive'
    | 'warning'
    | 'negative'
    // States
    | 'defaultPressed'
    | 'defaultDisabled'
    | 'primaryPressed'
    | 'primaryDisabled'
    | 'secondaryPressed'
    | 'secondaryDisabled'
    | 'tertiaryDisabled'
    | 'positivePressed'
    | 'positiveDisabled'
    | 'warningPressed'
    | 'warningDisabled'
    | 'negativePressed'
    // Brand
    | 'purple'
    | 'blue';

type BorderColorAlias =
    // Core
    | 'default'
    | 'primary'
    | 'secondary'
    | 'subtle'
    | 'light'
    // Sentiment
    | 'positive'
    | 'positiveLight'
    | 'warning'
    | 'warningLight'
    | 'negative'
    | 'negativeLight'
    // States
    | 'primaryPressed'
    | 'secondaryPressed'
    | 'positivePressed'
    | 'negativePressed'
    | 'onColorDisabled';

type BrandColorAlias =
    | 'black'
    | 'white'
    | 'green'
    | 'greenLight'
    | 'purple'
    | 'purpleLight'
    | 'blue'
    | 'blueLight'
    | 'orangeLight';

type BackgroundColors = { [TokenName in BackgroundColorAlias]: Color };

const backgroundColors: BackgroundColors = {
    // Core
    default: palette.white,
    primary: palette.black,
    secondary: palette.white,
    tertiary: palette.neutral[600],
    subtle: palette.neutral[50],
    light: palette.neutral[25],
    // Sentiment
    positive: palette.green[500],
    positiveLight: palette.green[50],
    warning: palette.orange[500],
    warningLight: palette.orange[50],
    negative: palette.red[500],
    negativeLight: palette.red[50],
    // States
    primaryPressed: palette.black,
    primaryDisabled: palette.neutral[600],
    secondaryPressed: palette.white,
    secondaryDisabled: palette.white,
    tertiaryPressed: palette.neutral[50],
    tertiaryDisabled: palette.neutral[400],
    positivePressed: palette.green[500],
    positiveDisabled: palette.green[50],
    negativePressed: palette.red[500],
    negativeDisabled: palette.red[300],
    // Brand
    green: palette.green[500],
    greenLight: palette.green[200],
    purpleLight: palette.purple[50],
    blueLight: palette.blue[50],
    overlay: palette.blackAlpha[70],
    translucent: palette.whiteAlpha[70],
};

type ContentColors = { [TokenName in ContentColorAlias]: Color };

const contentColors: ContentColors = {
    // Core
    default: palette.black,
    primary: palette.white,
    secondary: palette.neutral[600],
    navigation: palette.neutral[500],
    // Sentiment
    positive: palette.green[500],
    warning: palette.orange[500],
    negative: palette.red[500],
    // States
    defaultPressed: palette.black,
    defaultDisabled: palette.neutral[500],
    primaryPressed: palette.white,
    primaryDisabled: palette.white,
    secondaryPressed: palette.neutral[700],
    secondaryDisabled: palette.white,
    tertiaryDisabled: palette.neutral[500],
    positivePressed: palette.green[800],
    positiveDisabled: palette.neutral[500],
    warningPressed: palette.orange[800],
    warningDisabled: palette.orange[300],
    negativePressed: palette.red[800],
    // Brand
    purple: palette.purple[600],
    blue: palette.blue[600],
};

type BorderColors = { [TokenName in BorderColorAlias]: Color };

const borderColors: BorderColors = {
    // Core
    default: palette.black,
    primary: palette.neutral[700],
    secondary: palette.blackAlpha[16],
    subtle: palette.neutral[100],
    light: palette.white,
    // Sentiment
    positive: palette.green[500],
    positiveLight: palette.green[200],
    warning: palette.orange[500],
    warningLight: palette.orange[200],
    negative: palette.red[500],
    negativeLight: palette.red[200],
    // State
    primaryPressed: palette.neutral[500],
    secondaryPressed: palette.neutral[700],
    positivePressed: palette.green[600],
    negativePressed: palette.red[600],
    onColorDisabled: palette.whiteAlpha[64],
};

type BrandColors = { [TokenName in BrandColorAlias]: Color };

const brandColors: BrandColors = {
    black: palette.black,
    white: palette.white,
    green: palette.green[500],
    greenLight: palette.green[300],
    purple: palette.purple[500],
    purpleLight: palette.purple[200],
    blue: palette.blue[400],
    blueLight: palette.blue[50],
    orangeLight: palette.orange[200],
};

export type Colors = {
    background: BackgroundColors;
    border: BorderColors;
    content: ContentColors;
    brand: BrandColors;
};

export const colors: Colors = {
    background: backgroundColors,
    border: borderColors,
    content: contentColors,
    brand: brandColors,
};
