import { getDevStackMode } from '../common/devStackMode';

enum WebSocketApiUrl {
    dev = 'wss://n5bnnvnz08.execute-api.eu-west-1.amazonaws.com/dev/',
    prod = 'wss://o23co1viz1.execute-api.eu-west-1.amazonaws.com/dev/',
}

export function getWebSocketApiUrl(): string {
    return getDevStackMode() ? WebSocketApiUrl.dev : WebSocketApiUrl.prod;
}
