import * as React from 'react';
import { Animated, Easing } from 'react-native';
import { StyleSheet, View, Image } from 'react-native';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';

import color from '../../style/color';

const logo = { uri: '/assets/images/logos/logo-with-title.png' };

export default function GradientBackground({ height, width }: { height?: number; width?: number }) {
    return (
        <>
            <View style={[styles.containerBackground, { height, width }]}>
                <Svg height="100%" width="100%">
                    <Defs>
                        <LinearGradient id="grad" x1="0" y1="0" x2="0" y2="1">
                            <Stop offset="0" stopColor={color.linkWaterLight} stopOpacity="1" />
                            <Stop offset="1" stopColor={color.white} stopOpacity="1" />
                        </LinearGradient>
                    </Defs>
                    <Rect x="0" y="0" width="100%" height="100%" fill="url(#grad)" />
                </Svg>
            </View>
            <View style={styles.containerLogo}>
                <Image source={logo} style={styles.imageLogo} />
            </View>
        </>
    );
}
const styles = StyleSheet.create({
    containerBackground: {
        flex: 1,
        flexDirection: 'row',
        borderRadius: 16,
        overflow: 'hidden',
    },
    containerLogo: {
        position: 'absolute',
        top: 40,
    },
    imageLogo: {
        width: 114,
        height: 55,
        resizeMode: 'contain',
    },
});

const AnimatedLinearGradient = Animated.createAnimatedComponent(LinearGradient);

export function AnimatedGradientBackground() {
    const animatedValue = React.useRef(new Animated.Value(0)).current;
    React.useEffect(() => {
        Animated.loop(
            Animated.timing(animatedValue, {
                toValue: 1,
                duration: 1500,
                easing: Easing.out(Easing.ease),
                useNativeDriver: false, // Since we're animating the gradient, not native properties
            })
        ).start();
    }, [animatedValue]);
    const startX = animatedValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['-100%', '100%'], // Move from left to right
    });
    const endX = animatedValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0%', '150%'], // Ending further right for smooth movement
    });
    return (
        <View style={{ width: '100%', height: '100%' }}>
            <Svg height="100%" width="100%">
                <Defs>
                    <AnimatedLinearGradient id="grad" x1={startX} y1="0" x2={endX} y2="0">
                        <Stop offset="0%" stopColor={color.alabaster} />
                        <Stop offset="50%" stopColor={color.white} />
                        <Stop offset="100%" stopColor={color.alabaster} />
                    </AnimatedLinearGradient>
                </Defs>
                <Rect height="100%" width="100%" fill="url(#grad)" rx={16} ry={16} />
            </Svg>
        </View>
    );
}
