import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { v1 as uuidv1 } from 'uuid';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { useWindowSize } from '../../style/utils';
import { getSocialIdentityCredentials, GetSocialIdentityCredentialsResponse, Provider } from '../../api/rest/user';
import {
    exchangeGoogleCodeForIdToken,
    GoogleIdentityDocument,
    verifyAndDecodeGoogleJsonWebToken,
    getSocialIdentityDocument,
    GOOGLE_OAUTH_CLIENT_ID,
} from '../../lib/auth/googleLogin';
import { logAmplitudeEvent } from '../../lib/events/amplitudeEvents';
import IntroPanel from './IntroPanel';
import LoadingFrame from '../../components/auth/LoadingFrame';
import SocialSignUpPage from './SocialSignUpPage';

const LOCALHOST_REDIRECT_URL = 'http://localhost:3000/auth/sign-up/google';

function GoogleAuthPage() {
    const texts = getLocalizedTexts().auth.signIn.withGoogle;
    const size = useWindowSize();
    const [signUpData, errorMessage] = useHandleRedirection();
    if (!signUpData)
        return (
            <View style={styles.container}>
                <LoadingFrame
                    text={errorMessage || texts.loading}
                    height={(size.height || 0) - 120}
                    width={(size.width || 0) - 240}
                    isError={!!errorMessage}
                />
            </View>
        );
    return (
        <View style={styles.containerSignUp}>
            <View style={styles.containerSignUpColumn}>
                <IntroPanel />
            </View>
            <View style={styles.containerSignUpColumn}>
                <SocialSignUpPage {...signUpData} />
            </View>
        </View>
    );
}

export default GoogleAuthPage;

function useHandleRedirection(): [SignUpData | undefined, string | undefined] {
    const location = useLocation();
    const redirectUrl =
        window.location.host === 'localhost:3000'
            ? LOCALHOST_REDIRECT_URL
            : `https://${window.location.host}${location.pathname}`; // When testing locally, we redirect to localhost with http instead of https to avoid a `ERR_SSL_PROTOCOL_ERROR` error.
    const { handleOAuthResponse, signUpData, errorMessage } = useHandleOAuthResponse(redirectUrl);
    React.useEffect(() => {
        handleRedirection(handleOAuthResponse, location.search, redirectUrl);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return [signUpData, errorMessage];
}

async function handleRedirection(
    handleOAuthResponse: (code: string, identityDocument: GoogleIdentityDocument) => void,
    locationUrlQuery: string,
    redirectUrl: string
) {
    const identityDocument = await getSocialIdentityDocument();
    const urlSearchParams = new URLSearchParams(locationUrlQuery);
    const googleCode = urlSearchParams.get('code');
    if (googleCode) {
        handleOAuthResponse(googleCode, identityDocument);
    } else {
        // We open a window on the authorization endpoint to open the google sign in modal.
        // Once the user accepts to sign in, google will redirect to `redirect_uri` with `code` as a url parameter.
        window.open(
            encodeURI(
                `${identityDocument.authorization_endpoint}` +
                    `?client_id=${GOOGLE_OAUTH_CLIENT_ID}` +
                    `&response_type=code` +
                    `&redirect_uri=${redirectUrl}` +
                    `&state=${uuidv1()}` +
                    `&scope=openid profile email`
            ),
            '_self'
        );
    }
}

interface SignUpData {
    initialEmail: string;
    credentials: GetSocialIdentityCredentialsResponse;
}

function useHandleOAuthResponse(redirectUrl: string): {
    handleOAuthResponse: (code: string, identityDocument: GoogleIdentityDocument) => void;
    signUpData: SignUpData | undefined;
    errorMessage: string | undefined;
} {
    const texts = getLocalizedTexts().auth.signIn.withGoogle;
    const history = useHistory();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [signUpData, setSignUpData] = React.useState<SignUpData | undefined>(undefined);
    const handleOAuthResponse = async (code: string, identityDocument: GoogleIdentityDocument) => {
        try {
            const idJsonWebToken = await exchangeGoogleCodeForIdToken(code, redirectUrl);
            const googleIdToken = await verifyAndDecodeGoogleJsonWebToken(idJsonWebToken, identityDocument);
            const userEmail = googleIdToken.email;
            if (!userEmail) throw new Error('No email was received in the Id token returned by user-appsync-api');
            const credentials = await getSocialIdentityCredentials({
                provider: Provider.Google,
                providerUserId: googleIdToken.sub,
                email: userEmail,
            });
            if (!credentials.new) await signIn(credentials);
            else setSignUpData({ initialEmail: userEmail, credentials });
        } catch (error) {
            setErrorMessage(texts.error.default);
            logAmplitudeEvent({ name: 'Registration - Google Login Failed' });
            setTimeout(() => history.push('/auth'), 1000);
        }
    };
    return { handleOAuthResponse, signUpData, errorMessage };
}

async function signIn(credentials: GetSocialIdentityCredentialsResponse) {
    Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
    await Auth.signIn(credentials.userId, credentials.secretKey);
    logAmplitudeEvent({ name: 'Registration - Google Login Succeeded' });
    window.location.reload();
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 60,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: color.white,
    },
    containerSignUp: {
        flex: 1,
        flexDirection: 'row',
    },
    containerSignUpColumn: {
        flex: 1,
    },
});
