import gql from 'graphql-tag';

import { enrichedUserOfferFragment, EnrichedUserOffer } from '../fragments/enrichedOffers';
import { OfferType } from '../fragments/offers';

export const enrichedUserOfferQuery = gql`
    query EnrichedUserOfferQuery($offerId: String!, $shouldUseNewConditions: Boolean) {
        user {
            enrichedOffer(offerId: $offerId, shouldUseNewConditions: $shouldUseNewConditions) {
                ...EnrichedUserOffer
            }
        }
    }
    ${enrichedUserOfferFragment}
`;

export interface EnrichedUserOfferQueryResponse {
    __typename: 'Query';
    user: {
        __typename: 'User';
        enrichedOffer: EnrichedUserOffer;
    };
}

export interface EnrichedUserOfferQueryVariable {
    offerId: string;
    shouldUseNewConditions?: boolean;
}

export const favoriteOffersQuery = gql`
    query FavoriteOffersQuery {
        user {
            enrichedOffers(filterFavorited: true, filterAvailable: true, sort: true, computeEarnedPoints: false) {
                ...EnrichedUserOffer
            }
        }
    }
    ${enrichedUserOfferFragment}
`;

export interface FavoriteOffersQueryResponse {
    __typename: 'Query';
    user: {
        __typename: 'User';
        enrichedOffers: EnrichedUserOffer[];
    };
}

export interface FavoriteOffersQueryVariables {}

export const minimalUserOfferFragment = gql`
    fragment MinimalUserOffer on EnrichedUserOffer {
        offerId
        offer {
            offerId
            screen {
                title
            }
            browsingTagIds
            type
            matchToAffiliateTransaction
        }
        active
    }
`;

export interface MinimalUserOffer {
    __typename: 'EnrichedUserOffer';
    offerId: string;
    offer: {
        __typename: 'EnrichedMerchantOffer';
        offerId: string;
        screen: {
            title: string | null;
        };
        browsingTagIds: string[] | null;
        type: OfferType;
        matchToAffiliateTransaction: boolean | null;
    };
    active: boolean;
}

export const minimalNonOngoingUserOffersQuery = gql`
    query MinimalNonOngoingUserOffersQuery($devMode: Boolean) {
        user {
            enrichedOffers(
                devMode: $devMode
                returnMinimalData: true
                excludeOngoing: true
                filterAvailable: true
                sort: true
                computeEarnedPoints: false
            ) {
                ...MinimalUserOffer
            }
        }
    }
    ${minimalUserOfferFragment}
`;

export interface MinimalNonOngoingUserOffersQueryResponse {
    __typename: 'Query';
    user: {
        __typename: 'User';
        enrichedOffers: MinimalUserOffer[];
    };
}

export interface MinimalNonOngoingUserOffersQueryVariables {
    devMode?: boolean;
}
