import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { useWindowSize } from '../../style/utils';
import { getSocialIdentityCredentials, GetSocialIdentityCredentialsResponse, Provider } from '../../api/rest/user';
import { logAmplitudeEvent } from '../../lib/events/amplitudeEvents';
import LoadingFrame from '../../components/auth/LoadingFrame';
import IntroPanel from './IntroPanel';
import SocialSignUpPage from './SocialSignUpPage';

const JWT = require('jsonwebtoken');
const jwkToPem = require('jwk-to-pem');

const { v1: uuidv1 } = require('uuid');

const APPLE_OAUTH_URL = 'https://appleid.apple.com/auth/authorize';
const APPLE_CLIENT_ID = 'io.wylr.joko-web';

const LOCALHOST_REDIRECT_URL = 'https://joko.com/apple-login';

function AppleAuthPage() {
    const texts = getLocalizedTexts().auth.signIn.withApple;
    const size = useWindowSize();
    const [signUpData, errorMessage] = useHandleRedirection();
    if (!signUpData)
        return (
            <View style={styles.container}>
                <LoadingFrame
                    text={errorMessage || texts.loading}
                    height={(size.height || 0) - 120}
                    width={(size.width || 0) - 240}
                    isError={!!errorMessage}
                />
            </View>
        );
    else
        return (
            <View style={styles.containerSignUp}>
                <View style={styles.containerSignUpColumn}>
                    <IntroPanel />
                </View>
                <View style={styles.containerSignUpColumn}>
                    <SocialSignUpPage {...signUpData} />
                </View>
            </View>
        );
}

export default AppleAuthPage;

function useHandleRedirection(): [SignUpData | undefined, string | undefined] {
    const location = useLocation();
    const redirectUri =
        window.location.host === 'localhost:3000'
            ? LOCALHOST_REDIRECT_URL
            : `https://${window.location.host}${location.pathname}`;
    const [handleOAuthResponse, signUpData, errorMessage] = useHandleOAuthResponse();
    React.useEffect(() => {
        const match = location.hash?.match(`id_token=(.+)$`);
        if (match && match[1]) {
            const idToken = match[1];
            handleOAuthResponse(idToken);
        } else {
            if (window.location.host === 'localhost:3000')
                alert(`In order to test Apple sign-in in Localhost, replace "${LOCALHOST_REDIRECT_URL}" with this page URL`);
            const appleState = uuidv1();
            window.open(
                `${APPLE_OAUTH_URL}` +
                    `?client_id=${APPLE_CLIENT_ID}` +
                    `&redirect_uri=${redirectUri}` +
                    `&response_type=code id_token` +
                    `&state=${appleState}` +
                    `&response_mode=fragment`,
                window.location.host === 'localhost:3000' ? undefined : '_self'
            );
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return [signUpData, errorMessage];
}

interface SignUpData {
    initialEmail: string;
    credentials: GetSocialIdentityCredentialsResponse;
}

function useHandleOAuthResponse(): [(idToken: string) => void, SignUpData | undefined, string | undefined] {
    const texts = getLocalizedTexts().auth.signIn.withApple;
    const history = useHistory();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [signUpData, setSignUpData] = React.useState<SignUpData | undefined>(undefined);
    const handleOAuthResponse = async (idToken: string) => {
        try {
            const verifiedToken = await verifyAppleJWTToken(idToken);
            // Apple doesn't provide an email in the idToken for Work & School users, so we use a fall back for the rest of the flow. For more information see:
            // https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_rest_api/authenticating_users_with_sign_in_with_apple#3383773
            const appleEmail = verifiedToken.email || `${verifiedToken.sub}@apple.com`;
            const credentials = await getSocialIdentityCredentials({
                provider: Provider.Apple,
                providerUserId: verifiedToken.sub,
                email: appleEmail,
            });
            if (!credentials.new) await signIn(credentials);
            else setSignUpData({ initialEmail: appleEmail, credentials });
        } catch (error) {
            console.log(error);
            setErrorMessage(texts.error.default);
            logAmplitudeEvent({ name: 'Registration - Apple Login Failed' });
            setTimeout(() => history.push('/auth'), 1000);
        }
    };
    return [handleOAuthResponse, signUpData, errorMessage];
}

async function verifyAppleJWTToken(JWTToken: string): Promise<{ sub: string; email?: string }> {
    console.log('Apple Sign-in: verifying JWT token...');
    const decodedToken = JWT.decode(JWTToken, { complete: true });
    if (!decodedToken?.header?.kid) throw new Error("Apple Sign-in: missing 'kid' key in token header");
    const applePublicKey = await matchApplePublicKey(decodedToken.header.kid);
    const verifiedToken = JWT.verify(JWTToken, applePublicKey, { algorithms: ['RS256'] });
    console.log('Apple Sign-in: JWT token verified');
    if (!verifiedToken || !verifiedToken.sub || typeof verifiedToken.sub !== 'string')
        throw new Error(`Apple Sign-in: missing 'sub' key in verified JWT Token`);
    return verifiedToken;
}

const APPLE_PUBLIC_KEYS_URL = 'https://appleid.apple.com/auth/keys';

async function matchApplePublicKey(keyId: string): Promise<string> {
    const applePublicKeys = await fetch(APPLE_PUBLIC_KEYS_URL)
        .then((response) => response.json())
        .then((json) => json.keys);
    const matchingKey = applePublicKeys.find((key: any) => key?.kid === keyId);
    if (!matchingKey) throw new Error('Apple Sign-in: no matching public key found');
    const pemKey = jwkToPem(matchingKey);
    return pemKey;
}

async function signIn(credentials: GetSocialIdentityCredentialsResponse) {
    Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
    await Auth.signIn(credentials.userId, credentials.secretKey);
    logAmplitudeEvent({ name: 'Registration - Apple Login Succeeded' });
    window.location.reload();
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 60,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: color.white,
    },
    containerSignUp: {
        flex: 1,
        flexDirection: 'row',
    },
    containerSignUpColumn: {
        flex: 1,
    },
});
