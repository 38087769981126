import * as React from 'react';
import qs from 'qs';

import { getRegion, Region } from '../auth/region';
import { logAmplitudeEventWithoutAuthentication } from '../events/amplitudeEvents';
import { logEventWithoutAuthentication } from '../../api/rest/events';
import { browserKind, osVersion } from '../../lib/common/browser';
import { updateStoredDeviceId } from '../../lib/common/device';
import {
    getDoesBelongToTestGroupPreRegistration,
    parseUserSeed,
    PreRegistrationTwoGroupAbTestSettingsKey,
} from './preRegistrationUserSeed';

export const isOsVersion18OrLater = Number(osVersion) >= 18;

export type UrlStep =
    | 'activate'
    | 'authorize'
    | 'authorizeWithBanner'
    | 'conclusion'
    | 'missingAllWebsitesAuthorization'
    | 'missingAllWebsitesAuthorizationAfterRetry'
    | 'missingAuthorization'
    | 'temporaryAccountReplacement'
    | 'firstWaitingStep'
    | 'secondWaitingStep';

export function useParseUrlParameters(location: any): {
    userId: string | undefined;
    urlStep: UrlStep;
    isDevStackMode: boolean;
    isOnboarding: boolean;
    isBeforeOnboarding: boolean;
    isFlowWithSystemSettings: boolean;
    deviceId: string | undefined;
    region: Region;
    userSeed: number | undefined;
} {
    return React.useMemo(() => {
        const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
        return {
            userId: typeof parsedQuery?.userId === 'string' ? parsedQuery.userId : undefined,
            urlStep:
                parsedQuery?.step === 'authorize' ||
                parsedQuery?.step === 'authorizeWithBanner' ||
                parsedQuery?.step === 'conclusion' ||
                parsedQuery?.step === 'missingAllWebsitesAuthorization' ||
                parsedQuery?.step === 'missingAllWebsitesAuthorizationAfterRetry' ||
                parsedQuery?.step === 'missingAuthorization' ||
                parsedQuery?.step === 'temporaryAccountReplacement' ||
                parsedQuery?.step === 'firstWaitingStep' ||
                parsedQuery?.step === 'secondWaitingStep'
                    ? parsedQuery.step
                    : ('activate' as UrlStep),
            isDevStackMode: parsedQuery?.devStack === 'true',
            isOnboarding: parsedQuery?.isOnboarding === 'true',
            isBeforeOnboarding:
                parsedQuery?.isBeforeOnboarding === 'true' ||
                ((parsedQuery?.step === 'conclusion' || parsedQuery?.step === 'authorize') &&
                    checkHasRecentlyConsentedToMobileSafariExtensionTermsOfServiceAndPrivacyPolicy()),
            isFlowWithSystemSettings: parsedQuery?.isFlowWithSystemSettings === 'true',
            deviceId: typeof parsedQuery?.deviceId === 'string' ? parsedQuery.deviceId : undefined,
            region: getRegion(),
            userSeed: typeof parsedQuery?.userSeed === 'string' ? parseUserSeed(parsedQuery.userSeed) : undefined,
        };
    }, [location]);
}

export function useShouldUseRevampedOnboardingFlow(urlParameters: ReturnType<typeof useParseUrlParameters>) {
    const [shouldUseRevampedOnboardingFlow, setShouldUseRevampedOnboardingFlow] = React.useState<boolean | undefined>(
        undefined
    );
    React.useEffect(() => {
        const { userId, deviceId, isDevStackMode, userSeed, region } = urlParameters;
        async function updateShouldUseRevampedOnboardingFlow() {
            const { isRevampedOnboardingFlow, shouldSeeBanner } = await getPreRegistrationTestGroupAssignments({
                isDevStackMode,
                userSeed,
                region,
            });
            logRevampedOnboardingFlowAbTestGroups({
                userId,
                deviceId,
                isDevStackMode,
                isRevampedOnboardingFlow,
                shouldSeeBanner,
            });
            setShouldUseRevampedOnboardingFlow(isRevampedOnboardingFlow);
        }
        updateShouldUseRevampedOnboardingFlow();
    }, [urlParameters]);
    return shouldUseRevampedOnboardingFlow;
}

async function getPreRegistrationTestGroupAssignments({
    isDevStackMode,
    userSeed,
    region,
}: {
    isDevStackMode: boolean;
    userSeed?: number;
    region: Region;
}) {
    if (Number(osVersion) < 16)
        // Because there are some issues with how the animations are rendered on iOS 15, we use the legacy flow on iOS 15
        return { isRevampedOnboardingFlow: false, shouldSeeBanner: false };
    if (!isOsVersion18OrLater && (region === Region.US || region === Region.GB)) {
        // Because we don't have English animations for versions of Safari prior to iOS 18, we use the legacy flow for non-French users in production
        return { isRevampedOnboardingFlow: false, shouldSeeBanner: false };
    }
    if (!userSeed) {
        return { isRevampedOnboardingFlow: false, shouldSeeBanner: false };
    }
    if (isDevStackMode) {
        // Because we currently do not fetch the dev App Settings properly for un-logged-in users in the web app,
        // we hard-code the value to true for ease of testing
        return { isRevampedOnboardingFlow: true };
    }
    const [doesBelongToRevampedMobileSafariExtensionFlowTestGroup, doesBelongToBannerTestGroup] = await Promise.all([
        getDoesBelongToTestGroupPreRegistration(
            userSeed!,
            PreRegistrationTwoGroupAbTestSettingsKey.shouldUseRevampedMobileSafariExtensionInstallationFlowGroupProportion
        ),
        getDoesBelongToTestGroupPreRegistration(
            userSeed!,
            PreRegistrationTwoGroupAbTestSettingsKey.shouldShowBannerInRevampedMobileSafariExtensionInstallationFlowTestGroupProportion
        ),
    ]);
    return {
        isRevampedOnboardingFlow: doesBelongToRevampedMobileSafariExtensionFlowTestGroup,
        shouldSeeBanner: doesBelongToRevampedMobileSafariExtensionFlowTestGroup && doesBelongToBannerTestGroup,
    };
}

function logRevampedOnboardingFlowAbTestGroups({
    userId,
    deviceId,
    isDevStackMode,
    isRevampedOnboardingFlow,
    shouldSeeBanner,
}: {
    userId?: string;
    deviceId?: string;
    isDevStackMode: boolean;
    isRevampedOnboardingFlow: boolean;
    shouldSeeBanner?: boolean;
}) {
    const localStorageKey = 'hasAlreadyLoggedRevampedOnboardingFlowAbTestGroups';
    const hasAlreadyLoggedRevampedOnboardingFlowAbTestGroups = localStorage.getItem(localStorageKey);

    if (!hasAlreadyLoggedRevampedOnboardingFlowAbTestGroups) {
        localStorage.setItem(localStorageKey, 'true');
        logEventWithoutAuthentication(
            userId,
            {
                type: 'mobileSafariExtensionRevampedOnboardingFlowABTestGroupSet',
                payload: { isRevampedOnboardingFlow, shouldSeeBanner },
            },
            isDevStackMode
        );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Revamped Onboarding Flow AB Test Group Set',
                properties: { isRevampedOnboardingFlow, shouldSeeBanner },
            },
            { userId, deviceId }
        );
    }
}

export enum InstallationStep {
    activateStepPreamble = 'activateStepPreamble',
    activateStep = 'activateStep',
    authorizeStepPreamble = 'authorizeStepPreamble',
    authorizeStep = 'authorizeStep',
    authorizeWithBannerStep = 'authorizeWithBannerStep',
    conclusionStep = 'conclusionStep',
    missingAllWebsitesAuthorization = 'missingAllWebsitesAuthorization',
    missingAllWebsitesAuthorizationAfterRetry = 'missingAllWebsitesAuthorizationAfterRetry',
    missingAuthorization = 'missingAuthorization',
    firstWaitingStep = 'firstWaitingStep',
    secondWaitingStep = 'secondWaitingStep',
}

export function useInstallationStep({
    urlStep,
    userId,
    isDevStackMode,
    shouldSkipActivationPreamble,
    shouldSkipAuthorizationPreamble,
}: {
    urlStep: UrlStep;
    userId: string | undefined;
    isDevStackMode: boolean;
    shouldSkipActivationPreamble?: boolean;
    shouldSkipAuthorizationPreamble?: boolean;
}): [InstallationStep | undefined, React.Dispatch<React.SetStateAction<InstallationStep | undefined>>] {
    const [step, setStep] = React.useState<InstallationStep | undefined>(undefined);
    React.useEffect(() => {
        if (urlStep === 'activate') {
            if (shouldSkipActivationPreamble) setStep(InstallationStep.activateStep);
            else setStep(InstallationStep.activateStepPreamble);
        } else if (urlStep === 'authorize') {
            if (shouldSkipAuthorizationPreamble) setStep(InstallationStep.authorizeStep);
            else setStep(InstallationStep.authorizeStepPreamble);
        } else if (urlStep === 'authorizeWithBanner') setStep(InstallationStep.authorizeWithBannerStep);
        else if (urlStep === 'conclusion' || urlStep === 'temporaryAccountReplacement')
            setStep(InstallationStep.conclusionStep);
        else if (urlStep === 'missingAllWebsitesAuthorization') setStep(InstallationStep.missingAllWebsitesAuthorization);
        else if (urlStep === 'missingAllWebsitesAuthorizationAfterRetry')
            setStep(InstallationStep.missingAllWebsitesAuthorizationAfterRetry);
        else if (urlStep === 'missingAuthorization') setStep(InstallationStep.missingAuthorization);
        else if (urlStep === 'firstWaitingStep') setStep(InstallationStep.firstWaitingStep);
        else if (urlStep === 'secondWaitingStep') setStep(InstallationStep.secondWaitingStep);
    }, [urlStep, userId, isDevStackMode, shouldSkipActivationPreamble, shouldSkipAuthorizationPreamble]);
    return [step, setStep];
}

export function useLogEvents({
    urlStep,
    userId,
    deviceId,
    isDevStackMode,
    isOnboarding,
    isBeforeOnboarding,
    isFlowWithSystemSettings,
    isRevampedOnboardingFlow,
}: {
    urlStep: UrlStep;
    userId: string | undefined;
    deviceId: string | undefined;
    isDevStackMode: boolean;
    isOnboarding: boolean;
    isBeforeOnboarding: boolean;
    isFlowWithSystemSettings: boolean;
    isRevampedOnboardingFlow: boolean;
}) {
    React.useEffect(() => {
        // The `deviceId` is reset if needed, in order to be correctly set for future page openings
        updateStoredDeviceId(deviceId);
        if (urlStep === 'activate') {
            logEventWithoutAuthentication(
                userId,
                {
                    type: 'viewedActivationPageMobileSafariExtensionInstallationFlow',
                    payload: { isOnboarding, isBeforeOnboarding, isRevampedOnboardingFlow },
                },
                isDevStackMode
            );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Viewed Activation Page',
                    properties: { browserKind, isOnboarding, isBeforeOnboarding, isRevampedOnboardingFlow },
                },
                { userId, deviceId }
            );
        } else if (urlStep === 'authorize' || urlStep === 'authorizeWithBanner') {
            const isFlowWithBanner = urlStep === 'authorizeWithBanner';
            logEventWithoutAuthentication(
                userId,
                {
                    type: 'viewedAuthorizationPageMobileSafariExtension',
                    payload: {
                        isBeforeOnboarding,
                        isFlowWithSystemSettings,
                        isFlowWithBanner,
                        isRevampedOnboardingFlow,
                    },
                },
                isDevStackMode
            );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Viewed Authorization Page',
                    properties: { isBeforeOnboarding, isFlowWithSystemSettings, isFlowWithBanner, isRevampedOnboardingFlow },
                },
                { userId, deviceId }
            );
        } else if (urlStep === 'conclusion') {
            logEventWithoutAuthentication(
                userId,
                {
                    type: 'viewedConclusionPageMobileSafariExtension',
                    payload: { isBeforeOnboarding, isRevampedOnboardingFlow },
                },
                isDevStackMode
            );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Viewed Conclusion Page',
                    properties: { isBeforeOnboarding, isRevampedOnboardingFlow },
                },
                { userId, deviceId }
            );
        } else if (urlStep === 'missingAllWebsitesAuthorization') {
            logEventWithoutAuthentication(
                userId,
                {
                    type: 'viewedMissingAllWebsitesAuthorizationPageMobileSafariExtension',
                    payload: { isRevampedOnboardingFlow },
                },
                isDevStackMode
            );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Viewed Missing All-Websites Authorization Page',
                    properties: { isRevampedOnboardingFlow },
                },
                { userId, deviceId }
            );
        } else if (urlStep === 'missingAllWebsitesAuthorizationAfterRetry') {
            logEventWithoutAuthentication(
                userId,
                {
                    type: 'viewedMissingAllWebsitesAuthorizationAfterRetryPageMobileSafariExtension',
                    payload: { isRevampedOnboardingFlow },
                },
                isDevStackMode
            );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Viewed Missing All-Websites Authorization After Retry Page',
                    properties: { isRevampedOnboardingFlow },
                },
                { userId, deviceId }
            );
        } else if (urlStep === 'missingAuthorization') {
            logEventWithoutAuthentication(
                userId,
                { type: 'viewedMissingAuthorizationPageMobileSafariExtension', payload: { isRevampedOnboardingFlow } },
                isDevStackMode
            );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Viewed Missing Authorization Page',
                    properties: { isRevampedOnboardingFlow },
                },
                { userId, deviceId }
            );
        } else if (urlStep === 'firstWaitingStep' || urlStep === 'secondWaitingStep')
            // We only log an Amplitude event because the waiting pages are opened by the MSE without userId information in the URL parameters
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Viewed Waiting Page',
                    properties: { step: urlStep, isRevampedOnboardingFlow },
                },
                {}
            );
    }, [
        urlStep,
        userId,
        isDevStackMode,
        isOnboarding,
        isBeforeOnboarding,
        isFlowWithSystemSettings,
        deviceId,
        isRevampedOnboardingFlow,
    ]);
}

export function getCallbacks(
    {
        step,
        setStep,
        userId,
        deviceId,
        isDevStackMode,
        isOnboarding,
        isBeforeOnboarding,
        isFlowWithSystemSettings,
    }: {
        step: InstallationStep | undefined;
        setStep: (step: InstallationStep) => void;
        userId: string | undefined;
        deviceId: string | undefined;
        isDevStackMode: boolean;
        isOnboarding: boolean;
        isBeforeOnboarding: boolean;
        isFlowWithSystemSettings: boolean;
    },
    isRevampedOnboardingFlow: boolean = false
) {
    const onPressStartActivation = () => {
        setStep(InstallationStep.activateStep);
        logEventWithoutAuthentication(
            userId,
            {
                type: 'clickedStartActivationMobileSafariExtensionInstallationFlow',
                payload: { isOnboarding, isBeforeOnboarding, isRevampedOnboardingFlow },
            },
            isDevStackMode
        );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Start Activation',
                properties: { isOnboarding, isBeforeOnboarding, isRevampedOnboardingFlow },
            },
            { userId, deviceId }
        );
    };
    const onPressStartAuthorization = () => {
        setStep(InstallationStep.authorizeStep);
        logEventWithoutAuthentication(
            userId,
            {
                type: 'clickedStartAuthorizationMobileSafariExtensionInstallationFlow',
                payload: { isBeforeOnboarding, isFlowWithSystemSettings, isRevampedOnboardingFlow },
            },
            isDevStackMode
        );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Start Authorization',
                properties: { isBeforeOnboarding, isFlowWithSystemSettings, isRevampedOnboardingFlow },
            },
            { userId, deviceId }
        );
    };
    const onPressCloseMissingAllWebsitesAuthorizationModal = (hasUserClickedModifyAuthorization: boolean) => {
        setStep(InstallationStep.authorizeStep);
        if (hasUserClickedModifyAuthorization) {
            logEventWithoutAuthentication(
                userId,
                {
                    type: 'clickedModifyAuthorizationMobileSafariExtensionInstallationFlow',
                    payload: { from: 'missingAllWebsitesAuthorizationPopup', isRevampedOnboardingFlow },
                },
                isDevStackMode
            );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Clicked Modify Authorization',
                    properties: { from: 'missingAllWebsitesAuthorizationPopup', isRevampedOnboardingFlow },
                },
                { userId, deviceId }
            );
        } else {
            logEventWithoutAuthentication(
                userId,
                {
                    type: 'closedMissingAuthorizationPopupMobileSafariExtensionInstallationFlow',
                    payload: { isRevampedOnboardingFlow },
                },
                isDevStackMode
            );
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Mobile Safari Extension - Closed Missing Authorization Popup',
                    properties: { isRevampedOnboardingFlow },
                },
                { userId, deviceId }
            );
        }
    };
    const onPressModifyAuthorization = () => {
        setStep(InstallationStep.authorizeStep);
        logEventWithoutAuthentication(
            userId,
            {
                type: 'clickedModifyAuthorizationMobileSafariExtensionInstallationFlow',
                payload: { from: 'missingAuthorizationPage', isRevampedOnboardingFlow },
            },
            isDevStackMode
        );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Modify Authorization',
                properties: { from: 'missingAuthorizationPage', isRevampedOnboardingFlow },
            },
            { userId, deviceId }
        );
    };
    const onPressReturnToApp = () => {
        const payload = { installationStep: step, isRevampedOnboardingFlow };
        logEventWithoutAuthentication(userId, { type: 'clickedReturnToAppFromMobileSafariExtension', payload });
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Return To App',
                properties: payload,
            },
            { userId, deviceId }
        );
        window.location.href = 'joko://';
    };
    const onPressGoToGoogle = () => {
        const payload = { installationStep: step, isRevampedOnboardingFlow };
        logEventWithoutAuthentication(userId, {
            type: 'clickedFinishLaterFromMissingAuthorizationPageMobileSafariExtension',
            payload: payload,
        });
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Finish Later From Missing Authorization Page',
                properties: payload,
            },
            { userId, deviceId }
        );
        window.location.href = 'https://www.google.com';
    };
    return {
        onPressStartActivation,
        onPressStartAuthorization,
        onPressCloseMissingAllWebsitesAuthorizationModal,
        onPressModifyAuthorization,
        onPressReturnToApp,
        onPressGoToGoogle,
    };
}
const REDIRECT_TO_APP_TIMEOUT = 3000;

export function useRedirectToAppAtConclusionStepAfterTimeout({
    isOnboarding,
    isBeforeOnboarding,
    urlStep,
}: {
    isOnboarding: boolean;
    isBeforeOnboarding: boolean;
    urlStep: UrlStep;
}) {
    React.useEffect(() => {
        let timeoutId: NodeJS.Timeout | undefined;
        if ((isOnboarding || isBeforeOnboarding) && urlStep === 'conclusion')
            timeoutId = setTimeout(() => window.location.replace('joko://'), REDIRECT_TO_APP_TIMEOUT);
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [isOnboarding, isBeforeOnboarding, urlStep]);
}

export const MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_LOCAL_STORAGE_KEY =
    '@ConsentsStore:mobileSafariExtensionTermsOfServiceAndPrivacyPolicyConsent';

export const MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_TIMESTAMP_LOCAL_STORAGE_KEY =
    '@ConsentsStore:mobileSafariExtensionTermsOfServiceAndPrivacyPolicyConsentTimestampMs';

export const MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_IS_DEV_STACK_MODE_LOCAL_STORAGE_KEY =
    '@ConsentsStore:mobileSafariExtensionTermsOfServiceAndPrivacyPolicyConsentIsDevStackMode';

export function setMobileSafariExtensionTermsOfServiceAndPrivacyPolicyConsentInLocalStorage(isDevStackMode: boolean) {
    localStorage.setItem(MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_LOCAL_STORAGE_KEY, 'true');
    localStorage.setItem(MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_TIMESTAMP_LOCAL_STORAGE_KEY, JSON.stringify(Date.now()));
    localStorage.setItem(
        MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_IS_DEV_STACK_MODE_LOCAL_STORAGE_KEY,
        isDevStackMode ? 'true' : 'false'
    );
}

function checkHasRecentlyConsentedToMobileSafariExtensionTermsOfServiceAndPrivacyPolicy() {
    try {
        return (
            localStorage.getItem(MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_LOCAL_STORAGE_KEY) === 'true' &&
            !!localStorage.getItem(MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_TIMESTAMP_LOCAL_STORAGE_KEY) &&
            Date.now() -
                Number(localStorage.getItem(MOBILE_SAFARI_EXTENSION_TOS_AND_PP_CONSENT_TIMESTAMP_LOCAL_STORAGE_KEY)) <
                15 * 60 * 1000
        );
    } catch {
        return false;
    }
}

// After activation and authorization in the MSE setup flow, the instruction pages are opened by the MSE in the focused tab except for the following exception: in the purpose of blocking a native display, two waiting pages are opened in a new tab.
// We implement a communication mechanism to close the obsolete pages in the background (activation and first waiting page), when the second waiting page is opened.
const BROADCAST_CHANNEL_NAME = 'closeOldInstructionPagesBroadcastChannel';
const SECOND_WAITING_PAGE_OPENED_MESSAGE = 'secondWaitingPageOpened';
const ALLOWED_MESSAGE_ORIGINS = [
    `https://app.hellojoko.com`,
    `https://dev.app.hellojoko.com`,
    `https://app.joko.com`,
    `https://dev.app.joko.com`,
];

export function useCloseObsoletePagesWhenSecondWaitingPageIsOpened(step: InstallationStep | undefined) {
    React.useEffect(() => {
        if (
            step !== InstallationStep.secondWaitingStep &&
            step !== InstallationStep.activateStepPreamble &&
            step !== InstallationStep.activateStep &&
            step !== InstallationStep.firstWaitingStep
        )
            return;
        const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
        if (step === InstallationStep.secondWaitingStep)
            setTimeout(() => broadcastChannel.postMessage(SECOND_WAITING_PAGE_OPENED_MESSAGE), 2000);
        else if (
            step === InstallationStep.activateStepPreamble ||
            step === InstallationStep.activateStep ||
            step === InstallationStep.firstWaitingStep
        )
            broadcastChannel.addEventListener('message', (message) => {
                if (
                    message.data === SECOND_WAITING_PAGE_OPENED_MESSAGE &&
                    !!message.origin &&
                    ALLOWED_MESSAGE_ORIGINS.includes(message.origin)
                )
                    window.close();
            });
        return () => broadcastChannel.close(); // This closes the broadcast channel and removes the event listener on component unmount
    }, [step]);
}
