import gql from 'graphql-tag';

export const transactionEligibilityConditionsFragment = gql`
    fragment TransactionEligibilityConditions on TransactionEligibilityConditions {
        dateMin
        dateMax
        valueMin
        valueMax
    }
`;

export const offerConditionsFragment = gql`
    fragment OfferConditions on OfferConditions {
        description
        transactionEligibility {
            ...TransactionEligibilityConditions
        }
        standardConditionIds
        customConditions {
            summary
            description
        }
    }
    ${transactionEligibilityConditionsFragment}
`;

export const offerCardFragment = gql`
    fragment OfferCard on OfferCard {
        title
        subtitle
        logoURL
        bannerURL
    }
`;

export const offerScreenFragment = gql`
    fragment OfferScreen on OfferScreen {
        title
        subtitle
        logoURL
        bannerURL
    }
`;

export const rateForDisplayFragment = gql`
    fragment RateForDisplay on RateForDisplay {
        points
        fixedPoints
        nonBoostedPoints
        nonBoostedFixedPoints
        text
    }
`;

export const merchantOfferFragment = gql`
    fragment MerchantOffer on MerchantOffer {
        offerId
        merchantId
        type
        points
        fixedPoints
        nonBoostedPoints
        nonBoostedFixedPoints
        multipleRatesForDisplay {
            ...RateForDisplay
        }
        logoURL
        conditions {
            ...OfferConditions
        }
        card {
            ...OfferCard
        }
        screen {
            ...OfferScreen
        }
        affiliateLink
        website
        browsingTagIds
    }
    ${rateForDisplayFragment}
    ${offerConditionsFragment}
    ${offerCardFragment}
    ${offerScreenFragment}
`;

export enum OfferType {
    Ongoing = 'ongoing',
    OneTime = 'onetime',
    Online = 'online',
}

export interface TransactionEligibilityConditions {
    __typename: 'TransactionEligibilityConditions';
    dateMin: string | null;
    dateMax: string | null;
    valueMin: number | null;
    valueMax: number | null;
}

export interface CustomCondition {
    __typename: 'CustomCondition';
    summary: string;
    description: string | null;
}

export interface OfferConditions {
    __typename: 'OfferConditions';
    description: string | null;
    transactionEligibility: TransactionEligibilityConditions | null;
    standardConditionIds: string[] | null;
    customConditions: CustomCondition[] | null;
}

export interface OfferCard {
    __typename: 'OfferCard';
    title: string | null;
    subtitle: string | null;
    logoURL: string | null;
    bannerURL: string | null;
}

export interface OfferScreen {
    __typename: 'OfferScreen';
    title: string | null;
    subtitle: string;
    logoURL: string;
    bannerURL: string | null;
}

export interface RateForDisplay {
    __typename: 'RateForDisplay';
    points: number | null;
    fixedPoints: number | null;
    nonBoostedPoints: number | null;
    nonBoostedFixedPoints: number | null;
    text: string | null;
}

export interface MerchantOffer {
    __typename: 'MerchantOffer';
    offerId: string;
    merchantId: string;
    type: OfferType;
    points: number;
    fixedPoints: number;
    nonBoostedPoints: number | null;
    nonBoostedFixedPoints: number | null;
    multipleRatesForDisplay: RateForDisplay[] | null;
    logoURL: string | null;
    conditions: OfferConditions | null;
    card: OfferCard | null;
    screen: OfferScreen;
    affiliateLink: string | null;
    website: string | null;
    browsingTagIds: string[];
}

export function dataIdFromMerchantOffer(offer: MerchantOffer | { offerId: string }) {
    return `MerchantOffer:${offer.offerId}`;
}

export const dataIdFromUserOffer = (userOffer: { offerId: string }) => {
    return `UserOffer:${userOffer.offerId}`;
};

export const offerConditionFragment = gql`
    fragment OfferCondition on OfferCondition {
        conditionId
        summary
        description
    }
`;

export interface OfferCondition {
    __typename: 'OfferCondition';
    conditionId: string;
    summary: string;
    description: string | null;
}

export const dataIdFromOfferCondition = (condition: OfferCondition) => {
    return `OfferCondition:${condition.conditionId}`;
};

export interface OfferConditionList {
    __typename: 'OfferConditionList';
    items: OfferCondition[];
    nextToken: string | null;
}
