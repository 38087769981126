import gql from 'graphql-tag';

import { BankDetails } from '../../../lib/bank/bankDetails';

export const redeemRewardMutation = gql`
    mutation RedeemReward($rewardId: String!, $points: Int, $userBankDetails: UserRewardUserBankDetailsInput) {
        redeemReward(rewardId: $rewardId, points: $points, userBankDetails: $userBankDetails) {
            createdAt
            rewardId
            points
        }
    }
`;

export const redeemBankTransferUKMutation = gql`
    mutation RedeemBankTransferUK($rewardId: String!, $points: Int, $userBankDetails: UserRewardUserBankDetailsUKInput) {
        redeemBankTransferUK(rewardId: $rewardId, points: $points, userBankDetails: $userBankDetails) {
            createdAt
            rewardId
            points
        }
    }
`;

export const redeemBankTransferUSMutation = gql`
    mutation RedeemBankTransferUS($rewardId: String!, $points: Int, $userBankDetails: UserRewardUserBankDetailsUSInput) {
        redeemBankTransferUS(rewardId: $rewardId, points: $points, userBankDetails: $userBankDetails) {
            createdAt
            rewardId
            points
        }
    }
`;

export interface RedeemRewardMutationResponse {
    __typename: 'Mutation';
    redeemReward: {
        __typename: 'UserReward';
        createdAt: number;
        rewardId: string;
        points: number;
    };
}

export interface RedeemRewardMutationVariables {
    rewardId: string;
    points?: number;
    userBankDetails?: BankDetails;
}
