import React from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity, ScrollView, ImageSourcePropType, ImageStyle } from 'react-native';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';
import { useHistory, useLocation } from 'react-router';
import { useApolloClient } from '@apollo/react-hooks';
import { Auth } from 'aws-amplify';
import moment from 'moment';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { userRegionVar } from '../../api/graphql/reactiveVariables';
import { SettingKey } from '../../api/graphql/fragments/settings';
import { Gender } from '../../api/graphql/fragments/user';
import { updateUser } from '../../api/graphql/mutations/updateUser';
import { getPublicSetting } from '../../api/rest/settings';
import { Region } from '../../lib/auth/region';
import { logAmplitudeEvent } from '../../lib/events/amplitudeEvents';
import { logUserEventUtil } from '../../lib/events/userEvents';
import { usePrevious } from '../../lib/common/utilHooks';
import { getDevStackMode } from '../../lib/common/devStackMode';
import { CognitoUserAttributeName } from '../../lib/common/cognito';
import { isFirstNameValid, isAgeValid, isReferrerCodeValid, queryUser } from '../../lib/onboarding/userInfo';
import { useEmailSignUp } from '../../lib/auth/emailSignUp';
import {
    EmailInput,
    PasswordInput,
    TermsOfServiceConsentCheckBox,
    NewsletterConsentCheckBox,
    SignUpErrorMessage,
} from '../auth/EmailSignUpPage';
import { IS_USER_LOGGING_FROM_WEB_APP_LOCATION_STATE } from '../auth/SignInPage';
import Button from '../../components/common/Button';
import HighlightedText from '../../components/common/HighlightedText';
import PasswordRequirements, { usePasswordRequirementsChecks } from '../../components/auth/PasswordRequirements';
import { FirstNameInput, AgeInput, ReferrerCodeInput } from '../../components/onboarding/UserInfoInputs';
import GenderButtons from '../../components/onboarding/GenderButtons';
import { HoverableTouchableOpacity } from '../../components/common/HoverableComponents';
import { MarketingWebsitePath, getMarketingWebsiteUrl, getHelpCenterUrl } from '../../lib/auth/region';

const jokoLogo = { uri: '/assets/images/logos/logo-with-title.png' };
const jokoLogoWhite = { uri: '/assets/images/logos/logo-with-title-white.svg' };
const appleLogo = { uri: '/assets/images/logos/apple.svg' };
const googleLogo = { uri: '/assets/images/logos/google.svg' };
const facebookLogo = { uri: '/assets/images/logos/facebook.svg' };
const lightningRightLogo = { uri: '/assets/images/visualIdentity/lightning-blue-right-cropped.svg' };
const lightningLeftLogo = { uri: '/assets/images/visualIdentity/lightning-blue-left-cropped.svg' };
const boltsIcon = { uri: '/assets/images/visualIdentity/bolts.svg' };
const playStoreRatingLogo = { uri: '/assets/images/logos/ratings/play-store-rating.png' };
const appleStoreRatingLogo = { uri: '/assets/images/logos/ratings/apple-store-rating.png' };
const trustpilotRatingLogo = { uri: '/assets/images/logos/ratings/trustpilot-rating.png' };
const signUpIllustration = { uri: '/assets/images/illustrations/sign-up.svg' };
const shopOnDesktopIllustration = { uri: '/assets/images/illustrations/shop-on-desktop.svg' };
const bankIllustration = { uri: '/assets/images/illustrations/bank.svg' };
const teamPicture = { uri: '/assets/images/pictures/team.jpg' };
const mailIcon = { uri: '/assets/images/icons/mail.svg' };
const cartIcon = { uri: '/assets/images/icons/cart-green.svg' };
const helpIcon = { uri: '/assets/images/icons/help-green.svg' };
const giftIcon = { uri: '/assets/images/icons/gift-green.svg' };

const DEFAULT_MERCHANT_LOGO_URIS = [
    { uri: '/assets/images/logos/merchants/logo-aliexpress.png' },
    { uri: '/assets/images/logos/merchants/logo-asos.png' },
    { uri: '/assets/images/logos/merchants/logo-fnac.png' },
    { uri: '/assets/images/logos/merchants/logo-sarenza.png' },
    { uri: '/assets/images/logos/merchants/logo-cdiscount.png' },
    { uri: '/assets/images/logos/merchants/logo-nike.png' },
    { uri: '/assets/images/logos/merchants/logo-shein.png' },
    { uri: '/assets/images/logos/merchants/logo-boulanger.png' },
    { uri: '/assets/images/logos/merchants/logo-conforama.png' },
    { uri: '/assets/images/logos/merchants/logo-booking.png' },
    { uri: '/assets/images/logos/merchants/logo-fdj.png' },
    { uri: '/assets/images/logos/merchants/logo-groupon.png' },
];

const PLAY_STORE_URL = 'https://hellojoko.app.link/XbizOK3axP';
const APP_STORE_URL = 'https://hellojoko.app.link/atriePabxP';
const TRUSTPILOT_URL = 'https://fr.trustpilot.com/review/hellojoko.com';
const DEV_WEB_APP_LANDING_PAGE_VERSION_RESOURCES_URL =
    'https://joko-web-app-landing-page-versions-911473691590.s3.eu-west-1.amazonaws.com';
const PROD_WEB_APP_LANDING_PAGE_VERSION_RESOURCES_URL =
    'https://joko-web-app-landing-page-versions-910637121509.s3.eu-west-1.amazonaws.com';

function LandingPage() {
    const texts = getLocalizedTexts().landing;
    const scrollViewComponentRef = React.useRef<ScrollView | null>(null);
    const scrollToTop = () => scrollViewComponentRef.current?.scrollTo(0);
    React.useEffect(() => {
        document.title = texts.title;
    }, []);
    return (
        <ScrollView style={{ minWidth: 1250 }} ref={(component: any) => (scrollViewComponentRef.current = component)}>
            <Header />
            <View>
                <HowItWorks />
                <SignUpButton onPress={scrollToTop} />
                <Testimonials />
                <Image source={lightningRightLogo} style={styles.imageLightningRight} />
                <Image source={lightningLeftLogo} style={styles.imageLightningLeft} />
                <GradientBackground />
            </View>
            <ValueProposition />
            <SignUpButton onPress={scrollToTop} />
            <About />
            <SignUpButton onPress={scrollToTop} />
            <Footer {...{ scrollToTop }} />
        </ScrollView>
    );
}

export default LandingPage;

function Header() {
    const texts = getLocalizedTexts().landing.header;
    const devStackMode = getDevStackMode();
    const history = useHistory();
    return (
        <View style={[styles.containerHeader, { backgroundColor: devStackMode ? color.azalea : color.emerald }]}>
            <View style={{ marginHorizontal: 50, marginTop: 35, marginBottom: 40 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', height: HEADER_LOGO_HEIGHT }}>
                    <Image source={jokoLogoWhite} style={styles.imageJokoLogoHeader} />
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.textAlreadySignedUp}>{texts.alreadySignedUp} </Text>
                        <HoverableTouchableOpacity
                            onPress={() => {
                                // We add a state here to differentiate between the two possible origins for the login flow: web-app or extension
                                history.push({
                                    pathname: '/auth/sign-in',
                                    state: IS_USER_LOGGING_FROM_WEB_APP_LOCATION_STATE,
                                });
                                window.location.reload();
                            }}
                            activeOpacity={0.8}
                            hoveredStyle={{ opacity: 0.8 }}>
                            <Text style={styles.textSignIn}>{texts.signIn}</Text>
                        </HoverableTouchableOpacity>
                    </View>
                </View>
                <View style={styles.containerHeaderContent}>
                    <Presentation />
                    <SignUp />
                </View>
            </View>
        </View>
    );
}

function Presentation() {
    const texts = getLocalizedTexts().landing.header;
    const presentationContent = usePresentationContent();
    const presentationTexts = texts.presentation;
    return (
        <View style={styles.containerPresentation}>
            <View style={{ maxWidth: 670 }}>
                <Text style={styles.textPresentationTitle}>{presentationContent.title}</Text>
                <Text style={styles.textPresentationSubtitle}>{presentationContent.subtitle}</Text>
            </View>
            <View style={{ maxWidth: 740 }}>
                <MerchantsLogosRow merchantsUris={presentationContent.merchantLogoUris?.slice(0, 4) ?? []} />
                <MerchantsLogosRow merchantsUris={presentationContent.merchantLogoUris?.slice(4, 8) ?? []} />
                <MerchantsLogosRow merchantsUris={presentationContent.merchantLogoUris?.slice(8, 12) ?? []} />
                <Text style={styles.textPresentationMoreMerchants}>{presentationTexts.moreMerchants}</Text>
            </View>
        </View>
    );
}

type PresentationContent = {
    title?: string;
    subtitle?: string;
    merchantLogoUris?: { uri: string }[];
};

function usePresentationContent() {
    const texts = getLocalizedTexts().landing.header;
    const location = useLocation();
    const [presentationContent, setPresentationContent] = React.useState<PresentationContent>({});
    const defaultPresentationTexts = texts.presentation;
    const setDefaultPresentationContent = () => {
        setPresentationContent({
            title: defaultPresentationTexts.title,
            subtitle: defaultPresentationTexts.subtitle,
            merchantLogoUris: DEFAULT_MERCHANT_LOGO_URIS,
        });
    };
    React.useEffect(() => {
        const landingPageVersion = new URLSearchParams(location.search).get('v');
        if (landingPageVersion) {
            const headers: Headers = new Headers();
            headers.append('Accept', 'application/json');
            headers.append('Content-Type', 'application/json');
            const baseUrl = getDevStackMode()
                ? DEV_WEB_APP_LANDING_PAGE_VERSION_RESOURCES_URL
                : PROD_WEB_APP_LANDING_PAGE_VERSION_RESOURCES_URL;
            fetch(`${baseUrl}/${landingPageVersion}.json`, { method: 'GET', headers: headers })
                .then((response) => response.json())
                .then((json) => {
                    const versionSpecificContent = JSON.parse(json) as {
                        webAppLandingPageVersionId?: string;
                        headlineText1?: string;
                        headlineText2?: string;
                        merchantLogoURLs?: string[];
                    } | null;
                    setPresentationContent({
                        title: versionSpecificContent?.headlineText1 ?? defaultPresentationTexts.title,
                        subtitle: versionSpecificContent?.headlineText2 ?? defaultPresentationTexts.subtitle,
                        merchantLogoUris:
                            versionSpecificContent?.merchantLogoURLs?.map((uri) => ({ uri })) ?? DEFAULT_MERCHANT_LOGO_URIS,
                    });
                })
                .catch(() => {
                    setDefaultPresentationContent();
                });
        } else setDefaultPresentationContent();
    }, []);
    return presentationContent;
}

function MerchantsLogosRow({ merchantsUris }: { merchantsUris: { uri: string }[] }) {
    return (
        <View style={styles.containerMerchantsLogosRow}>
            {merchantsUris.map((uri) => (
                <View style={styles.containerMerchantLogo} key={uri.uri}>
                    <Image source={uri} style={styles.imageMerchantLogo} />
                </View>
            ))}
        </View>
    );
}

enum SignUpState {
    pending = 'pending',
    running = 'running',
    completed = 'completed',
}

function SignUp() {
    const texts = getLocalizedTexts().landing.header;
    const history = useHistory();
    const [signUpState, setSignUpState] = React.useState<SignUpState>(SignUpState.pending);
    const signUpTexts = texts.signUp;
    return (
        <View style={{ flex: 1 }}>
            <View style={{ maxWidth: 380 }}>
                <Image source={boltsIcon} style={styles.imageSignUpTitle} />
                <Text style={styles.textSignUpTitle}>{signUpTexts.title}</Text>
                <View style={{ paddingTop: 15 }}>
                    <Button
                        onPress={() => {
                            logAmplitudeEvent({ name: 'Registration - Clicked Apple Register' });
                            history.push('/auth/sign-up/apple');
                            window.location.reload();
                        }}
                        useSecondaryColor
                        height={40}
                        style={{ marginBottom: 5 }}
                        icon={<Image source={appleLogo} style={styles.imageSocialSignUp} />}>
                        {signUpTexts.withAppleButton}
                    </Button>
                    <Button
                        onPress={() => {
                            logAmplitudeEvent({ name: 'Registration - Clicked Google Register' });
                            history.push('/auth/sign-up/google');
                            window.location.reload();
                        }}
                        useSecondaryColor
                        height={40}
                        style={{ marginBottom: 5 }}
                        icon={<Image source={googleLogo} style={styles.imageSocialSignUp} />}>
                        {signUpTexts.withGoogleButton}
                    </Button>
                    <Button
                        onPress={() => {
                            logAmplitudeEvent({ name: 'Registration - Clicked Facebook Register' });
                            history.push('/auth/sign-up/facebook');
                            window.location.reload();
                        }}
                        useSecondaryColor
                        height={40}
                        icon={<Image source={facebookLogo} style={styles.imageSocialSignUp} />}>
                        {signUpTexts.withFacebookButton}
                    </Button>
                </View>
                <Text style={styles.textSocialAndEmailSeparator}>{signUpTexts.socialAndEmailSeparator}</Text>
                {signUpState === SignUpState.pending ? (
                    <EmailSignUp
                        onSignUpStart={() => setSignUpState(SignUpState.running)}
                        onSignUpEnd={() => setSignUpState(SignUpState.completed)}
                    />
                ) : (
                    <Onboarding {...{ signUpState }} />
                )}
            </View>
        </View>
    );
}

function EmailSignUp({ onSignUpStart, onSignUpEnd }: { onSignUpStart: () => void; onSignUpEnd: () => void }) {
    const texts = getLocalizedTexts().landing.header.signUp;
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const previousEmail = usePrevious(email);
    const previousPassword = usePrevious(password);
    const passwordRequirementsChecks = usePasswordRequirementsChecks(password);
    const [consentedToTermsOfService, setConsentedToTermsOfService] = React.useState<boolean>(false);
    const previousConsentedToTermsOfService = usePrevious(consentedToTermsOfService);
    const [consentedToNewsletter, setConsentedToNewsletter] = React.useState<boolean>(false);
    const [signUp, isLoading, error, clearError] = useEmailSignUp({ onSignUpStart, onSignUpEnd });
    const onPress = async () => {
        await logAmplitudeEvent({ name: 'Registration - Clicked Email Register' });
        signUp(email, password, passwordRequirementsChecks, consentedToTermsOfService, consentedToNewsletter);
    };
    React.useEffect(() => {
        if (
            email.length !== previousEmail?.length ||
            password.length !== previousPassword?.length ||
            consentedToTermsOfService !== previousConsentedToTermsOfService
        )
            clearError();
    }, [
        email,
        password,
        previousEmail,
        previousPassword,
        consentedToTermsOfService,
        previousConsentedToTermsOfService,
        clearError,
    ]);
    return (
        <View style={styles.containerEmailSignUpContent}>
            <View style={{ padding: 25 }}>
                <EmailInput {...{ email, setEmail, isLoading }} />
                <PasswordInput {...{ password, setPassword, isLoading, onPress }} style={{ marginTop: 10 }} />
                <PasswordRequirements {...{ passwordRequirementsChecks }} style={{ marginTop: 8 }} />
                <View style={{ marginTop: 30 }}>
                    <TermsOfServiceConsentCheckBox
                        isChecked={consentedToTermsOfService}
                        setIsChecked={setConsentedToTermsOfService}
                    />
                    <View style={{ height: 18 }} />
                    <NewsletterConsentCheckBox isChecked={consentedToNewsletter} setIsChecked={setConsentedToNewsletter} />
                </View>
                {error ? <SignUpErrorMessage {...{ error }} /> : null}
                <Button
                    {...{ onPress, isLoading }}
                    style={{ marginTop: 30 }}
                    textStyle={{ marginLeft: 4, fontSize: 14 }}
                    height={40}
                    icon={<Image source={mailIcon} style={{ width: 20, height: 20, resizeMode: 'contain' }} />}>
                    {texts.withEmailButton}
                </Button>
            </View>
        </View>
    );
}

function Onboarding({ signUpState }: { signUpState: SignUpState }) {
    const texts = getLocalizedTexts().landing.header;
    const [firstName, setFirstName] = React.useState<string>('');
    const [age, setAge] = React.useState<string>('');
    const [gender, setGender] = React.useState<Gender | undefined>();
    const [isReferrerCodeFieldVisible, setIsReferrerCodeFieldVisible] = React.useState(false);
    const [referrerCode, setReferrerCode] = React.useState<string>('');
    const previousReferrerCode = usePrevious(referrerCode);
    const [onPressUpdateUserInfo, isLoading, error, clearError] = useUpdateUserInfo(signUpState);
    const onPress = () => onPressUpdateUserInfo({ firstName, age, gender, referrerCode: referrerCode.toLocaleLowerCase() });
    React.useEffect(() => {
        if (referrerCode.length !== previousReferrerCode?.length) clearError();
    }, [referrerCode, previousReferrerCode, clearError]);
    const onboardingTexts = texts.onboarding;
    return (
        <View style={styles.containerEmailSignUpContent}>
            <View style={{ padding: 25 }}>
                <FirstNameInput
                    firstNamePlaceholder={onboardingTexts.firstNamePlaceholder}
                    {...{ firstName, setFirstName, isLoading }}
                />
                <AgeInput
                    {...{ age, agePlaceholder: onboardingTexts.agePlaceholder, setAge, isLoading }}
                    style={{ marginTop: 8 }}
                />
                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 15, marginBottom: 10 }}>
                    <View style={{ marginRight: 16 }}>
                        <Text style={styles.textOnboardingItem}>{onboardingTexts.gender.text}</Text>
                        <Text style={styles.textOnboardingItemOptional}>{onboardingTexts.gender.optional}</Text>
                    </View>
                    <GenderButtons
                        {...{
                            gender,
                            setGender,
                            isLoading,
                            containerImageWidth: 44,
                            imageWidth: 20,
                            descriptionTextSize: 9,
                        }}
                    />
                </View>
                {isReferrerCodeFieldVisible ? (
                    <ReferrerCodeInput
                        referrerCodePlaceholder={onboardingTexts.referrerCode.placeholder}
                        {...{ referrerCode, setReferrerCode, isLoading }}
                    />
                ) : (
                    <HoverableTouchableOpacity
                        onPress={() => setIsReferrerCodeFieldVisible(true)}
                        activeOpacity={0.8}
                        hoveredStyle={{ opacity: 0.8 }}>
                        <Text style={[styles.textOnboardingItem, { textDecorationLine: 'underline' }]}>
                            {onboardingTexts.referrerCode.link}
                        </Text>
                    </HoverableTouchableOpacity>
                )}
                {error ? <OnboardingErrorMessage {...{ error }} /> : null}
                <Button
                    {...{ onPress, isLoading }}
                    disabled={!isFirstNameValid(firstName) || !isAgeValid(age)}
                    textStyle={{ fontSize: 14 }}
                    height={40}
                    style={{ marginTop: 30 }}>
                    {onboardingTexts.submitButton}
                </Button>
            </View>
        </View>
    );
}

enum OnboardingError {
    ReferrerCodeNotValid = 'ReferrerCodeNotValid',
    Other = 'Other',
}

interface UpdateUserInfoParameters {
    firstName: string;
    age: string;
    gender: Gender | undefined;
    referrerCode: string;
}

function useUpdateUserInfo(
    signUpState: SignUpState
): [(parameters: UpdateUserInfoParameters) => void, boolean, OnboardingError | undefined, () => void] {
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<OnboardingError | undefined>();
    const clearError = () => setError(undefined);
    const apolloClient = useApolloClient();
    const updateUserInfo = React.useCallback(
        async ({ firstName, age, gender = Gender.Other, referrerCode }: UpdateUserInfoParameters) => {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const user = await queryUser(apolloClient);
            if (cognitoUser && user) {
                await Auth.updateUserAttributes(cognitoUser, {
                    [CognitoUserAttributeName.givenName]: firstName,
                    [CognitoUserAttributeName.age]: age,
                    [CognitoUserAttributeName.gender]: gender,
                });
                await updateUser(apolloClient, {
                    firstName,
                    age: Number(age),
                    gender,
                    referrerCode: referrerCode.length > 0 && !user.referrerCode ? referrerCode : undefined,
                });
                logAmplitudeEvent(
                    { name: 'Registration - Validated User Information' },
                    {
                        firstName,
                        age,
                        gender,
                        referrerCode: referrerCode.length > 0 ? referrerCode : undefined,
                        registrationDate: user.registeredAt
                            ? moment.unix(user.registeredAt).format('YYYY-MM-DD')
                            : undefined,
                        registrationWeek: user.registeredAt
                            ? moment.unix(user.registeredAt).format('YYYY-[W]ww')
                            : undefined,
                        registrationMonth: user.registeredAt ? moment.unix(user.registeredAt).format('YYYY-MM') : undefined,
                    }
                );
                logUserEventUtil(apolloClient, { type: 'validatedUserInfo' });
                history.push('/onboarding/end');
                window.location.reload();
            }
        },
        [apolloClient, history]
    );
    const [updateUserInfoParameters, setUpdateUserInfoParameters] = React.useState<
        { firstName: string; age: string; gender: Gender | undefined; referrerCode: string } | undefined
    >(undefined);
    const onPressUpdateUserInfo = (parameters: UpdateUserInfoParameters) => {
        if (!isReferrerCodeValid(parameters.referrerCode)) setError(OnboardingError.ReferrerCodeNotValid);
        else if (!isLoading) {
            setIsLoading(true);
            setUpdateUserInfoParameters(parameters);
        }
    };
    React.useEffect(() => {
        // We have to wait for the user account to be fully created before triggering the updateUserInfo action
        if (signUpState === SignUpState.completed && !!updateUserInfoParameters) updateUserInfo(updateUserInfoParameters);
    }, [signUpState, updateUserInfoParameters, updateUserInfo]);
    return [onPressUpdateUserInfo, isLoading, error, clearError];
}

const OnboardingErrorMessage = ({ error }: { error: OnboardingError }) => {
    const texts = getLocalizedTexts().landing.header.onboarding;
    const onboardingErrorTexts = texts.error;
    let message: string = '';
    if (error === OnboardingError.ReferrerCodeNotValid) message = onboardingErrorTexts.referrerCodeNotValid;
    else message = onboardingErrorTexts.default;
    return (
        <View style={styles.containerOnboardingErrorMessage}>
            <Text style={styles.textOnboardingErrorMessage}>{message}</Text>
        </View>
    );
};

function HowItWorks() {
    const texts = getLocalizedTexts().landing;
    const howItWorksTexts = texts.howItWorks;
    return (
        <View style={styles.containerHowItWorks}>
            <Text style={styles.textHowItWorksTitle}>{howItWorksTexts.title}</Text>
            <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', paddingTop: 40 }}>
                <HowItWorksItem
                    texts={howItWorksTexts.signUp}
                    imageSource={signUpIllustration}
                    imageStyle={{ width: 110, height: 134 }}
                />
                <HowItWorksItem
                    texts={howItWorksTexts.useOffer}
                    imageSource={shopOnDesktopIllustration}
                    imageStyle={{ width: 110, height: 134 }}
                />
                <HowItWorksItem
                    texts={howItWorksTexts.getRewarded}
                    imageSource={bankIllustration}
                    imageStyle={{ width: 136, height: 136 }}
                />
            </View>
        </View>
    );
}

function HowItWorksItem({
    texts,
    imageSource,
    imageStyle = {},
}: {
    texts: { title: string; subtitle: string };
    imageSource: ImageSourcePropType;
    imageStyle: ImageStyle;
}) {
    return (
        <View style={styles.containerHowItWorksItem}>
            <Image source={imageSource} style={imageStyle} />
            <Text style={styles.textHowItWorksItemTitle}>{texts.title}</Text>
            <Text style={styles.textHowItWorksItemSubtitle}>{texts.subtitle}</Text>
        </View>
    );
}

function SignUpButton({ onPress }: { onPress: () => any }) {
    const texts = getLocalizedTexts().landing;
    return (
        <View style={styles.containerSignUpButton}>
            <Button onPress={onPress} textStyle={{ fontSize: 16 }} height={40} style={{ width: 325 }}>
                {texts.signUpButton}
            </Button>
        </View>
    );
}

function Testimonials() {
    const texts = getLocalizedTexts().landing.testimonials;
    return (
        <View style={styles.containerTestimonials}>
            <HighlightedText style={styles.textTestimonialsTitle}>{texts.title}</HighlightedText>
            <Image source={getTestimonialsIllustration()} style={{ width: 1120, height: 346 }}></Image>
        </View>
    );
}

function getTestimonialsIllustration(): { uri: string } {
    switch (userRegionVar()) {
        case Region.GB:
        case Region.US:
            return { uri: '/assets/images/illustrations/en-testimonials.png' };
        case Region.FR:
        default:
            return { uri: '/assets/images/illustrations/fr-testimonials.png' };
    }
}

function GradientBackground() {
    return (
        <Svg style={styles.containerGradientBackground}>
            <Defs>
                <LinearGradient id="grad" x1="0" y1="0" x2="0" y2="1">
                    <Stop offset="0" stopColor={color.white} stopOpacity="1" />
                    <Stop offset="0.5" stopColor={color.linkWaterLight} stopOpacity="1" />
                    <Stop offset="1" stopColor={color.white} stopOpacity="1" />
                </LinearGradient>
            </Defs>
            <Rect x="0" y="0" width="100%" height="100%" fill="url(#grad)" />
        </Svg>
    );
}

function ValueProposition() {
    const texts = getLocalizedTexts().landing;
    const valuePropositionTexts = texts.valueProposition;
    return (
        <View style={{ marginTop: 60 }}>
            <View style={styles.containerValueProposition}>
                <HighlightedText style={styles.textValuePropositionTitle}>{valuePropositionTexts.title}</HighlightedText>
                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', paddingTop: 60 }}>
                    <ValuePropositionItem texts={valuePropositionTexts.shopping} imageSource={cartIcon} />
                    <ValuePropositionItem texts={valuePropositionTexts.customerService} imageSource={helpIcon} />
                    <ValuePropositionItem texts={valuePropositionTexts.rewards} imageSource={giftIcon} />
                </View>
            </View>
            <GradientBackgroundValueProposition />
        </View>
    );
}

function ValuePropositionItem({
    texts,
    imageSource,
}: {
    texts: { title: string; subtitle: string };
    imageSource: ImageSourcePropType;
}) {
    return (
        <View style={styles.containerValuePropositionItem}>
            <Image source={imageSource} style={styles.imageValuePropositionItem}></Image>
            <Text style={styles.textValuePropositionItemTitle}>{texts.title}</Text>
            <Text style={styles.textValuePropositionItemSubtitle}>{texts.subtitle}</Text>
        </View>
    );
}

function GradientBackgroundValueProposition() {
    return (
        <Svg style={styles.containerGradientBackground}>
            <Defs>
                <LinearGradient id="grad-value-proposition" x1="0" y1="0" x2="0" y2="1">
                    <Stop offset="0" stopColor={color.whiteIce} stopOpacity="1" />
                    <Stop offset="1" stopColor={color.whiteIce} stopOpacity="0" />
                </LinearGradient>
            </Defs>
            <Rect x="0" y="0" width="100%" height="100%" fill="url(#grad-value-proposition)" />
        </Svg>
    );
}

function About() {
    const texts = getLocalizedTexts().landing.about;
    return (
        <View style={styles.containerAbout}>
            <HighlightedText style={styles.textAboutTitle}>{texts.title}</HighlightedText>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 70 }}>
                <Image source={teamPicture} style={{ width: 415, height: 262 }}></Image>
                <Text style={styles.textAbout}>{texts.text}</Text>
            </View>
        </View>
    );
}

function Footer({ scrollToTop }: { scrollToTop: () => void }) {
    const texts = getLocalizedTexts().landing.footer;
    return (
        <View style={styles.containerFooter}>
            <View style={styles.containerFooterLogos}>
                <HoverableTouchableOpacity onPress={scrollToTop} activeOpacity={0.8} hoveredStyle={{ opacity: 0.8 }}>
                    <Image source={jokoLogo} style={styles.imageJokoLogoFooter} />
                </HoverableTouchableOpacity>
                <View style={{ flexDirection: 'row' }}>
                    <ReviewItem
                        url={PLAY_STORE_URL}
                        imageSource={playStoreRatingLogo}
                        imageStyle={{ width: 129, height: 75 }}
                    />
                    <ReviewItem
                        url={APP_STORE_URL}
                        imageSource={appleStoreRatingLogo}
                        imageStyle={{ width: 129, height: 76 }}
                    />
                    <ReviewItem
                        url={TRUSTPILOT_URL}
                        imageSource={trustpilotRatingLogo}
                        imageStyle={{ width: 136, height: 74 }}
                    />
                </View>
            </View>
            <View style={styles.containerFooterLinks}>
                <CareFooterLink />
                <Text style={styles.textFooterLinkSeparator}> · </Text>
                <FooterLink
                    url={getMarketingWebsiteUrl({ path: MarketingWebsitePath.termOfService })}
                    linkText={texts.termsOfService}
                />
                <Text style={styles.textFooterLinkSeparator}> · </Text>
                <FooterLink
                    url={getMarketingWebsiteUrl({ path: MarketingWebsitePath.privacyPolicy })}
                    linkText={texts.privacyPolicy}
                />
            </View>
        </View>
    );
}

function ReviewItem({
    url,
    imageSource,
    imageStyle = {},
}: {
    url: string;
    imageSource: ImageSourcePropType;
    imageStyle: ImageStyle;
}) {
    return (
        <TouchableOpacity onPress={() => window.open(url)} style={styles.containerReview}>
            <Image source={imageSource} style={imageStyle} />
        </TouchableOpacity>
    );
}

function FooterLink({ url, linkText }: { url: string; linkText: string }) {
    return (
        <TouchableOpacity onPress={() => window.open(url)}>
            <Text style={styles.textFooterLink}>{linkText}</Text>
        </TouchableOpacity>
    );
}

function CareFooterLink() {
    const linkText = getLocalizedTexts().landing.footer.contact;
    return (
        <TouchableOpacity
            onPress={async () => {
                const shouldUseIntercom = await getPublicSetting<boolean>(SettingKey.shouldUseIntercomWhenLoggedOutOnWebApp);
                const contactUrl = shouldUseIntercom ? getHelpCenterUrl(true) : getHelpCenterUrl(false) + '/requests/new';
                window.open(contactUrl);
            }}>
            <Text style={styles.textFooterLink}>{linkText}</Text>
        </TouchableOpacity>
    );
}

const HEADER_LOGO_HEIGHT = 70;
const HEADER_LOGO_WIDTH = HEADER_LOGO_HEIGHT * 3;

const styles = StyleSheet.create({
    containerHeader: {
        margin: 20,
        borderRadius: 40,
    },
    containerHeaderContent: {
        flex: 1,
        flexDirection: 'row',
        paddingTop: 10,
    },
    containerPresentation: {
        flex: 2,
        paddingTop: 20,
        paddingRight: 20,
    },
    containerMerchantsLogosRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginVertical: 10,
    },
    containerMerchantLogo: {
        width: 170,
        height: 71,
        borderRadius: 15,
        backgroundColor: color.white,
    },
    containerEmailSignUpContent: {
        backgroundColor: color.white,
        borderRadius: 15,
    },
    containerGradientBackground: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'transparent',
        top: 0,
        zIndex: -1,
    },
    containerOnboardingErrorMessage: {
        backgroundColor: color.fairPink,
        paddingVertical: 8,
        paddingHorizontal: 25,
        borderRadius: 10,
        marginTop: 16,
    },
    containerHowItWorks: {
        paddingTop: 75,
        paddingHorizontal: 100,
    },
    containerHowItWorksItem: {
        alignItems: 'center',
        maxWidth: 315,
    },
    containerSignUpButton: {
        marginTop: 60,
        alignItems: 'center',
    },
    containerTestimonials: {
        paddingTop: 120,
        paddingHorizontal: 100,
        alignItems: 'center',
    },
    containerValueProposition: {
        paddingTop: 75,
        paddingHorizontal: 100,
    },
    containerValuePropositionItem: {
        alignItems: 'center',
        maxWidth: 315,
    },
    containerAbout: {
        paddingTop: 130,
        paddingHorizontal: 40,
        alignItems: 'center',
    },
    containerFooter: {
        flexDirection: 'column',
    },
    containerFooterLogos: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 140,
        marginBottom: 110,
        paddingHorizontal: 150,
    },
    containerFooterLinks: {
        flexDirection: 'row',
        alignSelf: 'center',
        marginBottom: 50,
    },
    containerReview: {
        marginLeft: 60,
    },
    textAlreadySignedUp: {
        color: color.white,
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
    },
    textSignIn: {
        color: color.white,
        fontFamily: font.ambitBlack,
        fontSize: 16,
    },
    textPresentationTitle: {
        color: color.black,
        fontFamily: font.ambitBlack,
        fontSize: 50,
        marginBottom: 20,
    },
    textPresentationSubtitle: {
        color: color.white,
        fontFamily: font.ambitBlack,
        fontSize: 30,
        marginBottom: 40,
    },
    textPresentationMoreMerchants: {
        color: color.white,
        fontFamily: font.ambitBold,
        fontSize: 18,
        textAlign: 'center',
        marginTop: 10,
    },
    textSignUpTitle: {
        color: color.white,
        fontFamily: font.ambitBlack,
        fontSize: 26,
        textAlign: 'center',
    },
    textSignUpSubtitle: {
        color: color.black,
        fontFamily: font.ambitBlack,
        fontSize: 18,
        textAlign: 'center',
        marginTop: 5,
        marginBottom: 7,
    },
    textSocialAndEmailSeparator: {
        color: color.white,
        fontFamily: font.ambitBlack,
        fontSize: 20,
        textAlign: 'center',
        paddingVertical: 15,
    },
    textOnboardingItem: {
        fontFamily: font.ambitSemiBold,
        color: color.black,
        fontSize: 16,
    },
    textOnboardingItemOptional: {
        fontFamily: font.ambitSemiBold,
        color: color.silverChalice,
        fontSize: 14,
        marginTop: 5,
    },
    textOnboardingErrorMessage: {
        color: color.flamingo,
        fontFamily: font.ambitBold,
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
    textHowItWorksTitle: {
        color: color.black,
        fontFamily: font.ambitBlack,
        fontSize: 42,
        textAlign: 'center',
    },
    textHowItWorksItemTitle: {
        color: color.black,
        fontFamily: font.ambitBlack,
        fontSize: 28,
        textAlign: 'center',
        marginTop: 20,
    },
    textHowItWorksItemSubtitle: {
        paddingTop: 15,
        color: color.silverChalice,
        fontFamily: font.ambitBold,
        fontSize: 22,
        textAlign: 'center',
    },
    textTestimonialsTitle: {
        fontFamily: font.ambitBold,
        fontSize: 32,
        color: color.black,
        marginBottom: 30,
        textAlign: 'center',
    },
    textValuePropositionTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 42,
        color: color.black,
        textAlign: 'center',
    },
    textValuePropositionItemTitle: {
        color: color.black,
        fontFamily: font.ambitBlack,
        fontSize: 28,
        textAlign: 'center',
    },
    textValuePropositionItemSubtitle: {
        paddingTop: 15,
        color: color.manatee,
        fontFamily: font.ambitSemiBold,
        fontSize: 22,
        textAlign: 'center',
    },
    textAboutTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 42,
        color: color.black,
    },
    textAbout: {
        fontFamily: font.ambitSemiBold,
        fontSize: 22,
        color: color.black,
        maxWidth: 665,
        marginLeft: 40,
    },
    textFooterLink: {
        fontFamily: font.ambitSemiBold,
        fontSize: 18,
        color: color.silverChalice,
        textDecorationLine: 'underline',
    },
    textFooterLinkSeparator: {
        fontFamily: font.ambitSemiBold,
        fontSize: 18,
        color: color.silverChalice,
    },
    imageJokoLogoHeader: {
        height: HEADER_LOGO_HEIGHT,
        width: HEADER_LOGO_WIDTH,
    },
    imageMerchantLogo: {
        width: 171,
        height: 70,
        resize: 'contain',
    },
    imageSocialSignUp: {
        width: 20,
        height: 20,
        resizeMode: 'contain',
    },
    imageLightningLeft: {
        position: 'absolute',
        top: 320,
        left: 0,
        width: 120,
        height: 426,
    },
    imageLightningRight: {
        position: 'absolute',
        top: 75,
        right: 0,
        width: 130,
        height: 426,
    },
    imageValuePropositionItem: {
        width: 58,
        height: 58,
        marginBottom: 25,
    },
    imageSignUpTitle: {
        height: 60,
        width: 60,
        alignSelf: 'flex-end',
        right: 20,
        top: 30,
    },
    imageJokoLogoFooter: {
        height: 60,
        width: 180,
    },
});
