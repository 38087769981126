import gql from 'graphql-tag';

export const intercomUserHashQuery = gql`
    query IntercomUserHashQuery($platform: String!) {
        user {
            intercomUserHash(platform: $platform)
        }
    }
`;

export type IntercomUserHashQueryResponse = {
    __typename: 'Query';
    user: {
        __typename: 'User';
        intercomUserHash: string;
    };
};

export type IntercomUserHashQueryVariables = {
    platform: string;
};
