import gql from 'graphql-tag';

import { merchantFragment, Merchant } from '../fragments/merchants';

export const merchantQuery = gql`
    query MerchantQuery($merchantId: String!) {
        merchant(merchantId: $merchantId) {
            ...Merchant
        }
    }
    ${merchantFragment}
`;

export type MerchantQueryResponse = {
    __typename: 'Query';
    merchant: Merchant | null;
};

export type MerchantQueryVariables = {
    merchantId: string | undefined;
};
