import gql from 'graphql-tag';

import { ReferralPointUserInfo, ReferralPointType, ReferralPointDetails, ReferralPointOfferPointDetails } from './referral';
import { UserRewardBankTransferDetails, UserRewardDonationDetailsDetails } from './rewards';

export const historyRecordFragment = gql`
    fragment HistoryRecord on HistoryRecord {
        recordId
        timestamp
        type
        points
        pending
        offer {
            offerId
            screen {
                title
                logoURL
                bannerURL
            }
        }
        reward {
            rewardId
            type
            screen {
                title
                imageURL
            }
            bankTransferProperties {
                terms
            }
        }
        userReward {
            createdAt
            rewardId
            points
            bankTransferDetails {
                amount
                currency
            }
            donationDetails {
                isAutoDonation
            }
        }
        bonus {
            bonusId
            card {
                title
                imageURL
            }
        }
        transaction {
            transactionId
            value
            date
            offerPointsCalculationRule {
                points
                fixedPoints
                boosts {
                    boostType
                    ratio
                    startDate
                    endDate
                }
            }
        }
        affiliateTransaction {
            affiliateTransactionId
            transactionAmount
            purchasedAt
        }
        referralPoints {
            type
            refereeUserId
            refereeUserInfo {
                firstName
                gender
            }
            referrerUserId
            referrerUserInfo {
                firstName
                gender
            }
            pointDetails {
                collectedBonusPoints
                bonusIds
                collectedReferralPoints
                refereeUserIds
                collectedOfferPoints
                transactionIds
                affiliateTransactionIds
            }
            offerPointDetails {
                collectedOfferPoints
                transactionIds
                affiliateTransactionIds
            }
        }
        isRetroactive
        isUncollected
    }
`;

interface MerchantOffer {
    __typename: 'MerchantOffer';
    offerId: string;
    screen: {
        __typename: 'OfferScreen';
        title: string;
        logoURL: string | null;
        bannerURL: string;
    };
}

interface Bonus {
    __typename: 'Bonus';
    card: {
        __typename: 'BonusCard';
        title: string;
        imageURL: string;
    };
}

interface Reward {
    __typename: 'Reward';
    type: string;
    screen: {
        __typename: 'RewardScreen';
        title: string;
        imageURL: string;
    };
    bankTransferProperties: {
        __typename: 'RewardBankTransferProperties';
        terms: string | null;
    } | null;
}

interface UserReward {
    __typename: 'UserReward';
    createdAt: number;
    rewardId: string;
    points: number;
    bankTransferDetails: UserRewardBankTransferDetails | null;
    donationDetails?: UserRewardDonationDetailsDetails | null;
}

export enum BoostType {
    roulette = 'roulette',
}

interface UserTransaction {
    __typename: 'UserTransaction';
    transactionId: string;
    value: number;
    date: string;
    offerPointsCalculationRule?: {
        points?: number;
        fixedPoints?: number;
        boosts?: Array<{
            boostType: BoostType;
            ratio: number;
            startDate: string;
            endDate: string;
        }>;
    };
}

interface AffiliateTransaction {
    affiliateTransactionId: string;
    transactionAmount: number;
    purchasedAt?: number;
}

interface ReferralPoints {
    type: ReferralPointType;
    refereeUserId: string;
    refereeUserInfo: ReferralPointUserInfo;
    referrerUserId: string;
    referrerUserInfo: ReferralPointUserInfo;
    pointDetails: ReferralPointDetails | null;
    offerPointDetails: ReferralPointOfferPointDetails | null;
}

export enum HistoryRecordType {
    earn = 'earn',
    redeem = 'redeem',
}

export interface HistoryRecord {
    __typename: 'HistoryRecord';
    recordId: string;
    timestamp: number;
    type: HistoryRecordType;
    points: number;
    pending: boolean;
    offer: MerchantOffer | null;
    reward: Reward | null;
    userReward: UserReward | null;
    bonus: Bonus | null;
    transaction: UserTransaction | null;
    affiliateTransaction: AffiliateTransaction | null;
    referralPoints: ReferralPoints | null;
    isRetroactive: boolean;
    isUncollected: boolean;
}

export const dataIdFromHistoryRecord = ({ recordId }: HistoryRecord) => {
    return `HistoryRecord:${recordId}`;
};

export interface HistoryRecordWitKey extends HistoryRecord {
    key: string;
}
