import { RestApiUrl } from '../config';
import { DonationRedeemState } from '../../pages/public/IOSDonationPage';

export async function redeemIOSDonationReward(
    userId: string,
    rewardId: string,
    token: string,
    rewardValue: number,
    shouldUseDevStack: boolean
): Promise<DonationRedeemState> {
    console.log(`API call: redeeming an iOS donation for user ${userId} and reward ${rewardId}...`);
    const headers: Headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    const restApiUrl = shouldUseDevStack ? RestApiUrl.dev : RestApiUrl.prod;
    const url =
        `${restApiUrl}user/reward/redeem-ios-donation` +
        `?userId=${encodeURIComponent(userId)}&rewardId=${encodeURIComponent(rewardId)}&token=${encodeURIComponent(token)}`;
    const body = { points: rewardValue * 1000 };
    try {
        const response = await fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(body) });
        console.log(`Response ${response.status} for API call 'redeem iOS donation'`);
        if (response.status === 200) return DonationRedeemState.success;
        else return DonationRedeemState.error;
    } catch (error) {
        console.log(`Error when doing the API call 'redeem iOS donation': `, error);
        return DonationRedeemState.error;
    }
}
