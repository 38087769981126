import * as React from 'react';
import { ImageURISource } from 'react-native';
import { useLocation } from 'react-router';
import qs from 'qs';
import * as moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-au';

import { getRestApiUrl } from '../../api/config';
import { ApolloClient } from '../../api/graphql/client';
import { userQuery, UserQueryResponse } from '../../api/graphql/queries/user';
import { userRegionVar } from '../../api/graphql/reactiveVariables';
import { logAmplitudeEventWithoutAuthentication } from '../../lib/events/amplitudeEvents';
import { checkIsSignedIn } from '../common/cognito';

export enum Region {
    FR = 'FR',
    GB = 'GB',
    US = 'US',
}

export const DEFAULT_REGION = Region.FR;
export const AUTHORIZED_REGIONS = [Region.FR, Region.US];

export enum Currency {
    EUR = 'EUR',
    GBP = 'GBP',
    USD = 'USD',
}

export enum RegionDetectionMethod {
    urlParameter = 'urlParameter',
    userRegion = 'userRegion',
    ipAddress = 'ipAddress',
    browserLanguage = 'browserLanguage',
}

/** This hook attempts to detect the region synchronously with the URL parameters, and if necessary uses asynchronous detection which requires to display a loading indicator  */
export function useRegionDetection(apolloClient: ApolloClient | undefined): boolean {
    const regionFromUrlParameters = useRegionFromUrlParameters();
    const [isRegionLoading, setIsRegionLoading] = React.useState<boolean>(!regionFromUrlParameters && !userRegionVar());
    React.useEffect(() => {
        if (!apolloClient || !!regionFromUrlParameters || !!userRegionVar()) return;
        const launchAsynchronousRegionDetection = async () => {
            await detectRegion(apolloClient);
            setIsRegionLoading(false);
        };
        launchAsynchronousRegionDetection();
    }, [apolloClient, regionFromUrlParameters]);
    return isRegionLoading;
}

function useRegionFromUrlParameters() {
    const { search } = useLocation();
    const regionFromUrlParameters = qs.parse(search, { ignoreQueryPrefix: true })?.region as Region | undefined;
    if (regionFromUrlParameters && checkIsAuthorizedRegion(regionFromUrlParameters)) {
        setRegion(regionFromUrlParameters);
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Navigation - Region Detected',
                properties: { detectionMethod: RegionDetectionMethod.urlParameter, region: regionFromUrlParameters },
            },
            {}
        );
    }
    return regionFromUrlParameters;
}

function checkIsAuthorizedRegion(region: Region | undefined): region is Region {
    return !!region && AUTHORIZED_REGIONS.includes(region);
}

export interface RegionDetectionResult {
    region: Region | undefined;
    city?: string;
    detectionMethod: RegionDetectionMethod;
}

/**
 * The following map is useful because regions are not necessarily in one-to-one correspondence with countries
 */
const countryCodeToRegionMap = {
    FR: Region.FR,
    GB: Region.GB,
    US: Region.US,
};

/**
 * This function tries to detect the region by using methods in the following order of priority:
 * 1. If the user is authenticated, it uses the `region` field from the **users** table
 * 2. It fetches the region associated to the user's IP address
 * 3. Otherwise, it defaults to the browser language
 */
async function detectRegion(apolloClient: ApolloClient | undefined): Promise<Region> {
    let regionDetectionResult: RegionDetectionResult = {
        region: apolloClient && checkIsSignedIn() ? await getUserRegion(apolloClient) : undefined,
        detectionMethod: RegionDetectionMethod.userRegion,
    };
    if (!regionDetectionResult.region || !checkIsAuthorizedRegion(regionDetectionResult.region))
        regionDetectionResult = await getRegionFromIpAddress();
    if (!regionDetectionResult.region || !checkIsAuthorizedRegion(regionDetectionResult.region))
        regionDetectionResult = {
            region: getRegionFromBrowserLanguage(),
            detectionMethod: RegionDetectionMethod.browserLanguage,
        };
    setRegion(regionDetectionResult.region ?? DEFAULT_REGION);
    logAmplitudeEventWithoutAuthentication({ name: 'Navigation - Region Detected', properties: regionDetectionResult }, {});
    return countryCodeToRegionMap[regionDetectionResult.region as Region];
}

async function getUserRegion(client: ApolloClient): Promise<Region | undefined> {
    const { data, error } = await client.query<UserQueryResponse>({ query: userQuery, fetchPolicy: 'cache-first' });
    if (error?.networkError) return undefined;
    return data?.user.region ?? Region.FR; // Legacy French accounts have an `undefined` `region` field
}

async function getRegionFromIpAddress(): Promise<RegionDetectionResult> {
    try {
        const { countryCode, city } = await fetchRegionFromIpAddressWithTimeout();
        return { region: countryCode, city, detectionMethod: RegionDetectionMethod.ipAddress };
    } catch (error) {
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Navigation - Region Detection From Ip Failed',
                properties: { reason: error instanceof RegionFromIpTimeoutExceeded ? 'timeout' : 'unknown' },
            },
            {}
        );
    }
    return { region: undefined, detectionMethod: RegionDetectionMethod.ipAddress };
}

const REGION_DETECTION_FROM_IP_TIMEOUT_IN_MS = 1500;

function fetchRegionFromIpAddressWithTimeout(): Promise<{ countryCode: Region; city: string }> {
    return new Promise((resolve, reject) => {
        const promise = fetchRegionFromIpAddress();
        const timeoutId = setTimeout(
            () => reject(new RegionFromIpTimeoutExceeded()),
            REGION_DETECTION_FROM_IP_TIMEOUT_IN_MS
        );
        promise
            .then((result) => {
                clearTimeout(timeoutId);
                resolve(result);
            })
            .catch((error) => {
                clearTimeout(timeoutId);
                reject(error);
            });
    });
}

class RegionFromIpTimeoutExceeded extends Error {
    __proto__: RegionFromIpTimeoutExceeded;

    constructor() {
        super('');
        this.__proto__ = RegionFromIpTimeoutExceeded.prototype;
    }
}

async function fetchRegionFromIpAddress(): Promise<{ countryCode: Region; city: string }> {
    console.log(`API call: getting region from IP...`);
    const headers: Headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    const url = `${getRestApiUrl()}region`;
    const response = await fetch(url, { method: 'GET', headers });
    console.log(`Response ${response.status} for API call 'get region from IP'`);
    if (response.status === 200) {
        const { countryCode, city } = await response.json();
        return { countryCode, city };
    }
    throw new Error(`${response.status}`);
}

function getRegionFromBrowserLanguage(): Region {
    const browserRegion = convertBrowserLanguageToRegion(window.navigator.language);
    if (checkIsAuthorizedRegion(browserRegion)) return browserRegion;
    return DEFAULT_REGION;
}

function convertBrowserLanguageToRegion(browserLanguage: string): Region {
    const formattedBrowserLanguage = browserLanguage.toLocaleLowerCase();
    if (['en_gb', 'en-gb'].includes(formattedBrowserLanguage)) return Region.GB;
    else if (['en_us', 'en-us', 'en'].includes(formattedBrowserLanguage)) return Region.US;
    else if (['fr_fr', 'fr-fr', 'fr'].includes(formattedBrowserLanguage)) return Region.FR;
    else return DEFAULT_REGION;
}

export enum MarketingWebsitePath {
    termOfService = 'tos',
    privacyPolicy = 'privacy-policy',
}

export function getMarketingWebsiteUrl({ path }: { path: MarketingWebsitePath }): string {
    const region = getRegion();
    const marketingWebsiteUrl = 'https://www.joko.com';
    switch (region) {
        case Region.GB:
            return `${marketingWebsiteUrl}/en/${path}`;
        case Region.US:
            return `${marketingWebsiteUrl}/en/${path}-us`;
        case Region.FR:
        default:
            return `${marketingWebsiteUrl}/fr/${path}`;
    }
}

export function getHelpCenterUrl(shouldUseIntercom: boolean): string {
    const region = getRegion();
    if (!shouldUseIntercom) {
        switch (region) {
            case Region.US:
                return 'https://joko.zendesk.com/hc/en-us';
            case Region.GB:
                return 'https://joko.zendesk.com/hc/en-gb';
            case Region.FR:
            default:
                return 'https://joko.zendesk.com/hc/fr-fr';
        }
    } else
        switch (region) {
            case Region.US:
            case Region.GB:
                return 'https://support.joko.com/en';
            case Region.FR:
            default:
                return 'https://support.joko.com/fr';
        }
}

export type LocalizedAsset = {
    [key in Region]: ImageURISource;
};

export function localizeAsset(asset: LocalizedAsset | ImageURISource): ImageURISource {
    const region = getRegion();
    if (checkIsLocalizedAsset(asset, region)) return asset[region];
    return asset;
}

export function getRegion(): Region {
    return userRegionVar() ?? DEFAULT_REGION;
}

function setRegion(region: Region): void {
    if (region === Region.FR) moment.locale('fr');
    // Use `en-au` instead of `en` or `en-us` due to an issue with moment, see https://gitlab.com/wylr/user-mobile-app/-/merge_requests/1206#note_1291898282
    else if (region === Region.US) moment.locale('en-au');
    userRegionVar(region);
}

export function checkIsLocalizedAsset(asset: LocalizedAsset | ImageURISource, region: Region): asset is LocalizedAsset {
    return (
        typeof asset !== 'undefined' &&
        typeof (asset as LocalizedAsset)[region] !== 'undefined' &&
        typeof (asset as LocalizedAsset)[region].uri !== 'undefined'
    );
}
