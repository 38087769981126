import gql from 'graphql-tag';
import moment from 'moment';

import { ApolloClient } from '../client';
import { HistoryRecord } from '../fragments/history';
import { userHistoryAndPointsQuery, UserHistoryAndPointsQueryResponse } from '../queries/pointCollection';
import { lastPointsCollectedAtVar } from '../reactiveVariables';

export const collectPointsMutation = gql`
    mutation CollectPoints {
        collectPoints {
            points
        }
    }
`;

export interface CollectPointsResponse {
    __typename: 'Mutation';
    collectPoints: {
        __typename: 'CollectPointsOutput';
        points: number;
    };
}

export function dataIdFromCollectPointsOutput(object: {}): string {
    return `CollectPointsOutput:default`;
}

export async function collectPoints({ client }: { client: ApolloClient }) {
    lastPointsCollectedAtVar(moment().unix());
    console.log('AppSync: collecting offer points...');
    client.mutate({ mutation: collectPointsMutation }).then(() => console.log('AppSync: offer points collected'));
    try {
        console.log('Apollo: updating local data...');
        const dataInCache = client.readQuery<UserHistoryAndPointsQueryResponse>({ query: userHistoryAndPointsQuery });
        if (dataInCache?.user) {
            const { history, points } = dataInCache.user;
            let pointsToCollect: number = 0;
            const updatedHistory: HistoryRecord[] = [];
            for (const record of history) {
                if (record.isUncollected) pointsToCollect += record.points;
                updatedHistory.push({ ...record, isUncollected: false });
            }
            client.writeQuery<UserHistoryAndPointsQueryResponse>({
                query: userHistoryAndPointsQuery,
                data: {
                    __typename: 'Query',
                    user: {
                        __typename: 'User',
                        history: updatedHistory,
                        points: {
                            __typename: 'UserPoints',
                            balance: (points.balance || 0) + pointsToCollect,
                            total: (points.total || 0) + pointsToCollect,
                        },
                        firstPointsCollectedAt: dataInCache?.user.firstPointsCollectedAt || null,
                        firstOfferPointsCollectedAt: dataInCache?.user.firstOfferPointsCollectedAt || null,
                    },
                },
            });
            console.log('Apollo: local data updated');
        }
    } catch (error) {
        console.log('Apollo: error while updating local data');
        console.log(error);
    }
}
