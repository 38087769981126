import fetchRetry from 'fetch-retry';

import { getDeviceId } from '../../lib/common/device';
import { UserEvent, getDeduplicatedTimestampInSeconds } from '../../lib/events/userEvents';

const DEV_STACK_EVENT_LOGGING_URL_BASE = 'https://ttwgkhkd82.execute-api.eu-west-1.amazonaws.com';
const PROD_STACK_EVENT_LOGGING_URL_BASE = 'https://5lcadbj7za.execute-api.eu-west-1.amazonaws.com';

type LogEventInput = {
    type: string;
    active?: boolean;
    payload?: string;
    timestamp?: number;
};

export async function logEventWithoutAuthentication(
    userId: string | undefined,
    event: UserEvent,
    shouldUseDevStack?: boolean
) {
    console.log(`API call: logging ${event.type} event...`);
    const timestamp = getDeduplicatedTimestampInSeconds();
    const payload = { ...(event.payload ?? {}), platform: 'webApp', deviceId: getDeviceId() };
    const stringifiedPayload = JSON.stringify(payload);
    const logUserEventInput = {
        ...event,
        payload: stringifiedPayload,
        active: true,
        timestamp,
    };
    return await logEventThroughRestApi(userId, logUserEventInput, shouldUseDevStack);
}

const fetchWithRetry = fetchRetry(fetch);

async function logEventThroughRestApi(
    userId: string | undefined,
    event: LogEventInput,
    shouldUseDevStack?: boolean
): Promise<{ status: 'success' | 'failure' }> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    try {
        const response = await fetchWithRetry(getEventLoggingUrl({ shouldUseDevStack, shouldUseUserId: !!userId }), {
            method: 'POST',
            headers,
            body: JSON.stringify({ ...(!!userId && { userId }), events: [event] }),
            retryDelay: (attempt) => {
                if (attempt === 1) return 3 * 1000; // 3 seconds
                else return 10 * 1000; // 10 seconds
            },
            retries: 2,
        });
        if (response.ok) return { status: 'success' };
        else {
            console.error('Failed to log user event:', response.status, response.statusText);
            return { status: 'failure' };
        }
    } catch (error) {
        console.error(error);
        return { status: 'failure' };
    }
}

function getEventLoggingUrl({
    shouldUseDevStack,
    shouldUseUserId,
}: {
    shouldUseDevStack?: boolean;
    shouldUseUserId: boolean;
}): string {
    const baseUrl = shouldUseDevStack ? DEV_STACK_EVENT_LOGGING_URL_BASE : PROD_STACK_EVENT_LOGGING_URL_BASE;
    const path = shouldUseUserId ? 'ingest-user-events' : 'ingest-anonymous-events';
    return `${baseUrl}/${path}`;
}
