import * as React from 'react';
import {
    View,
    Text,
    Image,
    StyleSheet,
    ActivityIndicator,
    TouchableOpacity,
    ScrollView,
    LayoutChangeEvent,
} from 'react-native';
import { useParams } from 'react-router';
import { useQuery, useApolloClient } from '@apollo/react-hooks';

import { getLocalizedTexts, formatCurrencyAmount, formatPercentageAmount } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { containerStyles } from '../../style/container';
import { useWindowSize } from '../../style/utils';
import { OfferType } from '../../api/graphql/fragments/offers';
import { EnrichedCondition, EnrichedMerchantOffer, EnrichedUserOffer } from '../../api/graphql/fragments/enrichedOffers';
import {
    enrichedUserOfferQuery,
    EnrichedUserOfferQueryResponse,
    EnrichedUserOfferQueryVariable,
} from '../../api/graphql/queries/enrichedOffers';
import {
    getOfferSingleCashbackRate,
    RateType,
    CashbackRate,
    ComputationType,
    useOnPressFavoriteOfferButton,
    getFilteredEnrichedConditions,
    useMultipleCashbackRates,
} from '../../lib/offers/offers';
import { AffiliateLinkLoadingTriggeringEventType } from '../../lib/offers/affiliateLinkLoading';
import { logUserEventUtil } from '../../lib/events/userEvents';
import StrikeThroughContainer from '../../components/common/StrikeThroughContainer';
import { HoverableTouchableOpacity } from '../../components/common/HoverableComponents';
import ExpirationDate from '../../components/home/offers/ExpirationDate';
import { useOnPressOfferButton } from '../../components/home/offers/OfferButton';
import OfferDetailSection from './offers/OfferDetailsSection';
import { Feature, useFeatureMap } from '../../lib/features/buildFeatureMap';

const currencySymbolGreen = { uri: '/assets/images/visualIdentity/currency-symbol-green-no-circle.svg' };
const currencySymbolPurple = { uri: '/assets/images/visualIdentity/currency-symbol-purple-no-circle.svg' };
const currencySymbolGray = { uri: '/assets/images/visualIdentity/currency-symbol-gray-no-circle.svg' };
const checkMarkIcon = { uri: '/assets/images/icons/check-mark.svg' };
const chevronDownIcon = { uri: '/assets/images/icons/chevron-gray-down.svg' };
const heartIconPink = { uri: '/assets/images/icons/heart-pink.svg' };
const heartShapeIcon = { uri: '/assets/images/icons/heart-shape-black.svg' };
const creditCardIcon = { uri: '/assets/images/icons/credit-card-simple.svg' };
const cartIcon = { uri: '/assets/images/icons/cart-blue.svg' };

function OfferDetailPage() {
    const size = useWindowSize();
    const featureMap = useFeatureMap();
    const shouldUseNewConditions = !!featureMap?.[Feature.useNewOfferConditionStyleInWebApp];
    const shouldMergeCashbackRates = !!featureMap?.[Feature.useCashbackRatesMergingInWebApp];
    const userOffer = useUserOffer({ shouldUseNewConditions });
    const multipleCashbackRates = useMultipleCashbackRates(userOffer?.offer, shouldMergeCashbackRates);
    useLogViewedOfferDetailsEvent(userOffer);
    const mainCashbackRate = React.useMemo(
        () =>
            multipleCashbackRates.length > 0
                ? multipleCashbackRates[0]
                : userOffer
                ? getOfferSingleCashbackRate(userOffer.offer, true)
                : undefined,
        [userOffer, multipleCashbackRates]
    );
    if (multipleCashbackRates.length === 0 && mainCashbackRate) multipleCashbackRates.push(mainCashbackRate);
    const numberOfRates = multipleCashbackRates.length;
    if (!userOffer || !mainCashbackRate)
        return (
            <View
                style={[
                    styles.container,
                    { justifyContent: 'center', alignItems: 'center', paddingVertical: 10, zIndex: 20 },
                ]}>
                <ActivityIndicator color={color.frenchGray} size="large" />
            </View>
        );
    return (
        <View style={[styles.container, { height: Math.min((size.height || 0) * 0.9, 700) }]}>
            <BannerImage {...{ userOffer }} />
            <MainInfo {...{ userOffer, mainCashbackRate }} />
            <ScrollView style={{ paddingBottom: '15px' }}>
                <MultipleRates {...{ multipleCashbackRates, numberOfRates, shouldMergeCashbackRates }} />
                <Description {...{ userOffer }} />
                <ConditionsSection {...{ userOffer, shouldUseNewConditions }} />
            </ScrollView>
        </View>
    );
}

export default OfferDetailPage;

function useUserOffer({ shouldUseNewConditions }: { shouldUseNewConditions: boolean }): EnrichedUserOffer | undefined {
    let { offerId } = useParams<{ offerId: string | undefined }>();
    const queryResponse = useQuery<EnrichedUserOfferQueryResponse, EnrichedUserOfferQueryVariable>(enrichedUserOfferQuery, {
        variables: { offerId: offerId || '', shouldUseNewConditions },
        fetchPolicy: 'cache-and-network',
    });
    const userOffer = React.useMemo(() => queryResponse.data?.user?.enrichedOffer || undefined, [queryResponse]);
    return userOffer;
}

function useLogViewedOfferDetailsEvent(userOffer: EnrichedUserOffer | undefined) {
    const isEventLoggedRef = React.useRef<boolean>(false);
    const apolloClient = useApolloClient();
    React.useEffect(() => {
        if (userOffer && !isEventLoggedRef.current) {
            isEventLoggedRef.current = true;
            logUserEventUtil(apolloClient, {
                type: 'viewedOfferDetails',
                payload: { offerType: userOffer.offer.type, offerId: userOffer.offerId, from: 'webApp' },
            });
        }
    }, [apolloClient, userOffer]);
}

function BannerImage({ userOffer }: { userOffer: EnrichedUserOffer }) {
    return <Image source={{ uri: userOffer.offer.screen?.bannerURL || '' }} style={styles.imageBanner} />;
}

function MainInfo({ userOffer, mainCashbackRate }: { userOffer: EnrichedUserOffer; mainCashbackRate: CashbackRate }) {
    return (
        <View style={styles.containerMainInfo}>
            <Logo {...{ userOffer }} />
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <MainInfoTexts {...{ userOffer, mainCashbackRate }} />
                <FavoriteOfferButton {...{ userOffer }} />
                <ActivateButton {...{ userOffer }} />
            </View>
        </View>
    );
}

function Logo({ userOffer }: { userOffer: EnrichedUserOffer }) {
    return (
        <View style={styles.containerLogo}>
            <Image style={styles.imageLogo} source={{ uri: userOffer.offer.screen?.logoURL }} />
        </View>
    );
}

function MainInfoTexts({ userOffer, mainCashbackRate }: { userOffer: EnrichedUserOffer; mainCashbackRate: CashbackRate }) {
    return (
        <View style={styles.containerMainInfoTexts}>
            <View style={containerStyles.containerRowLeftCenter}>
                <Text style={styles.textOfferTitle}>{userOffer.offer.screen?.title}</Text>
                <View style={styles.containerExpirationDate}>
                    <ExpirationDate offer={userOffer.offer} />
                </View>
            </View>
            <OfferCashback {...{ mainCashbackRate }} />
        </View>
    );
}

function OfferCashback({ mainCashbackRate }: { mainCashbackRate: CashbackRate }) {
    const texts = getLocalizedTexts().home.offers.offerDetails;
    const nonBoostedCashback =
        mainCashbackRate.computationType === ComputationType.variable
            ? formatPercentageAmount(mainCashbackRate.nonBoostedCashback)
            : formatCurrencyAmount(mainCashbackRate.nonBoostedCashback);
    const boostedCashback =
        mainCashbackRate.computationType === ComputationType.variable
            ? formatPercentageAmount(mainCashbackRate.boostedCashback)
            : formatCurrencyAmount(mainCashbackRate.boostedCashback);
    return (
        <View style={styles.containerCashback}>
            {mainCashbackRate.rateType === RateType.multiple ? (
                <Text style={[styles.textCashbackDescription, { marginRight: 3 }]}>{texts.multipleRates.rateUpTo}</Text>
            ) : null}
            {!mainCashbackRate.boostedCashback ? (
                <CashbackAmount cashbackAmount={nonBoostedCashback} />
            ) : (
                <>
                    <NonBoostedCashbackAmount {...{ nonBoostedCashback }} />
                    <BoostedCashbackAmount {...{ boostedCashback }} />
                </>
            )}
            <Text style={styles.textCashbackDescription}>{texts.cashback.cashbackDescription}</Text>
            <Text style={styles.textCashbackDescription}>{mainCashbackRate.valueMinText}</Text>
        </View>
    );
}

function CashbackAmount({ cashbackAmount }: { cashbackAmount: string }) {
    return (
        <>
            <Image source={currencySymbolGreen} style={styles.imageCashbackAmount} />
            <Text style={styles.textCashbackAmount}>{cashbackAmount}</Text>
        </>
    );
}

function NonBoostedCashbackAmount({ nonBoostedCashback }: { nonBoostedCashback: string }) {
    return (
        <View>
            <StrikeThroughContainer
                style={styles.containerNonBoostedCashback}
                lineWidth={2}
                lineColor={color.osloGray}
                adjustLineHeight={1}>
                <View style={styles.containerCashback}>
                    <Image source={currencySymbolGray} style={styles.imageCashbackAmount} />
                    <Text style={styles.textNonBoostedCashbackAmount}>{nonBoostedCashback}</Text>
                </View>
            </StrikeThroughContainer>
        </View>
    );
}

function BoostedCashbackAmount({ boostedCashback }: { boostedCashback: string }) {
    return (
        <>
            <Image source={currencySymbolPurple} style={styles.imageCashbackAmount} />
            <Text style={styles.textBoostedCashbackAmount}>{boostedCashback}</Text>
        </>
    );
}

function FavoriteOfferButton({ userOffer }: { userOffer: EnrichedUserOffer }) {
    const onPressFavoriteOfferButton = useOnPressFavoriteOfferButton(userOffer);
    if (userOffer.offer.type === OfferType.Ongoing) return null;
    return (
        <HoverableTouchableOpacity
            style={styles.containerFavoriteButton}
            activeOpacity={0.8}
            hoveredStyle={styles.containerFavoriteButtonHovered}
            onPress={onPressFavoriteOfferButton}>
            {userOffer.favorited ? (
                <Image source={heartIconPink} style={{ height: 25, width: 30, top: 1 }} />
            ) : (
                <Image source={heartShapeIcon} style={{ height: 25, width: 30, top: 1 }} />
            )}
        </HoverableTouchableOpacity>
    );
}

function ActivateButton({ userOffer }: { userOffer: EnrichedUserOffer }) {
    const texts = getLocalizedTexts().home.offers.offerDetails.button;
    const [onPressOfferButton] = useOnPressOfferButton(userOffer, AffiliateLinkLoadingTriggeringEventType.offerDetails);
    if (userOffer.offer.type !== OfferType.Online && userOffer.active && userOffer.available)
        return (
            <View style={styles.containerActivatedOfferCheckMark}>
                <Image source={checkMarkIcon} style={{ height: 17, width: 17 }} />
            </View>
        );
    return (
        <HoverableTouchableOpacity
            style={[styles.containerActivateButton, !userOffer.available ? styles.containerActivateButtonUnavailable : {}]}
            hoveredStyle={[userOffer.available && styles.containerActivateButtonHovered]}
            onPress={onPressOfferButton}>
            <Text style={styles.textActivateButton}>
                {!userOffer.available
                    ? texts.unavailable
                    : userOffer.offer.type === OfferType.Online
                    ? texts.shop
                    : texts.activate}
            </Text>
        </HoverableTouchableOpacity>
    );
}

function MultipleRates({
    multipleCashbackRates,
    numberOfRates,
}: {
    multipleCashbackRates: CashbackRate[];
    numberOfRates: number;
}) {
    const texts = getLocalizedTexts().home.offers.offerDetails.multipleRates;
    const [width, setWidth] = React.useState<number | undefined>(undefined);
    const onLayoutSetHighestWidth = (event: LayoutChangeEvent) => {
        const newWidth = event.nativeEvent.layout.width;
        if (!width || newWidth > width) {
            setWidth(newWidth);
        }
    };
    if (multipleCashbackRates.length > 0) {
        return (
            <OfferDetailSection title={texts.title}>
                <View>
                    {multipleCashbackRates.map((cashbackRate, index) => (
                        <MultipleRateItem
                            key={index}
                            {...{ cashbackRate, numberOfRates, width, index, onLayoutSetHighestWidth }}
                        />
                    ))}
                </View>
            </OfferDetailSection>
        );
    }
    return null;
}

function MultipleRateItem({
    cashbackRate,
    numberOfRates,
    width,
    index,
    onLayoutSetHighestWidth,
}: {
    cashbackRate: CashbackRate;
    numberOfRates: number;
    width: number | undefined;
    index: number;
    onLayoutSetHighestWidth: (event: LayoutChangeEvent) => void;
}) {
    const texts = getLocalizedTexts().home.offers.offerDetails.cashback;
    const cashbackAmount = Math.max(cashbackRate.nonBoostedCashback, cashbackRate.boostedCashback);
    const formattedCashbackAmount =
        cashbackRate.computationType === ComputationType.variable
            ? formatPercentageAmount(cashbackAmount)
            : formatCurrencyAmount(cashbackAmount);
    return (
        <View style={{ flexDirection: 'row', marginTop: index === 0 ? 12 : 16 }}>
            <View>
                <View style={[styles.containerMultipleRateAmount, { minWidth: width }]} onLayout={onLayoutSetHighestWidth}>
                    <CashbackAmount cashbackAmount={formattedCashbackAmount} />
                </View>
            </View>
            <Text style={styles.textCashbackTypeDark}>
                {cashbackRate.description ? (
                    <span>{cashbackRate.description}</span>
                ) : numberOfRates === 1 ? (
                    <span>{texts.cashbackDescription}</span>
                ) : null}
            </Text>
        </View>
    );
}

const DESCRIPTION_TEXT_LENGTH_LIMIT = 160;
const DESCRIPTION_TEXT_CROPPED_LENGTH = 125;

function Description({ userOffer }: { userOffer: EnrichedUserOffer }) {
    const texts = getLocalizedTexts().home.offers.offerDetails.description;
    const text = userOffer.offer.screen?.subtitle || '';
    const isTextTooLong = text.length > DESCRIPTION_TEXT_LENGTH_LIMIT;
    const [seeAll, setSeeAll] = React.useState<boolean>(isTextTooLong ? false : true);
    const croppedText = React.useMemo(
        () => (seeAll ? text : text.substring(0, DESCRIPTION_TEXT_CROPPED_LENGTH).trim() + '...'),
        [text, seeAll]
    );
    return (
        <OfferDetailSection title={texts.title({ offerTitle: userOffer.offer.screen?.title || '' })}>
            <TouchableOpacity
                style={styles.containerSectionContent}
                onPress={() => setSeeAll(!seeAll)}
                disabled={!isTextTooLong}>
                <Text style={styles.textSectionContentLight}>
                    {croppedText} {!seeAll ? <Text style={styles.textSectionContentDark}>{texts.seeMore}</Text> : null}
                </Text>
            </TouchableOpacity>
        </OfferDetailSection>
    );
}

function ConditionsSection({
    userOffer,
    shouldUseNewConditions,
}: {
    userOffer: EnrichedUserOffer;
    shouldUseNewConditions: boolean;
}) {
    const texts = getLocalizedTexts().home.offers.offerDetails.conditions;
    return (
        <>
            <OfferDetailSection title={texts.howItWorksTitle}>
                <View style={styles.containerSectionContent}>
                    <View style={[styles.containerGenericCondition, { marginBottom: shouldUseNewConditions ? 0 : 16 }]}>
                        <OfferTypeIcon offer={userOffer.offer} />
                        <Text style={[styles.textSectionContentLight, { flex: 1, marginLeft: 16 }]}>
                            {texts.generic({
                                offerType: userOffer.offer.type,
                                active: userOffer.active,
                            })}
                        </Text>
                    </View>
                    {!shouldUseNewConditions ? <LegacyConditions {...{ userOffer }} /> : null}
                </View>
            </OfferDetailSection>
            {shouldUseNewConditions ? <ConditionList conditions={userOffer.offer.enrichedConditions?.conditions} /> : null}
        </>
    );
}

function OfferTypeIcon({ offer }: { offer: EnrichedMerchantOffer }) {
    if (offer.type === OfferType.Online)
        return (
            <View style={styles.containerOfferTypeIcon}>
                <Image source={cartIcon} style={styles.imageCartIcon} />
            </View>
        );
    const text = getLocalizedTexts().home.offers.offerDetails.conditions.offerTypeIcon.cardLinked;
    return (
        <View style={styles.containerOfferTypeIcon}>
            <View style={styles.containerCardLinkedIndicator}>
                <Image source={creditCardIcon} height={16} />
                <Text style={styles.textCardLinkedIndicator}>{text}</Text>
            </View>
        </View>
    );
}

function LegacyConditions({ userOffer }: { userOffer: EnrichedUserOffer }) {
    const conditions = getFilteredEnrichedConditions(userOffer);
    return (
        <>
            {conditions.map((condition, index) => (
                <ConditionDropdown condition={condition} key={`condition-dropdown-${index}`} />
            ))}
        </>
    );
}

function ConditionDropdown({ condition: { summary, description } }: { condition: EnrichedCondition }) {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    if (!description)
        return (
            <View style={styles.containerConditionDropdown}>
                <Text style={styles.textSectionContentDark}>{summary}</Text>
            </View>
        );
    else
        return (
            <View style={styles.containerConditionDropdown}>
                <TouchableOpacity onPress={() => setIsOpen(!isOpen)} activeOpacity={1}>
                    <View style={containerStyles.containerRowSpaceBetween}>
                        <Text style={styles.textSectionContentDark}>{summary}</Text>
                        <View style={{ transform: [{ rotate: isOpen ? '180deg' : '0deg' }] }}>
                            <Image source={chevronDownIcon} style={{ height: 10, width: 15, resizeMode: 'contain' }} />
                        </View>
                    </View>
                </TouchableOpacity>
                {isOpen ? (
                    <View style={styles.containerConditionDescription}>
                        <Text style={styles.textSectionContentLight}>{description}</Text>
                    </View>
                ) : null}
            </View>
        );
}

function ConditionList({ conditions }: { conditions: EnrichedCondition[] | undefined }) {
    const texts = getLocalizedTexts().home.offers.offerDetails.conditions;
    const sortedConditions = React.useMemo(
        () =>
            conditions
                ?.filter(({ iconUrl }) => iconUrl && iconUrl.length > 0)
                ?.sort(
                    (firstCondition, secondCondition) => (firstCondition.priority || 0) - (secondCondition.priority || 0)
                ),
        [conditions]
    );
    if (!sortedConditions?.length) return null;
    return (
        <OfferDetailSection title={texts.title}>
            {sortedConditions?.map((condition, index) => (
                <ConditionItem key={`offer-condition-${condition.conditionId}`} {...condition} {...{ index }} />
            ))}
        </OfferDetailSection>
    );
}

function ConditionItem({ iconUrl, summary, description, index }: EnrichedCondition & { index: number }) {
    return (
        <View style={[styles.containerConditionItem, index === 0 ? { marginTop: 14 } : { marginTop: 18 }]}>
            {iconUrl ? (
                <Image style={styles.imageContainerIcon} source={{ uri: iconUrl ?? '' }} />
            ) : (
                <View style={styles.imageContainerIcon} />
            )}
            <View style={{ flex: 1 }}>
                <Text style={styles.textConditionItemSummary}>{summary}</Text>
                {description ? <Text style={styles.textConditionItemDescription}>{description}</Text> : null}
            </View>
        </View>
    );
}

const CONDITION_FONT_SIZE = 14;
const CONDITION_ITEM_ICON_SIZE = 1.1 * CONDITION_FONT_SIZE;

const styles = StyleSheet.create({
    container: {
        width: 600,
        borderRadius: 16,
        backgroundColor: 'white',
        overflow: 'hidden',
    },
    containerMainInfo: {
        flexDirection: 'column',
        paddingHorizontal: 40,
        paddingTop: 50,
        paddingBottom: 35,
    },
    containerLogo: {
        position: 'absolute',
        top: -40,
        justifyContent: 'center',
        alignItems: 'center',
        height: 80,
        width: 80,
        borderRadius: 80,
        borderWidth: 3,
        borderColor: color.white,
        backgroundColor: color.linkWaterLight,
    },
    containerMainInfoTexts: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    containerCashback: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    containerNonBoostedCashback: {
        marginRight: 4,
    },
    containerExpirationDate: {
        marginLeft: 8,
    },
    containerFavoriteButton: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        width: 50,
        borderRadius: 50,
        borderWidth: 1,
        borderColor: color.gallery,
        marginRight: 10,
    },
    containerFavoriteButtonHovered: {
        opacity: 0.8,
    },
    containerActivateButton: {
        height: 50,
        borderRadius: 80,
        backgroundColor: color.black,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 35,
    },
    containerActivateButtonUnavailable: {
        backgroundColor: color.alto,
    },
    containerActivateButtonHovered: {
        opacity: 0.7,
    },
    containerActivatedOfferCheckMark: {
        height: 50,
        width: 50,
        borderRadius: 50,
        backgroundColor: color.emerald,
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerMultipleRateAmount: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: '8px',
    },
    containerSectionContent: {
        marginTop: 12,
    },
    containerGenericCondition: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerConditionDropdown: {
        marginTop: 15,
    },
    containerConditionDescription: {
        marginRight: 20,
    },
    containerConditionItem: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    containerOfferTypeIcon: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 56,
        height: 56,
        borderRadius: 56,
        backgroundColor: color.tranquil,
        borderWidth: 1,
        borderColor: color.tranquil,
    },
    containerCardLinkedIndicator: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageCartIcon: {
        width: 25,
        height: 22,
        resizeMode: 'contain',
    },
    imageBanner: {
        height: 220,
        backgroundColor: color.linkWaterLight,
    },
    imageLogo: {
        height: 40,
        width: 40,
        resizeMode: 'contain',
    },
    imageCashbackAmount: {
        height: 13,
        width: 13,
        resizeMode: 'contain',
        marginRight: 3,
    },
    imageContainerIcon: {
        width: CONDITION_ITEM_ICON_SIZE,
        height: CONDITION_ITEM_ICON_SIZE,
        marginRight: 16,
    },
    textOfferTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.black,
        marginBottom: 5,
    },
    textCashbackAmount: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.emerald,
    },
    textBoostedCashbackAmount: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.electricViolet,
    },
    textNonBoostedCashbackAmount: {
        fontFamily: font.ambitBold,
        fontSize: 14,
        color: color.osloGray,
        marginLeft: 2,
    },
    textCashbackDescription: {
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        color: color.osloGray,
        marginLeft: 4,
    },
    textCashbackTypeDark: {
        flex: 1,
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.black,
    },
    textActivateButton: {
        fontFamily: font.ambitBlack,
        fontSize: 16,
        color: color.white,
    },
    textSectionTitle: {
        fontFamily: font.ambitBold,
        fontSize: 16,
        fontWeight: '700',
        color: color.black,
    },
    textSectionContentLight: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.black,
    },
    textSectionContentDark: {
        fontFamily: font.ambitBold,
        fontSize: 14,
        color: color.black,
    },
    textConditionItemSummary: {
        fontFamily: font.ambitRegular,
        fontSize: CONDITION_FONT_SIZE,
        color: color.black,
    },
    textConditionItemDescription: {
        marginTop: 4,
        fontFamily: font.ambitRegular,
        fontSize: CONDITION_FONT_SIZE,
        color: color.doveGray,
    },
    textCardLinkedIndicator: {
        marginLeft: 3,
        fontFamily: font.ambitBold,
        fontSize: 14,
        color: color.easternBlue,
    },
});
