import gql from 'graphql-tag';

import { TrackingId } from '../fragments/trackingConsents';

export const updateUserTrackingConsentMutation = gql`
    mutation UpdateUserTrackingConsent($trackingId: String!, $consentChoice: Boolean!) {
        updateUserTrackingConsent(trackingId: $trackingId, consentChoice: $consentChoice) {
            trackingId
            consentChoice
            expiresAt
        }
    }
`;

export interface UpdateUserTrackingConsentMutationResponse {
    __typename: 'Mutation';
    updateUserTrackingConsent: {
        __typename: 'UserTrackingConsent';
        tracking: TrackingId;
        consentChoice: boolean;
        expiresAt: number;
    } | null;
}

export interface UpdateUserTrackingConsentMutationVariables {
    trackingId: TrackingId;
    consentChoice: boolean;
}
