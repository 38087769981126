import gql from 'graphql-tag';

export const rewardFragment = gql`
    fragment Reward on Reward {
        rewardId
        points
        minPoints
        maxPoints
        pointInterval
        type
        displayOrder
        card {
            title
            imageURL
            logoURL
            color
        }
        screen {
            title
            description
            imageURL
            logoURL
        }
        conditions {
            timestampMin
            timestampMax
        }
        bankTransferProperties {
            currency
            pointConversionRate
            terms
            bankCountryWhitelist
        }
    }
`;

interface RewardCard {
    __typename: 'RewardCard';
    title: string | null;
    imageURL: string;
    logoURL: string | null;
    color: string | null;
}

export interface RewardScreen {
    __typename: 'RewardScreen';
    title: string;
    description: string;
    imageURL: string;
    logoURL: string;
}

export interface RewardConditions {
    __typename: 'RewardConditions';
    timestampMin: number | null;
    timestampMax: number | null;
}

export interface RewardBankTransferProperties {
    __typename: 'RewardBankTransferProperties';
    currency: string | null;
    pointConversionRate: number | null;
    terms: string | null;
    bankCountryWhitelist: string[] | null;
}

export enum RewardType {
    gift = 'gift',
    giftCard = 'gift_card',
    event = 'event',
    donation = 'donation',
    contest = 'contest',
    bankTransfer = 'bank_transfer',
}

export interface Reward {
    __typename: 'Reward';
    rewardId: string;
    points: number | null;
    minPoints: number | null;
    maxPoints: number | null;
    pointInterval: number | null;
    type: RewardType;
    displayOrder: number | null;
    card: RewardCard;
    screen: RewardScreen;
    conditions: RewardConditions | null;
    bankTransferProperties: RewardBankTransferProperties | null;
}

export const dataIdFromReward = (reward: Reward) => {
    return `Reward:${reward.rewardId}`;
};

export const userRewardFragment = gql`
    fragment UserReward on UserReward {
        createdAt
        rewardId
        points
    }
`;

export interface UserRewardBankTransferDetails {
    __typename: 'UserRewardBankTransferDetails';
    amount: number | null;
    currency: string | null;
}

export interface UserRewardDonationDetailsDetails {
    __typename: 'UserRewardDonationDetails';
    isAutoDonation: boolean | null;
}

export interface UserReward {
    __typename: 'UserReward';
    createdAt: number;
    rewardId: string;
    points: number;
}

export const dataIdFromUserReward = (userReward: UserReward) => {
    return `UserReward:${userReward.createdAt}`;
};
