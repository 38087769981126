import * as React from 'react';
import { Auth } from 'aws-amplify';

import { getUserId } from '../../api/rest/user';
import { EmailAddressUnknown, GeneratedPassword } from '../../api/rest/errors';
import { PasswordType } from '../../api/graphql/fragments/user';
import { getDevStackMode } from '../../lib/common/devStackMode';
import { getRegion } from './region';
import { getDeviceId } from '../common/device';
import { logAmplitudeEvent } from '../events/amplitudeEvents';
import { PasswordRequirementsChecks } from '../../components/auth/PasswordRequirements';

const md5 = require('md5');
const { v1: uuidv1 } = require('uuid');

export enum SignUpError {
    EmailNotValid = 'EmailNotValid',
    PasswordNotValid = 'PasswordNotValid',
    DidNotConsentToTermsOfServices = 'DidNotConsentToTermsOfServices',
    AlreadyExists = 'AlreadyExists',
    Other = 'Other',
}

export function useEmailSignUp({
    onSignUpStart,
    onSignUpEnd,
}: {
    onSignUpStart?: () => void;
    onSignUpEnd?: () => void;
}): [
    (
        email: string,
        password: string,
        passwordRequirementsChecks: PasswordRequirementsChecks,
        consentedToTermsOfService: boolean,
        consentedToNewsletter: boolean
    ) => Promise<void>,
    boolean,
    SignUpError | undefined,
    () => void
] {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<SignUpError | undefined>();
    const signUp = async (
        email: string,
        password: string,
        passwordRequirementsChecks: PasswordRequirementsChecks,
        consentedToTermsOfService: boolean,
        consentedToNewsletter: boolean
    ): Promise<void> => {
        if (!isEmailValid(email)) setError(SignUpError.EmailNotValid);
        else if (!passwordRequirementsChecks.all) setError(SignUpError.PasswordNotValid);
        else if (!consentedToTermsOfService) setError(SignUpError.DidNotConsentToTermsOfServices);
        else {
            setIsLoading(true);
            logAmplitudeEvent({ name: 'Registration - Validated Email', properties: { email } });
            logAmplitudeEvent({ name: 'Registration - Defined Password' });
            if (consentedToTermsOfService)
                logAmplitudeEvent({ name: 'Registration - Ticked Checkbox For Terms Of Service and Privacy Policy' });
            if (consentedToNewsletter) logAmplitudeEvent({ name: 'Registration - Ticked Checkbox For Newsletter' });
            try {
                await getUserId(email);
                setError(SignUpError.AlreadyExists);
            } catch (error) {
                if (error instanceof EmailAddressUnknown) {
                    const passwordMd5: string = md5(password);
                    const userId: string = uuidv1();
                    const region = getRegion();
                    try {
                        if (onSignUpStart) onSignUpStart();
                        await Auth.signUp({
                            username: userId,
                            password: passwordMd5,
                            attributes: {
                                email: email.toLowerCase(),
                                'custom:password_type': PasswordType.manuallyDefined,
                                'custom:region': region,
                                'custom:newsletter_consent': `${consentedToNewsletter}`,
                                'custom:signup_platform': 'webApp',
                                'custom:signup_device_id': getDeviceId(),
                            },
                        });
                        Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
                        await Auth.signIn({ username: userId, password: passwordMd5 });
                        // AWS SES is not authorized to send emails to non @hellojoko.com email addresses on the dev stack.
                        // Cognito uses SES to send the verification email under the hood here, so we avoid errors by limiting verification to the prod stack.
                        if (!getDevStackMode()) await Auth.verifyCurrentUserAttribute('email');
                        if (onSignUpEnd) onSignUpEnd();
                    } catch (error) {
                        console.log(error);
                        setError(SignUpError.Other);
                    }
                } else if (error instanceof GeneratedPassword) setError(SignUpError.AlreadyExists);
                else setError(SignUpError.Other);
            }
            setIsLoading(false);
        }
    };
    const clearError = () => setError(undefined);
    return [signUp, isLoading, error, clearError];
}

export function isEmailValid(email: string): boolean {
    const regex =
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    return regex.test(email.toLowerCase());
}
