import * as React from 'react';
import { Animated } from 'react-native';
import Svg, { Text, Defs, LinearGradient, Stop } from 'react-native-svg';
import { font } from '../../style/text';

const AnimatedLinearGradient = Animated.createAnimatedComponent(LinearGradient);

export default function GradientText({ text, fontSize }: { text: string; fontSize: number }) {
    const animatedValue = React.useRef(new Animated.Value(0)).current;
    React.useEffect(() => {
        Animated.loop(
            Animated.timing(animatedValue, {
                toValue: 1,
                duration: 3000,
                useNativeDriver: false, // We're not animating transform properties, so false is required
            })
        ).start();
    }, [animatedValue]);
    const startX = animatedValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['-100%', '100%'], // Move from left to right
    });
    const endX = animatedValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0%', '200%'], // Ending further right for smooth movement
    });
    return (
        <Svg height="32" width="944">
            <Defs>
                <AnimatedLinearGradient id="grad" x1={startX} y1="0" x2={endX} y2="0">
                    <Stop offset="0%" stopColor="#A1A1A1" />
                    <Stop offset="50%" stopColor="#E2E2E2" />
                    <Stop offset="100%" stopColor="#A1A1A1" />
                </AnimatedLinearGradient>
            </Defs>
            {/* Apply the gradient fill to the text */}
            <Text
                fill="url(#grad)" // Reference the gradient by ID
                fontSize={fontSize}
                fontFamily={font.ambitSemiBold}
                x="0"
                y={fontSize + 4}>
                {text}
            </Text>
        </Svg>
    );
}
