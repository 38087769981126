import gql from 'graphql-tag';

import { ApolloClient } from '../client';
import { dataIdFromEnrichedUserOffer, EnrichedUserOffer } from '../fragments/enrichedOffers';
import { favoriteOffersQuery, FavoriteOffersQueryResponse } from '../queries/enrichedOffers';

const favoriteUserOfferMutation = gql`
    mutation FavoriteUserOffer($offerId: String!) {
        favoriteUserOffer(offerId: $offerId) {
            offerId
            favorited
        }
    }
`;

interface FavoriteUserOfferMutationResponse {
    __typename: 'Mutation';
    favoriteUserOffer: {
        __typename: 'UserOffer';
        offerId: string;
        favorited: boolean | undefined;
    } | null;
}

interface FavoriteUserOfferMutationVariables {
    offerId: string;
}

export function favoriteUserOffer(client: ApolloClient, userOffer: EnrichedUserOffer) {
    const offerId = userOffer.offerId;
    client.writeFragment<{
        __typename: 'EnrichedUserOffer';
        offerId: string;
        favorited: boolean | undefined;
    }>({
        id: dataIdFromEnrichedUserOffer({ offerId }),
        fragment: gql`
            fragment FavoritedEnrichedUserOffer on EnrichedUserOffer {
                offerId
                favorited
            }
        `,
        data: {
            __typename: 'EnrichedUserOffer',
            offerId: offerId,
            favorited: true,
        },
    });
    client.mutate<FavoriteUserOfferMutationResponse, FavoriteUserOfferMutationVariables>({
        mutation: favoriteUserOfferMutation,
        fetchPolicy: 'no-cache',
        variables: { offerId },
    });
    updateFavoriteOffersQueryResponseInCache(client, userOffer);
}

function updateFavoriteOffersQueryResponseInCache(client: ApolloClient, userOffer: EnrichedUserOffer) {
    try {
        const dataInCache = client.readQuery<FavoriteOffersQueryResponse>({
            query: favoriteOffersQuery,
        });
        const favoriteOffers = dataInCache?.user?.enrichedOffers;
        if (favoriteOffers && !favoriteOffers.find((offer) => offer.offerId === userOffer.offerId))
            client.writeQuery<FavoriteOffersQueryResponse>({
                query: favoriteOffersQuery,
                data: {
                    __typename: 'Query',
                    user: {
                        __typename: 'User',
                        enrichedOffers: favoriteOffers.concat({ ...userOffer, favorited: true }),
                    },
                },
            });
    } catch {}
}
