import React from 'react';

import { ApolloClient } from '../../api/graphql/client';
import { sessionIdVar } from '../../api/graphql/reactiveVariables';
import { logAmplitudeEvent } from '../events/amplitudeEvents';
import { logUserEventUtil } from '../events/userEvents';
import { captureAttributionParameters } from './attribution';

const SESSION_ID_LOCAL_STORAGE_KEY = '@AuthenticationStore:sessionId';
const LAST_EVENT_TIMESTAMP_MS_LOCAL_STORAGE_KEY = '@AuthenticationStore:lastEventTimestampMs';

const SESSION_TRACKING_WINDOW_MS = 5 * 60 * 1000;

export function useSessionTracker(apolloClient: ApolloClient | undefined) {
    React.useEffect(() => {
        if (apolloClient) trackSession(apolloClient);
    }, [apolloClient]);
    React.useEffect(() => {
        const visibilityChangeListener = () => {
            if (document.visibilityState === 'visible' && apolloClient) startOrContinueSession(apolloClient);
        };
        document.addEventListener('visibilitychange', visibilityChangeListener);
        return () => {
            document.removeEventListener('visibilitychange', visibilityChangeListener);
        };
    }, [apolloClient]);
}

async function trackSession(apolloClient: ApolloClient) {
    await startOrContinueSession(apolloClient);
    await logAmplitudeEvent({ name: 'Navigation - Launched App' });
    await logUserEventUtil(apolloClient, { type: 'launchedApp' });
}

async function startOrContinueSession(apolloClient: ApolloClient): Promise<void> {
    const lastSessionId = getSessionId();
    const lastEventTimestampMs = getLastEventTimestampMs();
    if (!lastSessionId || !lastEventTimestampMs || Date.now() - lastEventTimestampMs > SESSION_TRACKING_WINDOW_MS) {
        const sessionId = Date.now();
        console.log(`Starting a new session... (Session ID: ${sessionId})`);
        updateSessionId(sessionId);
        sessionIdVar(sessionId);
        await logUserEventUtil(apolloClient, { type: 'sessionStarted', payload: { sessionId: sessionId.toString() } });
        await captureAttributionParameters(apolloClient);
    } else {
        console.log(`Continuing existing session... (Session ID: ${lastSessionId})`);
        sessionIdVar(lastSessionId);
    }
}

function getSessionId(): number | undefined {
    const sessionIdString = localStorage.getItem(SESSION_ID_LOCAL_STORAGE_KEY);
    if (!sessionIdString) return undefined;
    else return Number(sessionIdString);
}

function updateSessionId(sessionId: number): void {
    localStorage.setItem(SESSION_ID_LOCAL_STORAGE_KEY, sessionId.toString());
}

export function clearSessionId(): void {
    localStorage.removeItem(SESSION_ID_LOCAL_STORAGE_KEY);
    localStorage.removeItem(LAST_EVENT_TIMESTAMP_MS_LOCAL_STORAGE_KEY);
}

function getLastEventTimestampMs(): number | undefined {
    const lastEventTimestampMsString = localStorage.getItem(LAST_EVENT_TIMESTAMP_MS_LOCAL_STORAGE_KEY);
    if (!lastEventTimestampMsString) return undefined;
    else return Number(lastEventTimestampMsString);
}

export function updateLastEventTimestampMs(timestampMs: number | undefined = undefined): void {
    const timestampMsString = timestampMs ? timestampMs.toString() : Date.now().toString();
    localStorage.setItem(LAST_EVENT_TIMESTAMP_MS_LOCAL_STORAGE_KEY, timestampMsString);
}
