import { getMarketingWebsiteUrl, MarketingWebsitePath } from '../../../lib/auth/region';

const RETURN_TO_APP_URL = 'joko://';
const SYSTEM_SETTINGS_REDIRECTION = 'MobileSafariExtensionSystemSettingsRedirection/';

export function returnToApp() {
    window.location.href = RETURN_TO_APP_URL;
}

export function returnToSystemSettings() {
    window.location.replace(RETURN_TO_APP_URL + SYSTEM_SETTINGS_REDIRECTION);
}

export function redirectToPrivacyPolicy() {
    window.open(getMarketingWebsiteUrl({ path: MarketingWebsitePath.privacyPolicy }));
}

export function redirectToTermsOfService() {
    window.open(getMarketingWebsiteUrl({ path: MarketingWebsitePath.termOfService }));
}
