import * as Sentry from '@sentry/browser';

import { getDevStackMode } from './devStackMode';
import { getUserId } from './cognito';
import { webAppVersion } from '../../Version';

const SENTRY_DSN = 'https://21259d90a1191a86351ffa9aad5196a7@o188289.ingest.us.sentry.io/4507776787087360';

Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [],
    sampleRate: 1, // 100% of the Sentry events are sent to their backend
    release: webAppVersion,
});

Sentry.setTags({
    environment: process.env.NODE_ENV,
    userId: getUserId(),
    isDevStack: getDevStackMode(),
});

export function captureSentryException(exception: unknown) {
    Sentry.captureException(exception);
}
