import { ApolloClient } from '../../api/graphql/client';
import { merchantQuery, MerchantQueryResponse, MerchantQueryVariables } from '../../api/graphql/queries/merchants';

export async function getMerchantName(
    apolloClient: ApolloClient,
    merchantId: string
): Promise<{ merchantId: string; merchantName: string | undefined }> {
    const queryResponse = await apolloClient.query<MerchantQueryResponse, MerchantQueryVariables>({
        query: merchantQuery,
        variables: { merchantId },
        fetchPolicy: 'cache-first',
    });
    return { merchantId, merchantName: queryResponse.data.merchant?.name ?? undefined };
}
