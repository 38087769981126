import gql from 'graphql-tag';

export const userStateFragment = gql`
    fragment UserState on UserState {
        stateKey
        value
    }
`;

export enum StateKey {
    isDev = 'isDev',
    abTestGroup = 'abTestGroup',
    hasEverLoggedInToBrowserExtension = 'hasEverLoggedInToBrowserExtension',
}

export interface UserState {
    __typename: 'UserState';
    stateKey: StateKey;
    value: string | null;
}

export const dataIdFromUserState = (userState: UserState | Pick<UserState, 'stateKey'>) => {
    return `UserState:${userState.stateKey}`;
};
