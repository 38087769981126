import gql from 'graphql-tag';

import { LegalUpdatesToBeReviewed, legalUpdatesToBeReviewedFragment } from '../fragments/legalUpdates';

export const legalUpdatesToBeReviewedQuery = gql`
    query LegalUpdatesToBeReviewedQuery {
        user {
            legalUpdatesToBeReviewed {
                ...LegalUpdatesToBeReviewed
            }
        }
    }
    ${legalUpdatesToBeReviewedFragment}
`;

interface User {
    __typename: 'User';
    legalUpdatesToBeReviewed: LegalUpdatesToBeReviewed | null;
}

export type LegalUpdatesToBeReviewedQueryResponse = {
    __typename: 'Query';
    user: User;
};
