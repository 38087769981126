import React from 'react';
import { View } from 'react-native';

export function InlineMp4({ src, poster }: { src: string; poster?: string }) {
    return (
        <View style={{ width: '100%' }}>
            <video autoPlay loop muted playsInline poster={poster}>
                <source src={src} type="video/mp4" />
            </video>
        </View>
    );
}
