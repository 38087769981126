import gql from 'graphql-tag';

import { OfferBrowsingTag, offerBrowsingTagFragment } from '../fragments/offerBrowsingTags';

export const offerBrowsingTagQuery = gql`
    query ListOfferBrowsingTags($devMode: Boolean) {
        offerBrowsingTags(devMode: $devMode) {
            items {
                ...OfferBrowsingTag
            }
            nextToken
        }
    }
    ${offerBrowsingTagFragment}
`;

export interface OfferBrowsingTagList {
    __typename: 'OfferBrowsingTagList';
    items: OfferBrowsingTag[];
    nextToken: string | null;
}

export type OfferBrowsingTagQueryResponse = {
    offerBrowsingTags: OfferBrowsingTagList;
};

export type OfferBrowsingTagQueryVariables = {
    devMode: boolean;
};
