import React from 'react';
import { View } from 'react-native';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { useApolloClient } from '../../api/config';
import { useOnboardingRedirection } from '../../lib/onboarding/navigation';
import { usePointCollectionModalManager } from '../../lib/points/pointCollection';
import { useLegalUpdatesModalManager } from '../../lib/common/legalUpdates';
import { updateFeatureMapVar } from '../../lib/features/buildFeatureMap';
import ModalContainer, { useModalLocationManager } from '../../components/common/ModalContainer';
import NavigationBar from '../../pages/home/NavigationBar';
import HomePage from '../../pages/home/HomePage';
import FavoriteOffersPage from '../../pages/home/FavoriteOffersPage';
import RewardsPage from '../../pages/home/rewards/RewardsPage';
import OfferDetailPage from '../../pages/home/OfferDetailPage';
import RewardDetailPage from '../../pages/home/rewards/RewardDetailPage';
import BankTransferDetailPage from '../../pages/home/rewards/BankTransferDetailPage';
import BankTransferSuccessPage from '../../pages/home/rewards/BankTransferSuccessPage';
import UserBankDetailsPage from '../../pages/home/rewards/UserBankDetailsPage';
import OfferSearchPage from '../../pages/home/OfferSearchPage';
import OfferBrowsingTagsPage from '../../pages/home/OfferBrowsingTagsPage';
import HistoryPage from '../../pages/home/HistoryPage';
import PointCollectionPage from '../../pages/home/PointCollectionPage';
import ProfileNavigator from '../../navigators/home/ProfileNavigator';
import LegalUpdatesPage from '../../pages/home/LegalUpdatesPage';

const modalPathPrefixes = [
    '/offer',
    '/rewards/gift-card/',
    '/rewards/donation/',
    '/rewards/bank-transfer/',
    '/history',
    '/point-collection',
    '/legal-updates',
];

function HomeNavigator() {
    const apolloClient = useApolloClient();
    const location = useLocation();
    let { path } = useRouteMatch();
    const [, lastNonModalLocation] = useModalLocationManager(modalPathPrefixes);
    React.useEffect(() => {
        if (apolloClient) updateFeatureMapVar(apolloClient);
    }, [apolloClient]);
    useOnboardingRedirection();
    usePointCollectionModalManager();
    useLegalUpdatesModalManager();
    return (
        <View style={{ minHeight: '100vh' }}>
            <NavigationBar />
            <Switch location={lastNonModalLocation}>
                <Route exact path={path}>
                    <HomePage />
                </Route>
                <Route exact path={`${path}/favorite-offers`}>
                    <FavoriteOffersPage />
                </Route>
                <Route exact path={`${path}/rewards`}>
                    <RewardsPage />
                </Route>
                <Route path={`${path}/profile`}>
                    <ProfileNavigator />
                </Route>
                <Route exact path={`${path}/search/`}>
                    <OfferBrowsingTagsPage />
                </Route>
                <Route path={`${path}/search/:searchValue`}>
                    <OfferSearchPage />
                </Route>
                <Route path={`${path}/tag/:tagId`}>
                    <OfferSearchPage />
                </Route>
            </Switch>
            <Switch location={location}>
                <Route exact path={`${path}/offer/:offerId`}>
                    <ModalContainer>
                        <OfferDetailPage />
                    </ModalContainer>
                </Route>
                <Route exact path={`${path}/rewards/gift-card/:rewardId`}>
                    <ModalContainer>
                        <RewardDetailPage />
                    </ModalContainer>
                </Route>
                <Route exact path={`${path}/rewards/donation/:rewardId`}>
                    <ModalContainer>
                        <RewardDetailPage />
                    </ModalContainer>
                </Route>
                <Route exact path={`${path}/rewards/bank-transfer/:rewardId/bank-details/:pointsString`}>
                    <ModalContainer cannotClose>
                        <UserBankDetailsPage />
                    </ModalContainer>
                </Route>
                <Route exact path={`${path}/rewards/bank-transfer/:rewardId/success`}>
                    <ModalContainer cannotClose>
                        <BankTransferSuccessPage />
                    </ModalContainer>
                </Route>
                <Route exact path={`${path}/rewards/bank-transfer/:rewardId`}>
                    <ModalContainer>
                        <BankTransferDetailPage />
                    </ModalContainer>
                </Route>
                <Route exact path={`${path}/history`}>
                    <ModalContainer>
                        <HistoryPage />
                    </ModalContainer>
                </Route>
                <Route exact path={`${path}/point-collection`}>
                    <ModalContainer cannotClose>
                        <PointCollectionPage />
                    </ModalContainer>
                </Route>
                <Route exact path={`${path}/legal-updates`}>
                    <ModalContainer cannotClose>
                        <LegalUpdatesPage />
                    </ModalContainer>
                </Route>
            </Switch>
        </View>
    );
}

export default HomeNavigator;
