import * as React from 'react';
import { ActivityIndicator, Image, Text, View } from 'react-native';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import {
    UrlStep,
    InstallationStep,
    useLogEvents,
    useInstallationStep,
    useCloseObsoletePagesWhenSecondWaitingPageIsOpened,
    isOsVersion18OrLater,
} from '../../lib/mobileSafariExtension/mobileSafariExtension';
import {
    InstructionActivateJokoExtension,
    InstructionAllowAlways,
    InstructionAllowOnAllWebsites,
    InstructionClickBanner,
    InstructionClickExtensionWarningIcon,
    InstructionClickSafariSettings,
    InstructionManageExtensions,
    InstructionSystemSettingsAllow,
    InstructionSystemSettingsOtherWebsites,
    InstructionSystemSettingsClickButton,
} from '../../components/onboarding/mobileSafariExtension/Instructions';
import {
    containerStyleWithGap,
    getImageActivationPreambleStyle,
    FINGER_POINTING_UP_EMOJI,
    newDesignSystemFontStyles,
    styles,
} from '../../components/onboarding/mobileSafariExtension/Styles';
import {
    NewDesignSystemButton,
    MissingVerifyBannerButton,
    TosConsentButton,
    onPressReturnToApp,
    onPressOpenSystemSettings,
    onPressStartActivation,
} from '../../components/onboarding/mobileSafariExtension/Buttons';
import { Header } from '../../components/onboarding/mobileSafariExtension/Header';
import { getRegion, localizeAsset, LocalizedAsset, Region } from '../../lib/auth/region';
import { InlineMp4 } from '../../components/onboarding/mobileSafariExtension/InlineMp4';

const widgetIllustrationActivationPreamble: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/widget-offer.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/widget-offer-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/widget-offer-us.png' },
};
const activationAnimation = '/assets/images/illustrations/mobile-safari-extension-installation/activation-animation.mp4';
const activationAnimationIos18 = {
    [Region.FR]: '/assets/images/illustrations/mobile-safari-extension-installation/activation-animation-ios-18.mp4',
    [Region.US]: '/assets/images/illustrations/mobile-safari-extension-installation/activation-animation-ios-18-us.mp4',
    [Region.GB]: '/assets/images/illustrations/mobile-safari-extension-installation/activation-animation-ios-18-us.mp4',
};
const activationAnimationPlaceholder =
    '/assets/images/illustrations/mobile-safari-extension-installation/activation-animation-placeholder.png';
const activationAnimationPlaceholderIos18 = {
    [Region.FR]:
        '/assets/images/illustrations/mobile-safari-extension-installation/activation-animation-placeholder-ios-18.png',
    [Region.US]:
        '/assets/images/illustrations/mobile-safari-extension-installation/activation-animation-placeholder-ios-18-us.png',
    [Region.GB]:
        '/assets/images/illustrations/mobile-safari-extension-installation/activation-animation-placeholder-ios-18-us.png',
};
const authorizationWithBannerAnimation = {
    [Region.FR]: '/assets/images/illustrations/mobile-safari-extension-installation/authorization-with-banner-animation.mp4',
    [Region.US]:
        '/assets/images/illustrations/mobile-safari-extension-installation/authorization-with-banner-animation-us.mp4',
    [Region.GB]:
        '/assets/images/illustrations/mobile-safari-extension-installation/authorization-with-banner-animation-us.mp4',
};
const authorizationWithBannerAnimationPlaceholder = {
    [Region.FR]:
        '/assets/images/illustrations/mobile-safari-extension-installation/authorization-with-banner-animation-placeholder.png',
    [Region.US]:
        '/assets/images/illustrations/mobile-safari-extension-installation/authorization-with-banner-animation-placeholder-us.png',
    [Region.GB]:
        '/assets/images/illustrations/mobile-safari-extension-installation/authorization-with-banner-animation-placeholder-us.png',
};
const authorizationFallbackAnimation =
    '/assets/images/illustrations/mobile-safari-extension-installation/authorization-fallback-animation.mp4';
const authorizationFallbackAnimationIos18 = {
    [Region.FR]:
        '/assets/images/illustrations/mobile-safari-extension-installation/authorization-fallback-animation-ios-18.mp4',
    [Region.US]:
        '/assets/images/illustrations/mobile-safari-extension-installation/authorization-fallback-animation-ios-18-us.mp4',
    [Region.GB]:
        '/assets/images/illustrations/mobile-safari-extension-installation/authorization-fallback-animation-ios-18-us.mp4',
};
const authorizationFallbackAnimationPlaceholder =
    '/assets/images/illustrations/mobile-safari-extension-installation/authorization-fallback-animation-placeholder.png';
const authorizationFallbackAnimationPlaceholderIos18 = {
    [Region.FR]:
        '/assets/images/illustrations/mobile-safari-extension-installation/authorization-fallback-animation-placeholder-ios-18.png',
    [Region.US]:
        '/assets/images/illustrations/mobile-safari-extension-installation/authorization-fallback-animation-placeholder-ios-18-us.png',
    [Region.GB]:
        '/assets/images/illustrations/mobile-safari-extension-installation/authorization-fallback-animation-placeholder-ios-18-us.png',
};
const successAnimation = '/assets/images/illustrations/mobile-safari-extension-installation/success-animation.mp4';
const successAnimationIos18 = {
    [Region.FR]: '/assets/images/illustrations/mobile-safari-extension-installation/success-animation-ios-18.mp4',
    [Region.US]: '/assets/images/illustrations/mobile-safari-extension-installation/success-animation-ios-18-us.mp4',
    [Region.GB]: '/assets/images/illustrations/mobile-safari-extension-installation/success-animation-ios-18-us.mp4',
};

export function MobileSafariExtensionInstallationPageContentRevamped({
    urlStep,
    isDevStackMode,
    userId,
    deviceId,
    isOnboarding,
    isBeforeOnboarding,
}: {
    urlStep: UrlStep;
    isDevStackMode: boolean;
    userId: string | undefined;
    deviceId: string | undefined;
    isOnboarding: boolean;
    isBeforeOnboarding: boolean;
}) {
    const [step, setStep] = useInstallationStep({
        urlStep,
        userId,
        isDevStackMode,
        shouldSkipActivationPreamble: !isBeforeOnboarding,
        shouldSkipAuthorizationPreamble: true,
    });
    useLogEvents({
        urlStep,
        userId,
        deviceId,
        isDevStackMode,
        isOnboarding,
        isBeforeOnboarding,
        isFlowWithSystemSettings: false,
        isRevampedOnboardingFlow: true,
    });
    useCloseObsoletePagesWhenSecondWaitingPageIsOpened(step);
    if (!step || step === InstallationStep.firstWaitingStep || step === InstallationStep.secondWaitingStep)
        return <WaitingScreen />;
    switch (step) {
        case InstallationStep.activateStepPreamble:
            return (
                <ActivationPreamble {...{ isDevStackMode, userId, deviceId, isOnboarding, isBeforeOnboarding, setStep }} />
            );
        case InstallationStep.activateStep:
            return <Activation />;
        case InstallationStep.authorizeWithBannerStep:
            return <AuthorizationWithBanner {...{ step, setStep, userId, deviceId, isDevStackMode }} />;
        case InstallationStep.authorizeStep:
            return <AuthorizationFallback />;
        case InstallationStep.missingAllWebsitesAuthorization:
            return <MissingAllWebsitesAuthorization />;
        case InstallationStep.missingAllWebsitesAuthorizationAfterRetry:
            return <MissingAllWebsitesAuthorizationAfterRetry {...{ userId, deviceId, isDevStackMode }} />;
        case InstallationStep.conclusionStep:
            return <Conclusion {...{ userId, deviceId }} />;
        default:
            throw new Error(`Invalid step: ${step}`);
    }
}

function WaitingScreen() {
    return (
        <View style={styles.containerWaitingScreen}>
            <ActivityIndicator size="large" color={color.alto} />
        </View>
    );
}

function ActivationPreamble({
    isDevStackMode,
    userId,
    deviceId,
    isOnboarding,
    isBeforeOnboarding,
    setStep,
}: {
    isDevStackMode: boolean;
    userId: string | undefined;
    deviceId: string | undefined;
    isOnboarding: boolean;
    isBeforeOnboarding: boolean;
    setStep: (step: InstallationStep) => void;
}) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped;
    const [isTosConsentNeeded, setIsTosConsentNeeded] = React.useState<boolean>(isBeforeOnboarding);
    const region = getRegion();
    return (
        <View style={containerStyleWithGap(40)}>
            <Header step={InstallationStep.activateStepPreamble} />
            <View style={styles.containerGreenCircleBackground}>
                <Image
                    source={localizeAsset(widgetIllustrationActivationPreamble)}
                    style={getImageActivationPreambleStyle(region)}
                />
            </View>
            <View style={styles.containerButtons}>
                <TosConsentButton
                    {...{
                        isBeforeOnboarding,
                        isTosConsentNeeded,
                        setIsTosConsentNeeded,
                        isDevStackMode,
                        userId,
                        deviceId,
                    }}
                />
                <View style={{ marginTop: 16 }}>
                    <NewDesignSystemButton
                        onPress={onPressStartActivation({
                            isDevStackMode,
                            userId,
                            deviceId,
                            isOnboarding,
                            isBeforeOnboarding,
                            setStep,
                        })}
                        disabled={isTosConsentNeeded}
                        textStyle={newDesignSystemFontStyles.actionMedium}>
                        {texts.activateStep.preamble.startButton}
                    </NewDesignSystemButton>
                </View>
            </View>
        </View>
    );
}

function Activation() {
    const region = getRegion();
    return (
        <View style={containerStyleWithGap(32)}>
            <Header step={InstallationStep.activateStep} />
            <View style={styles.containerInstructions}>
                <InstructionClickSafariSettings itemNumber={1} />
                <InstructionManageExtensions itemNumber={2} />
                <InstructionActivateJokoExtension itemNumber={3} />
            </View>
            {isOsVersion18OrLater ? (
                <InlineMp4 src={activationAnimationIos18[region]} poster={activationAnimationPlaceholderIos18[region]} />
            ) : (
                <InlineMp4 src={activationAnimation} poster={activationAnimationPlaceholder} />
            )}
        </View>
    );
}

function AuthorizationWithBanner({
    setStep,
    userId,
    deviceId,
    isDevStackMode,
}: {
    setStep: (step: InstallationStep) => void;
    userId?: string;
    deviceId?: string;
    isDevStackMode?: boolean;
}) {
    const region = getRegion();
    return (
        <>
            <Text style={styles.pointingEmoji}>{FINGER_POINTING_UP_EMOJI}</Text>
            <View style={containerStyleWithGap(24)}>
                <Header step={InstallationStep.authorizeWithBannerStep} />
                <View style={styles.containerInstructionsWithMissingBannerButton}>
                    <View style={styles.containerInstructions}>
                        <InstructionClickBanner itemNumber={1} />
                        <InstructionAllowAlways itemNumber={2} />
                        <InstructionAllowOnAllWebsites itemNumber={3} />
                    </View>
                    <MissingVerifyBannerButton {...{ setStep, userId, deviceId, isDevStackMode }} />
                </View>
                <InlineMp4
                    src={authorizationWithBannerAnimation[region]}
                    poster={authorizationWithBannerAnimationPlaceholder[region]}
                />
            </View>
        </>
    );
}

function AuthorizationFallback() {
    const region = getRegion();
    return (
        <View style={containerStyleWithGap(24)}>
            <Header step={InstallationStep.authorizeStep} />
            <View style={styles.containerInstructions}>
                <InstructionClickSafariSettings itemNumber={1} />
                <InstructionClickExtensionWarningIcon itemNumber={2} />
                <InstructionAllowAlways itemNumber={3} />
                <InstructionAllowOnAllWebsites itemNumber={4} />
            </View>
            {isOsVersion18OrLater ? (
                <InlineMp4
                    src={authorizationFallbackAnimationIos18[region]}
                    poster={authorizationFallbackAnimationPlaceholderIos18[region]}
                />
            ) : (
                <InlineMp4 src={authorizationFallbackAnimation} poster={authorizationFallbackAnimationPlaceholder} />
            )}
        </View>
    );
}

function MissingAllWebsitesAuthorization() {
    const region = getRegion();
    return (
        <View style={containerStyleWithGap(24)}>
            <Header step={InstallationStep.missingAllWebsitesAuthorization} />
            <View style={styles.containerSplitInstructions}>
                <View style={styles.containerInstructions}>
                    <InstructionClickSafariSettings itemNumber={1} />
                    <InstructionClickExtensionWarningIcon itemNumber={2} />
                </View>
                <View style={[styles.containerInstructions, { backgroundColor: color.pippin }]}>
                    <InstructionAllowAlways itemNumber={3} />
                    <InstructionAllowOnAllWebsites itemNumber={4} />
                </View>
            </View>
            {isOsVersion18OrLater ? (
                <InlineMp4
                    src={authorizationFallbackAnimationIos18[region]}
                    poster={authorizationFallbackAnimationPlaceholderIos18[region]}
                />
            ) : (
                <InlineMp4 src={authorizationFallbackAnimation} poster={authorizationFallbackAnimationPlaceholder} />
            )}
        </View>
    );
}

function MissingAllWebsitesAuthorizationAfterRetry({
    userId,
    deviceId,
    isDevStackMode,
}: {
    userId: string | undefined;
    deviceId: string | undefined;
    isDevStackMode: boolean;
}) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped;
    return (
        <View style={containerStyleWithGap(24)}>
            <Header step={InstallationStep.missingAllWebsitesAuthorizationAfterRetry} />
            <View style={styles.body}>
                <View style={styles.containerInstructions}>
                    <InstructionSystemSettingsClickButton itemNumber={1} />
                    <InstructionSystemSettingsOtherWebsites itemNumber={2} />
                    <InstructionSystemSettingsAllow itemNumber={3} />
                </View>
                <NewDesignSystemButton
                    onPress={onPressOpenSystemSettings({ userId, deviceId, isDevStackMode })}
                    textStyle={newDesignSystemFontStyles.actionMedium}>
                    {texts.missingAuthorization.systemSettingsButton}
                </NewDesignSystemButton>
            </View>
        </View>
    );
}

function Conclusion({ userId, deviceId }: { userId: string | undefined; deviceId: string | undefined }) {
    const region = getRegion();
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped;
    return (
        <View style={containerStyleWithGap(40)}>
            <Header step={InstallationStep.conclusionStep} />
            <View style={styles.body}>
                <InlineMp4 src={isOsVersion18OrLater ? successAnimationIos18[region] : successAnimation} />
                <NewDesignSystemButton
                    onPress={onPressReturnToApp({ userId, deviceId })}
                    textStyle={newDesignSystemFontStyles.actionMedium}>
                    {texts.conclusion.returnToJokoButton}
                </NewDesignSystemButton>
            </View>
        </View>
    );
}
