import { Size } from '../tokens/measurements/size';

export const HOME_SCREEN_WIDTH = 800;
export const NAVIGATION_BAR_HEIGHT = 72;

type DeviceWidthMeasurementsScale = '500px' | '740px' | '1280px' | '1440px';

type DeviceWidthMeasurements = Partial<Record<Size, DeviceWidthMeasurementsScale>>;

export const deviceWidthMeasurements: DeviceWidthMeasurements = {
    small: '500px',
    medium: '740px',
    large: '1280px',
    xxLarge: '1440px',
};
