import * as React from 'react';
import { StyleSheet, Text, ActivityIndicator, ViewStyle, TextStyle, View } from 'react-native';

import color from '../../style/color';
import { font } from '../../style/text';
import { HoverableTouchableOpacity } from './HoverableComponents';

export interface Props {
    children: string;
    onPress: () => any;
    disabled?: boolean;
    style?: ViewStyle;
    isLoading?: boolean;
    height?: number;
    useSecondaryColor?: boolean;
    textStyle?: TextStyle;
    icon?: JSX.Element;
    activityIndicatorColor?: string;
    disabledColor?: string;
}

export default function Button({
    children,
    onPress,
    disabled = false,
    isLoading = false,
    style = {},
    height = BUTTON_HEIGHT,
    useSecondaryColor = false,
    textStyle,
    icon,
    activityIndicatorColor,
    disabledColor = color.frenchGray,
}: Props) {
    const containerStyles: [{ [key: string]: any }] = [styles.container];
    const textStyles: [{ [key: string]: any }] = [styles.text];
    if (disabled) {
        containerStyles.push({ backgroundColor: disabledColor, borderColor: disabledColor });
        textStyles.push(styles.textDisabled);
    } else if (useSecondaryColor) {
        containerStyles.push(styles.containerSecondary);
        textStyles.push(styles.textSecondary);
    }
    if (textStyle) textStyles.push(textStyle);
    return (
        <HoverableTouchableOpacity
            style={[containerStyles, { height, maxHeight: height, borderRadius: height }, style]}
            hoveredStyle={{ opacity: !disabled ? 0.8 : 1 }}
            {...{ disabled, onPress }}
            onPress={onPress}>
            {isLoading ? (
                <ActivityIndicator
                    color={
                        activityIndicatorColor
                            ? activityIndicatorColor
                            : useSecondaryColor
                            ? color.caribbeanGreen
                            : color.white
                    }
                />
            ) : (
                <Text style={textStyles}>{children}</Text>
            )}
            {icon ? <View style={styles.containerIcon}>{icon}</View> : null}
        </HoverableTouchableOpacity>
    );
}

export const BUTTON_HEIGHT = 56;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexGrow: 1,
        borderWidth: 2,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color.black,
        borderColor: color.black,
        outlineStyle: 'none',
    },
    containerSecondary: {
        backgroundColor: color.white,
        borderColor: color.black,
    },
    containerIcon: {
        position: 'absolute',
        left: 25,
    },
    text: {
        fontFamily: font.ambitBlack,
        fontSize: 16,
        color: color.white,
    },
    textSecondary: {
        color: color.black,
    },
    textDisabled: {
        color: color.white,
    },
});
