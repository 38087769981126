import * as React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';
import { useHistory, useLocation } from 'react-router';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import Button from '../../components/common/Button';
import HighlightedText from '../../components/common/HighlightedText';
import { logAmplitudeEvent } from '../../lib/events/amplitudeEvents';
import { IS_USER_LOGGING_FROM_WEB_APP_LOCATION_STATE } from './SignInPage';

const mailIcon = { uri: '/assets/images/icons/mail.svg' };
const appleLogo = { uri: '/assets/images/logos/apple-white.svg' };
const googleLogo = { uri: '/assets/images/logos/google.svg' };
const facebookLogo = { uri: '/assets/images/logos/facebook-white.svg' };

export default function SignUpPage() {
    const texts = getLocalizedTexts().auth.signUp;
    const history = useHistory();
    const isUserLoggingInFromWebApp = useLocation().state === IS_USER_LOGGING_FROM_WEB_APP_LOCATION_STATE;
    return (
        <View style={styles.container}>
            <View style={styles.containerContent}>
                <HighlightedText style={styles.textTitle}>{texts.title}</HighlightedText>
                <Text style={styles.textSubtitle}>{texts.subtitle}</Text>
                <Button
                    onPress={() => {
                        logAmplitudeEvent({ name: 'Registration - Clicked Email Register' });
                        history.push('/auth/sign-up/email');
                    }}
                    textStyle={styles.textButton}
                    height={40}
                    icon={<Image source={mailIcon} style={{ width: 20, height: 20, resizeMode: 'contain' }} />}>
                    {texts.withEmailButton}
                </Button>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={styles.containerDivider} />
                    <Text style={styles.textDivider}>{texts.divider}</Text>
                    <View style={styles.containerDivider} />
                </View>
                <Button
                    onPress={() => {
                        logAmplitudeEvent({ name: 'Registration - Clicked Apple Register' });
                        history.push('/auth/sign-up/apple');
                    }}
                    style={{ backgroundColor: color.black, borderColor: color.black }}
                    textStyle={styles.textButton}
                    height={40}
                    icon={<Image source={appleLogo} style={{ width: 20, height: 20, resizeMode: 'contain' }} />}>
                    {texts.withAppleButton}
                </Button>
                <Button
                    onPress={() => {
                        logAmplitudeEvent({ name: 'Registration - Clicked Google Register' });
                        history.push('/auth/sign-up/google');
                    }}
                    style={{ backgroundColor: color.white, borderColor: color.black, marginTop: 16 }}
                    textStyle={styles.textButtonGoogle}
                    height={40}
                    icon={<Image source={googleLogo} style={{ width: 20, height: 20, resizeMode: 'contain' }} />}>
                    {texts.withGoogleButton}
                </Button>
                <Button
                    onPress={() => {
                        logAmplitudeEvent({ name: 'Registration - Clicked Facebook Register' });
                        history.push('/auth/sign-up/facebook');
                    }}
                    style={{ backgroundColor: color.facebook, borderColor: color.facebook, marginTop: 16 }}
                    textStyle={styles.textButton}
                    height={40}
                    icon={<Image source={facebookLogo} style={{ width: 20, height: 20, resizeMode: 'contain' }} />}>
                    {texts.withFacebookButton}
                </Button>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 8 }}>
                    <View style={styles.containerDivider} />
                </View>
                <Text style={styles.textSignIn}>{texts.signIn.text}</Text>
                <TouchableOpacity
                    onPress={() =>
                        history.push({
                            pathname: '/auth/sign-in',
                            state: isUserLoggingInFromWebApp ? IS_USER_LOGGING_FROM_WEB_APP_LOCATION_STATE : undefined,
                        })
                    }>
                    <Text style={styles.textSignInButton}>{texts.signIn.button}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: color.white,
    },
    containerContent: {
        marginTop: 130,
        width: 380,
    },
    containerDivider: {
        flex: 1,
        marginVertical: 16,
        borderBottomColor: color.frenchGray,
        borderBottomWidth: 1,
    },
    textTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.tundora,
        marginBottom: 16,
    },
    textSubtitle: {
        flex: 1,
        fontFamily: font.ambitBold,
        fontSize: 14,
        color: color.manatee,
        marginBottom: 80,
    },
    textButton: {
        fontSize: 14,
    },
    textButtonGoogle: {
        fontSize: 14,
        color: color.black,
    },
    textDivider: {
        fontFamily: font.ambitBlack,
        fontSize: 13,
        color: color.frenchGray,
        marginHorizontal: 16,
    },
    textSignIn: {
        textAlign: 'center',
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.manatee,
    },
    textSignInButton: {
        textAlign: 'center',
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.caribbeanGreen,
    },
});
