import * as React from 'react';
import { StyleSheet, TouchableOpacity, View, Text, Image } from 'react-native';
import { useHistory } from 'react-router';
import { useReactiveVar } from '@apollo/react-hooks';
import moment from 'moment';

import { getLocalizedTexts } from '../../../Locales';
import { font } from '../../../style/text';
import color from '../../../style/color';
import { hasPressedRedeemRewardVar, lastUserAttributeVerificationActionAtVar } from '../../../api/graphql/reactiveVariables';
import { Gender } from '../../../api/graphql/fragments/user';
import { logAmplitudeEvent } from '../../../lib/events/amplitudeEvents';
import { getEmailAndPhoneNumberStatuses } from '../../../lib/common/cognito';
import { useHandleIntercomWidgetDisplay } from '../../../lib/common/intercom';
import { getUserTrackingConsents } from '../../../lib/common/trackingConsents';
import { useUserInfo } from '../../../lib/common/userInfo';
import { HoverableTouchableOpacity } from '../../../components/common/HoverableComponents';
import TrackingPersonalizationModal from '../../../components/common/TrackingPersonalizationModal';

const chevronRightIcon = { uri: '/assets/images/icons/chevron-right-gray.svg' };

function UserInfoPage() {
    const texts = getLocalizedTexts().home.profile.userInfo;
    const history = useHistory();
    const [userInfo] = useUserInfo();
    const [cognitoEmailVerified, cognitoPhoneNumberVerified] = useCognitoVerifiedAttributes();
    const hasPressedRedeemReward = useReactiveVar(hasPressedRedeemRewardVar);
    const [isTrackingPersonalizationModalVisible, setIsTrackingPersonalizationModalVisible] = React.useState(false);
    useHandleIntercomWidgetDisplay();
    const userInfoLinePropsList: UserInfoLineProps[] = [
        {
            label: texts.mainSection.email,
            value: userInfo.email,
            onPressEdit: () => {
                logAmplitudeEvent({ name: 'Settings - Clicked Change Email' });
                history.push('/home/profile/user-info/email');
            },
            verified: cognitoEmailVerified,
            verificationLabel: cognitoEmailVerified !== false ? undefined : texts.mainSection.verify.email,
            onPressVerify: () => history.push('/home/profile/user-info/email/verification'),
        },
        {
            label: texts.mainSection.firstName,
            value: userInfo.firstName,
        },
        {
            label: texts.mainSection.gender,
            value: userInfo.gender !== Gender.Other ? userInfo.gender : texts.mainSection.unspecifiedGender,
        },
    ];
    if (userInfo.birthDate)
        userInfoLinePropsList.push({
            label: texts.mainSection.birthDate,
            value: moment(userInfo.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        });
    else
        userInfoLinePropsList.push({
            label: texts.mainSection.age,
            value: userInfo.age,
        });
    if (userInfo.phoneNumber || hasPressedRedeemReward)
        userInfoLinePropsList.push({
            label: texts.mainSection.phoneNumber,
            value: userInfo.phoneNumber?.length ? userInfo.phoneNumber : texts.mainSection.addPhoneNumber,
            highlightValue: !userInfo.phoneNumber?.length,
            onPressEdit: () => history.push('/home/profile/user-info/phone-number'),
            verified: cognitoPhoneNumberVerified,
            verificationLabel:
                !userInfo.phoneNumber?.length || cognitoPhoneNumberVerified !== false
                    ? undefined
                    : texts.mainSection.verify.phoneNumber,
            onPressVerify: () => history.push('/home/profile/user-info/phone-number/verification'),
        });
    if (isTrackingPersonalizationModalVisible)
        return (
            <TrackingPersonalizationModal
                {...{
                    trackingConsents: getUserTrackingConsents(),
                    setIsModalVisible: setIsTrackingPersonalizationModalVisible,
                }}
            />
        );
    return (
        <View style={styles.container}>
            <Text style={styles.textTitle}>{texts.title}</Text>
            <View style={styles.containerSection}>
                <Text style={styles.textSectionTitle}>{texts.mainSection.title}</Text>
                {userInfoLinePropsList.map((props, index) => (
                    <UserInfoLine
                        key={`user-info-line-${index}`}
                        {...props}
                        isLast={index === userInfoLinePropsList.length - 1}
                    />
                ))}
            </View>
            <HoverableTouchableOpacity
                onPress={() => setIsTrackingPersonalizationModalVisible(true)}
                activeOpacity={0.8}
                hoveredStyle={{ opacity: 0.7 }}>
                <Text style={styles.textPersonalizeTrackingConsents}>{texts.personalizeTrackingConsents}</Text>
            </HoverableTouchableOpacity>
        </View>
    );
}

export default UserInfoPage;

function useCognitoVerifiedAttributes(): [boolean | undefined, boolean | undefined] {
    const [cognitoEmailVerified, setCognitoEmailVerified] = React.useState<boolean | undefined>();
    const [cognitoPhoneNumberVerified, setCognitoPhoneNumberVerified] = React.useState<boolean | undefined>();
    const setCognitoVerifiedAttributes = async () => {
        const { emailVerified, phoneNumberVerified } = await getEmailAndPhoneNumberStatuses();
        setCognitoEmailVerified(emailVerified);
        setCognitoPhoneNumberVerified(phoneNumberVerified);
    };
    React.useEffect(() => {
        setCognitoVerifiedAttributes();
    }, []);
    const lastUserAttributeVerificationActionAt = useReactiveVar(lastUserAttributeVerificationActionAtVar);
    React.useEffect(() => {
        if (lastUserAttributeVerificationActionAt) setCognitoVerifiedAttributes();
    }, [lastUserAttributeVerificationActionAt]);
    React.useEffect(() => {
        window.addEventListener('visibilitychange', setCognitoVerifiedAttributes);
        return () => {
            window.removeEventListener('visibilitychange', setCognitoVerifiedAttributes);
        };
    }, []);
    return [cognitoEmailVerified, cognitoPhoneNumberVerified];
}

interface UserInfoLineProps {
    label: string;
    value: string | number | null;
    highlightValue?: boolean;
    onPressEdit?: () => any;
    isLast?: boolean;
    verified?: boolean | undefined;
    verificationLabel?: string;
    onPressVerify?: () => any;
}

function UserInfoLine({
    label,
    value,
    highlightValue,
    onPressEdit,
    isLast,
    verified,
    verificationLabel,
    onPressVerify,
}: UserInfoLineProps) {
    return (
        <HoverableTouchableOpacity
            style={[styles.containerLine, isLast && styles.containerLineLast]}
            hoveredStyle={[onPressEdit && { opacity: 0.7 }]}
            onPress={() => onPressEdit && onPressEdit()}
            disabled={!onPressEdit}
            activeOpacity={0.4}>
            <View style={styles.containerLineTexts}>
                <View style={styles.containerLabel}>
                    <Text style={styles.textLabel}>{label}</Text>
                </View>
                <View style={styles.containerValue}>
                    <Text style={[styles.textValue, highlightValue && styles.textValueHighlighted]} numberOfLines={1}>
                        {value}
                    </Text>
                    {!verified && verificationLabel && onPressVerify ? (
                        <TouchableOpacity style={styles.containerVerificationButton} onPress={() => onPressVerify()}>
                            <Text style={styles.textVerificationButton}>{verificationLabel}</Text>
                        </TouchableOpacity>
                    ) : undefined}
                </View>
            </View>
            {onPressEdit ? (
                <Image source={chevronRightIcon} style={{ width: 16, height: 16, resizeMode: 'contain' }} />
            ) : null}
        </HoverableTouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        paddingTop: 48,
        paddingHorizontal: 64,
    },
    containerSection: {
        marginVertical: 16,
        padding: 32,
        borderWidth: 1,
        borderColor: color.mercury,
        borderRadius: 8,
    },
    containerLine: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderBottomColor: color.mercury,
        paddingVertical: 15,
    },
    containerLineLast: {
        borderBottomWidth: 0,
        paddingBottom: 0,
    },
    containerLineTexts: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    containerLabel: {
        width: 225,
    },
    containerValue: {
        justifyContent: 'flex-start',
    },
    containerVerificationButton: {
        marginTop: 5,
    },
    textTitle: {
        fontSize: 20,
        fontFamily: font.ambitBlack,
        color: color.black,
    },
    textSectionTitle: {
        fontSize: 16,
        fontFamily: font.ambitBlack,
        color: color.black,
        marginBottom: 8,
    },
    textLabel: {
        fontSize: 15,
        fontFamily: font.ambitSemiBold,
        color: color.manatee,
    },
    textValue: {
        fontSize: 15,
        fontFamily: font.ambitSemiBold,
        color: color.black,
    },
    textPersonalizeTrackingConsents: {
        fontSize: 15,
        fontFamily: font.ambitBold,
        color: color.emerald,
    },
    textValueHighlighted: {
        fontFamily: font.ambitBold,
        color: color.emerald,
    },
    textVerificationButton: {
        fontFamily: font.ambitBold,
        fontSize: 10,
        color: color.emerald,
    },
});
