import * as React from 'react';
import { Image, Text, TouchableOpacity, View, TextStyle } from 'react-native';

import { default as ButtonCommon, Props as ButtonProps } from '../../../components/common/Button';
import color from '../../../style/color';
import {
    InstallationStep,
    setMobileSafariExtensionTermsOfServiceAndPrivacyPolicyConsentInLocalStorage,
} from '../../../lib/mobileSafariExtension/mobileSafariExtension';
import { getLocalizedTexts } from '../../../Locales';
import { logAmplitudeEventWithoutAuthentication } from '../../../lib/events/amplitudeEvents';
import HighlightedText from '../../common/HighlightedText';
import { newDesignSystemFontStyles, normalizeHeight, styles } from './Styles';
import { logEventWithoutAuthentication } from '../../../api/rest/events';
import { redirectToPrivacyPolicy, redirectToTermsOfService, returnToApp, returnToSystemSettings } from './RedirectLinks';

const checkMarkIcon = { uri: '/assets/images/icons/tick.svg' };

export function NewDesignSystemButton(props: ButtonProps) {
    return (
        <ButtonCommon height={52} disabledColor={color.boulder} {...props}>
            {props.children}
        </ButtonCommon>
    );
}

export function TosConsentButton({
    isBeforeOnboarding,
    isTosConsentNeeded,
    setIsTosConsentNeeded,
    isDevStackMode,
    userId,
    deviceId,
}: {
    isBeforeOnboarding: boolean;
    isTosConsentNeeded: boolean;
    setIsTosConsentNeeded: React.Dispatch<React.SetStateAction<boolean>>;
    isDevStackMode: boolean;
    userId?: string;
    deviceId?: string;
}) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped.activateStep.preamble;
    if (!isBeforeOnboarding) return null;
    return (
        <TouchableOpacity
            style={styles.containerTosConsentButton}
            onPress={onPressTosConsentButton({ setIsTosConsentNeeded, isDevStackMode, userId, deviceId })}
            accessibilityRole="checkbox"
            activeOpacity={0.7}>
            <View
                style={[
                    styles.containerCheckMark,
                    isTosConsentNeeded ? styles.containerCheckMarkUnselected : styles.containerCheckMarkSelected,
                ]}>
                <Image source={checkMarkIcon} style={styles.imageCheckMarkIcon} />
            </View>
            <HighlightedText
                style={newDesignSystemFontStyles.paragraphMedium}
                highlightedStyle={[
                    newDesignSystemFontStyles.actionMediumUnderline,
                    { textUnderlineOffset: normalizeHeight(4) } as TextStyle,
                ]}
                onPressHighlightedTexts={[redirectToTermsOfService, redirectToPrivacyPolicy]}>
                {texts.tosConsent}
            </HighlightedText>
        </TouchableOpacity>
    );
}

export function MissingVerifyBannerButton({
    setStep,
    userId,
    deviceId,
    isDevStackMode,
}: {
    setStep: (step: InstallationStep) => void;
    userId?: string;
    deviceId?: string;
    isDevStackMode?: boolean;
}) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPagesRevamped.authorizeStep;
    return (
        <TouchableOpacity
            onPress={onPressMissingAuthorizationBannerButton({ setStep, userId, deviceId, isDevStackMode })}
            activeOpacity={0.7}
            style={styles.containerMissingBannerButton}>
            <Text style={newDesignSystemFontStyles.actionSmall}>{texts.missingBannerButton}</Text>
        </TouchableOpacity>
    );
}

export function onPressStartActivation({
    isDevStackMode,
    userId,
    deviceId,
    isOnboarding,
    isBeforeOnboarding,
    setStep,
}: {
    isDevStackMode: boolean;
    userId: string | undefined;
    deviceId: string | undefined;
    isOnboarding: boolean;
    isBeforeOnboarding: boolean;
    setStep: (step: InstallationStep) => void;
}) {
    return () => {
        setStep(InstallationStep.activateStep);
        if (userId)
            logEventWithoutAuthentication(
                userId,
                {
                    type: 'clickedStartActivationMobileSafariExtensionInstallationFlow',
                    payload: { isOnboarding, isBeforeOnboarding, isRevampedOnboardingFlow: true },
                },
                isDevStackMode
            );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Start Activation',
                properties: { isOnboarding, isBeforeOnboarding, isRevampedOnboardingFlow: true },
            },
            { userId, deviceId }
        );
    };
}

export function onPressOpenSystemSettings({
    userId,
    deviceId,
    isDevStackMode,
}: {
    userId: string | undefined;
    deviceId: string | undefined;
    isDevStackMode: boolean;
}) {
    return () => {
        if (userId)
            logEventWithoutAuthentication(
                userId,
                {
                    type: 'clickedOpenSystemSettingsMobileSafariExtensionInstallationFlow',
                    payload: { isRevampedOnboardingFlow: true },
                },
                isDevStackMode
            );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Open System Settings',
                properties: { isRevampedOnboardingFlow: true },
            },
            { userId, deviceId }
        );
        returnToSystemSettings();
    };
}

export function onPressReturnToApp({ userId, deviceId }: { userId: string | undefined; deviceId: string | undefined }) {
    return () => {
        const payload = { installationStep: InstallationStep.conclusionStep, isRevampedOnboardingFlow: true };
        logEventWithoutAuthentication(userId, { type: 'clickedReturnToAppFromMobileSafariExtension', payload });
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Return To App',
                properties: payload,
            },
            { userId, deviceId }
        );
        returnToApp();
    };
}

function onPressTosConsentButton({
    setIsTosConsentNeeded,
    isDevStackMode,
    userId,
    deviceId,
}: {
    setIsTosConsentNeeded: React.Dispatch<React.SetStateAction<boolean>>;
    isDevStackMode: boolean;
    userId?: string;
    deviceId?: string;
}) {
    return () => {
        // We store the consent in the local storage so that it can be accessed by the mobile Safari extension's content script
        // We also store the associated 'isDevStackMode' flag so that it can be accessed and used by the mobile Safari extension
        setMobileSafariExtensionTermsOfServiceAndPrivacyPolicyConsentInLocalStorage(isDevStackMode);
        setIsTosConsentNeeded(false);
        logAmplitudeEventWithoutAuthentication(
            { name: 'Mobile Safari Extension - Clicked Consent ToS And PP', properties: { isRevampedOnboardingFlow: true } },
            { userId, deviceId }
        );
    };
}

function onPressMissingAuthorizationBannerButton({
    setStep,
    userId,
    deviceId,
    isDevStackMode,
}: {
    setStep: (step: InstallationStep) => void;
    userId?: string;
    deviceId?: string;
    isDevStackMode?: boolean;
}) {
    return () => {
        setStep(InstallationStep.authorizeStep);
        if (userId)
            logEventWithoutAuthentication(
                userId,
                {
                    type: 'clickedMissingMobileSafariExtensionAuthorizationBannerButton',
                    payload: { isRevampedOnboardingFlow: true },
                },
                isDevStackMode
            );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Missing Authorization Banner Button',
                properties: { isRevampedOnboardingFlow: true },
            },
            { userId, deviceId }
        );
    };
}
