import { css } from 'styled-components';

/** This css mixin prevents a default "long press" behavior for all images on iOS Safari allowing users to drag them.
 * See this recording: https://www.notion.so/joko/Desktop-Extension-cf98fcd8469445afbe8e85f3d8867724?pvs=4#23433e79263f4f46a0620328f0e6a981
 */
export const NON_HIGHLIGHTABLE_NOR_DRAGGABLE_ELEMENT_CSS_MIXIN = css`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -html-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    user-drag: none;
`;

export const CLICKABLE_ELEMENT_CSS_MIXIN = css`
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    ${NON_HIGHLIGHTABLE_NOR_DRAGGABLE_ELEMENT_CSS_MIXIN}
`;

export const NO_SCROLLBAR_CSS_MIXIN = css`
    scrollbar-width: none; /* This is for Firefox */
    &::-webkit-scrollbar {
        display: none; /* This is for webkit-based browsers: Safari and Chrome */
    }
`;
