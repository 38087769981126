import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { useWindowSize } from '../../style/utils';
import { getRestApiUrl } from '../../api/config';
import { getSocialIdentityCredentials, Provider, GetSocialIdentityCredentialsResponse } from '../../api/rest/user';
import { logAmplitudeEvent } from '../../lib/events/amplitudeEvents';
import LoadingFrame from '../../components/auth/LoadingFrame';
import IntroPanel from './IntroPanel';
import SocialSignUpPage from './SocialSignUpPage';

const { v1: uuidv1 } = require('uuid');

const FACEBOOK_OAUTH_URL = 'https://www.facebook.com/v4.0/dialog/oauth';
const FACEBOOK_APP_ID = '223366131775158';

const LOCALHOST_REDIRECT_URL = 'https://joko.com/apple-login';

function FacebookAuthPage() {
    const texts = getLocalizedTexts().auth.signIn.withFacebook;
    const size = useWindowSize();
    const [signUpData, errorMessage] = useHandleRedirection();
    if (!signUpData)
        return (
            <View style={styles.container}>
                <LoadingFrame
                    text={errorMessage || texts.loading}
                    height={(size.height || 0) - 120}
                    width={(size.width || 0) - 240}
                    isError={!!errorMessage}
                />
            </View>
        );
    else
        return (
            <View style={styles.containerSignUp}>
                <View style={styles.containerSignUpColumn}>
                    <IntroPanel />
                </View>
                <View style={styles.containerSignUpColumn}>
                    <SocialSignUpPage {...signUpData} />
                </View>
            </View>
        );
}

export default FacebookAuthPage;

function useHandleRedirection(): [SignUpData | undefined, string | undefined] {
    const location = useLocation();
    const redirectUri =
        window.location.host === 'localhost:3000'
            ? LOCALHOST_REDIRECT_URL
            : `https://${window.location.host}${location.pathname}`;
    const [handleOAuthResponse, signUpData, errorMessage] = useHandleOAuthResponse(redirectUri);
    React.useEffect(() => {
        const match = location.search?.match(`code=(.+)$`);
        if (match && match[1]) {
            const code = match[1];
            handleOAuthResponse(code);
        } else {
            if (window.location.host === 'localhost:3000')
                alert(
                    `In order to test Facebook sign-in in Localhost, replace "${LOCALHOST_REDIRECT_URL}" with this page URL`
                );
            const facebookState = uuidv1();
            window.open(
                `${FACEBOOK_OAUTH_URL}` +
                    `?client_id=${FACEBOOK_APP_ID}` +
                    `&redirect_uri=${redirectUri}` +
                    `&state=${facebookState}` +
                    `&scope=public_profile,email`,
                window.location.host === 'localhost:3000' ? undefined : '_self'
            );
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return [signUpData, errorMessage];
}

interface SignUpData {
    initialEmail: string;
    credentials: GetSocialIdentityCredentialsResponse;
}

function useHandleOAuthResponse(redirectUri: string): [(code: string) => void, SignUpData | undefined, string | undefined] {
    const texts = getLocalizedTexts().auth.signIn.withFacebook;
    const history = useHistory();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [signUpData, setSignUpData] = React.useState<SignUpData | undefined>(undefined);
    const handleOAuthResponse = async (code: string) => {
        try {
            const accessToken: string = await exchangeFacebookCode(code, redirectUri);
            const facebookUserData: FacebookUserData = await fetchFacebookUserData(accessToken);
            const credentials = await getSocialIdentityCredentials({
                provider: Provider.Facebook,
                providerUserId: facebookUserData.id,
                email: facebookUserData.email,
                secretToken: accessToken,
            });
            if (!credentials.new) await signIn(credentials);
            else setSignUpData({ initialEmail: facebookUserData.email, credentials });
        } catch (error) {
            setErrorMessage(texts.error.default);
            logAmplitudeEvent({ name: 'Registration - Facebook Login Failed' });
            setTimeout(() => history.push('/auth'), 1000);
        }
    };
    return [handleOAuthResponse, signUpData, errorMessage];
}

async function exchangeFacebookCode(code: string, redirectUri: string): Promise<string> {
    try {
        console.log('Facebook: exchanging user code for token...');
        const headers: Headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        const url =
            `${getRestApiUrl()}user/exchange-fb-code` +
            `?facebookAppId=${FACEBOOK_APP_ID}` +
            `&redirectUrl=${redirectUri}` +
            `&code=${code}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });
        const responseJSON = await response.json();
        console.log('Facebook: user code exchanged');
        return responseJSON.access_token;
    } catch (error) {
        throw new FacebookLoginError(error);
    }
}

class FacebookLoginError extends Error {
    __proto__: FacebookLoginError;
    message: string;
    constructor(message: string) {
        super(`Facebook login (${message})`);
        this.__proto__ = FacebookLoginError.prototype;
        this.message = message;
    }
}

interface FacebookUserData {
    id: string;
    email: string;
    first_name: string;
}

async function fetchFacebookUserData(accessToken: string): Promise<FacebookUserData> {
    console.log('Facebook: fetching user data...');
    const userData: FacebookUserData = await fetch(
        `https://graph.facebook.com/me?access_token=${accessToken}&fields=email,id,first_name`
    ).then((response) => response.json());
    console.log('Facebook: user data fetched');
    return userData;
}

async function signIn(credentials: GetSocialIdentityCredentialsResponse) {
    Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
    await Auth.signIn(credentials.userId, credentials.secretKey);
    logAmplitudeEvent({ name: 'Registration - Facebook Login Succeeded' });
    window.location.reload();
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 60,
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: color.white,
    },
    containerSignUp: {
        flex: 1,
        flexDirection: 'row',
    },
    containerSignUpColumn: {
        flex: 1,
    },
});
