import gql from 'graphql-tag';

import { userInAppContentFragment, UserInAppContent } from '../fragments/inAppContents';

export const userInAppContentsQuery = gql`
    query UserInAppContentQuery($devMode: Boolean, $filterWebContents: Boolean) {
        user {
            inAppContents(devMode: $devMode, filterWebContents: $filterWebContents) {
                items {
                    ...UserInAppContent
                }
                nextToken
            }
        }
    }
    ${userInAppContentFragment}
`;

interface User {
    __typename: 'User';
    inAppContents: {
        items: UserInAppContent[] | null;
        nextToken: string | null;
    };
}

export interface UserInAppContentQueryResponse {
    __typename: 'Query';
    user: User;
}

export interface UserInAppContentQueryVariables {
    devMode?: boolean;
    filterWebContents?: boolean;
}
