/* The pre-registration user seed is a stringified float between 0 and 1, shared in order to synchronize AB test treatment groups between the app and MSE for users who may not be logged in yet.
The seed is kept in persistent storage to ensure consistent experience from one session to the next.
Since the web app serves instructional pages as part of the MSE onboarding flow but cannot access the seed directly, either the mobile app or the MSE background script must pass the seed to these pages as a URL parameter.
These same functions used to assign a user to a treatment group based on their seed can be found in the `chrome-extension`. */

import { SettingKey } from '../../api/graphql/fragments/settings';
import { getPublicSetting } from '../../api/rest/settings';

const md5 = require('md5');

export enum PreRegistrationTwoGroupAbTestSettingsKey {
    shouldUseRevampedMobileSafariExtensionInstallationFlowGroupProportion = 'shouldUseRevampedMobileSafariExtensionInstallationFlowTestGroupProportion',
    shouldShowBannerInRevampedMobileSafariExtensionInstallationFlowTestGroupProportion = 'shouldShowBannerInRevampedMobileSafariExtensionInstallationFlowTestGroupProportion',
}

export function parseUserSeed(userSeed: string): number | undefined {
    try {
        const parsedUserSeed = Number(userSeed);
        validateParsedUserSeed(parsedUserSeed); // some extra error checking to be safe, since the user seed can potentially influence some very important things (like the whole MSE onboarding flow)
        return parsedUserSeed;
    } catch (error) {
        console.debug('Failed to parse user seed:', error);
    }
    return undefined;
}

function validateParsedUserSeed(parsedUserSeed: number) {
    if (!isValidProbability(parsedUserSeed)) throw new RangeError(`Invalid value: ${parsedUserSeed}`);
    if (parsedUserSeed === 0)
        throw new RangeError(
            'It is highly unlikely that the user seed is 0; it was probably specified wrongly or not at all.'
        );
}

/**
 * We map the user to a quantile (independently for each testName) based on their seed, and return whether that quantile is part of the test group
 * */
export async function getDoesBelongToTestGroupPreRegistration(
    userSeed: number,
    testName: PreRegistrationTwoGroupAbTestSettingsKey
): Promise<boolean> {
    const settings = await getPublicSetting<Record<PreRegistrationTwoGroupAbTestSettingsKey, number>>(
        SettingKey.preRegistrationTwoGroupAbTestSettings
    );
    if (!settings) return false;
    try {
        const testGroupProportion = settings[testName];
        if (testGroupProportion === undefined || !isValidProbability(testGroupProportion))
            throw new RangeError(`Setting ${testName} should be a valid probability but is ${testGroupProportion}`);
        return getHashQuantile(`${userSeed}:${testName}`) < testGroupProportion;
    } catch (error) {
        console.debug('Failed to handle pre-registration AB Test settings:', error);
        return false;
    }
}

const MD5_HASH_LENGTH = 32;

/**
 * Calculates the quantile of a string's MD5 hash within the full range of MD5 values, returning a uniform distribution value between 0 and 1.
 */
export function getHashQuantile(str: string) {
    const hashedHexadecimalString = md5(str);
    const maximumHashedHexadecimalString = 'f'.repeat(MD5_HASH_LENGTH);
    const standardUniformDistributionHashedValue =
        parseInt(hashedHexadecimalString, 16) / parseInt(maximumHashedHexadecimalString, 16);
    return standardUniformDistributionHashedValue;
}

export function isValidProbability(value: number): boolean {
    return !isNaN(value) && value >= 0 && value <= 1;
}
