import * as React from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';

import { getLocalizedTexts, formatCurrencyAmount, formatPercentageAmount } from '../../../Locales';
import { MerchantOffer } from '../../../api/graphql/fragments/offers';
import { EnrichedMerchantOffer } from '../../../api/graphql/fragments/enrichedOffers';
import { PartialMerchantOffer } from '../../../api/rest/offers';
import color from '../../../style/color';
import { font } from '../../../style/text';
import { containerStyles } from '../../../style/container';
import {
    getOfferSingleCashbackRate,
    getOfferMultipleCashbackRates,
    RateType,
    ComputationType,
} from '../../../lib/offers/offers';
import StrikeThroughContainer from '../../common/StrikeThroughContainer';
import { mobileOperatingSystem, MobileOperatingSystem } from '../../../lib/common/browser';

const currencySymbolGreen = { uri: '/assets/images/visualIdentity/currency-symbol-green-no-circle.svg' };
const currencySymbolPurple = { uri: '/assets/images/visualIdentity/currency-symbol-purple-no-circle.svg' };
const currencySymbolGray = { uri: '/assets/images/visualIdentity/currency-symbol-gray-no-circle.svg' };

const isOnAndroid = mobileOperatingSystem === MobileOperatingSystem.android;

export default function OfferCashback({
    offer,
    shouldUseValueMinCondition,
}: {
    offer: MerchantOffer | EnrichedMerchantOffer | PartialMerchantOffer;
    shouldUseValueMinCondition?: boolean;
}) {
    const texts = getLocalizedTexts().home.offers.offerComponent;
    const mainCashbackRate = React.useMemo(() => {
        const multipleCashbackRates = getOfferMultipleCashbackRates(offer, false);
        return multipleCashbackRates.length > 0
            ? multipleCashbackRates[0]
            : getOfferSingleCashbackRate(offer, !!shouldUseValueMinCondition);
    }, [offer, shouldUseValueMinCondition]);
    const nonBoostedCashback =
        mainCashbackRate.computationType === ComputationType.variable
            ? formatPercentageAmount(mainCashbackRate.nonBoostedCashback)
            : formatCurrencyAmount(mainCashbackRate.nonBoostedCashback);
    const boostedCashback =
        mainCashbackRate.computationType === ComputationType.variable
            ? formatPercentageAmount(mainCashbackRate.boostedCashback)
            : formatCurrencyAmount(mainCashbackRate.boostedCashback);
    return (
        <View style={styles.containerCashback}>
            {!mainCashbackRate.boostedCashback ? (
                <>
                    {mainCashbackRate.rateType === RateType.multiple ? (
                        <Text style={[styles.textCashbackDescription, { marginRight: 3 }]}>
                            {texts.multipleRates.rateUpTo}
                        </Text>
                    ) : null}
                    <Image
                        source={currencySymbolGreen}
                        style={[styles.imageCurrency, isOnAndroid && styles.imageCurrencyAndroid]}
                    />
                    <Text style={styles.textCashback}>{nonBoostedCashback}</Text>
                </>
            ) : mainCashbackRate.rateType === RateType.single ? (
                <View style={{ flexDirection: 'row' }}>
                    <NonBoostedCashback {...{ nonBoostedCashback }} />
                    <BoostedCashback {...{ boostedCashback }} />
                </View>
            ) : (
                <>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={[styles.textCashbackDescription, { marginRight: 3 }]}>
                            {texts.multipleRates.rateUpTo}
                        </Text>
                        <NonBoostedCashback {...{ nonBoostedCashback }} />
                    </View>
                    <BoostedCashback {...{ boostedCashback }} />
                </>
            )}
            <Text style={styles.textCashbackDescription}>{texts.cashbackDescription}</Text>
            <Text style={styles.textCashbackDescription}>{mainCashbackRate.valueMinText}</Text>
        </View>
    );
}

function NonBoostedCashback({ nonBoostedCashback }: { nonBoostedCashback: string }) {
    return (
        <StrikeThroughContainer
            style={styles.containerNonBoostedCashback}
            lineWidth={2}
            lineColor={color.osloGray}
            adjustLineHeight={1}>
            <View style={containerStyles.containerRowLeftCenter}>
                <Image
                    source={currencySymbolGray}
                    style={[styles.imageCurrency, isOnAndroid && styles.imageCurrencyAndroid]}
                />
                <Text style={styles.textNonBoostedCashback}>{nonBoostedCashback}</Text>
            </View>
        </StrikeThroughContainer>
    );
}

function BoostedCashback({ boostedCashback }: { boostedCashback: string }) {
    return (
        <View style={styles.containerBoostedCashback}>
            <Image
                source={currencySymbolPurple}
                style={[styles.imageCurrency, isOnAndroid && styles.imageCurrencyAndroid]}
            />
            <Text style={styles.textBoostedCashback}>{boostedCashback}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    containerCashback: {
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    containerNonBoostedCashback: {
        marginRight: 4,
    },
    containerBoostedCashback: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    textCashback: {
        marginLeft: 2,
        marginRight: 3,
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.emerald,
    },
    textBoostedCashback: {
        marginLeft: 2,
        marginRight: 3,
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.electricViolet,
    },
    textNonBoostedCashback: {
        marginLeft: 2,
        fontFamily: font.ambitBold,
        fontSize: 14,
        color: color.osloGray,
    },
    textCashbackDescription: {
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.osloGray,
    },
    imageCurrency: {
        height: 12,
        width: 12,
        resizeMode: 'contain',
    },
    imageCurrencyAndroid: {
        bottom: 1,
    },
});
