import { MerchantOffer } from '../graphql/fragments/offers';
import { RestApiUrl } from '../config';
import { logEventWithoutAuthentication } from './events';
import { logAmplitudeEventWithoutAuthentication } from '../../lib/events/amplitudeEvents';
import { getDevStackMode } from '../../lib/common/devStackMode';

export type PartialMerchantOffer = Pick<
    MerchantOffer,
    | 'offerId'
    | 'merchantId'
    | 'card'
    | 'points'
    | 'fixedPoints'
    | 'nonBoostedPoints'
    | 'nonBoostedFixedPoints'
    | 'multipleRatesForDisplay'
    | 'conditions'
> & { __typename: 'PartialMerchantOffer' };

export type OnlineOfferActivationInfo = {
    partialOffer: PartialMerchantOffer;
    personalizedAffiliateLink: string | undefined;
    hasRedirectUrl: boolean;
    addRedirectUrlFailed: boolean;
    parametersRemovedFromRedirectUrl: string[];
    hasAcknowledgedWarningForCookieTracking: boolean;
};

export async function fetchOnlineOfferActivationInfo(
    userId: string,
    offerId: string,
    shouldUseDevStack?: boolean,
    redirectUrl?: string,
    triggeringEventPlatform?: string
): Promise<OnlineOfferActivationInfo | undefined> {
    console.log(`API call: getting the activation information for offer ${offerId}...`);
    const headers: Headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    const isDevStackMode = shouldUseDevStack ?? getDevStackMode();
    const restApiUrl = isDevStackMode ? RestApiUrl.dev : RestApiUrl.prod;
    const url =
        `${restApiUrl}user/online-offer-activation-info` +
        `?userId=${encodeURIComponent(userId)}&offerId=${encodeURIComponent(offerId)}` +
        `&isMobileSafariExtension=${triggeringEventPlatform === 'mobileSafariExtension' ? 'true' : 'false'}` +
        `&redirectUrl=${redirectUrl ? encodeURIComponent(redirectUrl) : ''}` +
        (triggeringEventPlatform ? `&triggeringEventPlatform=${triggeringEventPlatform}` : '');
    let offerActivationInfo: OnlineOfferActivationInfo | undefined;
    try {
        const offerActivationResponse = await fetch(url, { method: 'GET', headers: headers });
        console.log(`Response ${offerActivationResponse.status} for API call 'get online offer activation info'`);
        if (offerActivationResponse.status === 200) {
            offerActivationInfo = await offerActivationResponse.json();
            const { personalizedAffiliateLink, addRedirectUrlFailed } = offerActivationInfo || {};
            if (offerActivationInfo) offerActivationInfo.partialOffer.__typename = 'PartialMerchantOffer';
            if (personalizedAffiliateLink && addRedirectUrlFailed)
                logAddRedirectUrlToAffiliateLinkFailed({ userId, offerId, isDevStackMode });
        }
    } catch {}
    if (!offerActivationInfo) console.error('Error: could not fetch online offer activation info');
    return offerActivationInfo;
}

async function logAddRedirectUrlToAffiliateLinkFailed({
    userId,
    offerId,
    isDevStackMode,
}: {
    userId: string;
    offerId: string;
    isDevStackMode: boolean;
}) {
    const payload = { offerId };
    await Promise.all([
        logEventWithoutAuthentication(userId, { type: 'addRedirectUrlToAffiliateLinkFailed', payload }, isDevStackMode),
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Offers - Add Redirect URL To Affiliate Link Failed',
                properties: payload,
            },
            { userId }
        ),
    ]);
}
