import * as React from 'react';
import { StyleSheet, View, Text, TextInput } from 'react-native';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/react-hooks';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { getLocalizedTexts } from '../../../Locales';
import color from '../../../style/color';
import { font } from '../../../style/text';
import { getUserId } from '../../../api/rest/user';
import { EmailAddressUnknown } from '../../../api/rest/errors';
import { updateUser } from '../../../api/graphql/mutations/updateUser';
import { CognitoUserAttributeName, getCognitoUserAttribute } from '../../../lib/common/cognito';
import { usePrevious } from '../../../lib/common/utilHooks';
import { logUserEventUtil } from '../../../lib/events/userEvents';
import { logAmplitudeEvent } from '../../../lib/events/amplitudeEvents';
import Button from '../../../components/common/Button';
import { updateUserOnIntercom } from '../../../lib/common/intercom';

function UserEmailPage() {
    const texts = getLocalizedTexts().home.profile.userInfo.emailPage;
    const history = useHistory();
    const [initialEmail, setInitialEmail] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const previousEmail = usePrevious(email);
    const apolloClient = useApolloClient();
    React.useEffect(() => {
        getCognitoUserAttribute(CognitoUserAttributeName.email).then((cognitoEmailAttribute) => {
            if (cognitoEmailAttribute) {
                setInitialEmail(cognitoEmailAttribute.getValue());
                if (!email) setEmail(cognitoEmailAttribute.getValue());
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const [updateEmail, isLoading, error, clearError] = useUpdateEmail();
    const onPress = () => {
        if (isEmailValid(email)) {
            updateEmail(initialEmail, email);
            getUserId(initialEmail).then((userId) => updateUserOnIntercom({ user_id: userId, email }, apolloClient));
        }
    };
    React.useEffect(() => {
        if (email.length !== previousEmail?.length) clearError();
    }, [email, previousEmail, clearError]);
    return (
        <View style={styles.container}>
            <Text style={styles.textTitle}>{texts.title}</Text>
            <EmailInput {...{ email, setEmail, isLoading }} />
            {error ? <ErrorMessage {...{ error }} /> : null}
            <Button
                {...{ onPress, isLoading }}
                style={styles.containerConfirmButton}
                textStyle={styles.textConfirmButton}
                height={40}
                disabled={!isEmailValid(email)}>
                {texts.nextButton}
            </Button>
            <Button
                onPress={() => history.goBack()}
                style={styles.containerCancelButton}
                textStyle={styles.textCancelButton}
                height={40}
                useSecondaryColor>
                {texts.backButton}
            </Button>
        </View>
    );
}

export default UserEmailPage;

enum UpdateEmailError {
    AlreadyExists = 'AlreadyExists',
    Other = 'Other',
}

function useUpdateEmail(): [
    (initialEmail: string, email: string) => Promise<void>,
    boolean,
    UpdateEmailError | undefined,
    () => void
] {
    const history = useHistory();
    const apolloClient = useApolloClient();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<UpdateEmailError | undefined>();
    const updateEmail = async (initialEmail: string, email: string) => {
        if (!isLoading && isEmailValid(email)) {
            if (email === initialEmail) history.goBack();
            else {
                setIsLoading(true);
                try {
                    await getUserId(email);
                    setError(UpdateEmailError.AlreadyExists);
                    setIsLoading(false);
                } catch (error) {
                    if (error instanceof EmailAddressUnknown) {
                        await logUserEventUtil(apolloClient, {
                            type: 'updatedEmail',
                            payload: { oldEmail: initialEmail, newEmail: email },
                        });
                        await logAmplitudeEvent({
                            name: 'Settings - Confirmed Change Email',
                            properties: { oldEmail: initialEmail, newEmail: email },
                        });
                        await new Promise((resolve) => setTimeout(resolve, 5000)); // We want the user to be notified before the actual change
                        await updateUser(apolloClient, { email });
                        const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
                        await Auth.updateUserAttributes(cognitoUser, { email });
                        history.push('/home/profile/user-info/email/verification');
                    } else {
                        setError(UpdateEmailError.Other);
                        setIsLoading(false);
                    }
                }
            }
        }
    };
    const clearError = () => setError(undefined);
    return [updateEmail, isLoading, error, clearError];
}

export function isEmailValid(email: string): boolean {
    const regex =
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    return regex.test(email.toLowerCase());
}

export const EmailInput = ({
    email,
    setEmail,
    isLoading,
}: {
    email: string;
    setEmail: (email: string) => void;
    isLoading: boolean;
}) => {
    const texts = getLocalizedTexts().home.profile.userInfo.emailPage;
    return (
        <View style={[email ? styles.bottomBorder : styles.bottomBorderInactive, styles.containerTextInput]}>
            <TextInput
                value={email}
                style={styles.textInput}
                placeholder={texts.emailPlaceholder}
                autoCorrect={false}
                autoCapitalize={'none'}
                keyboardType={'email-address'}
                returnKeyType="next"
                autoFocus={true}
                editable={!isLoading}
                onChangeText={setEmail}
                placeholderTextColor={color.frenchGray}
            />
        </View>
    );
};

export const ErrorMessage = ({ error }: { error: UpdateEmailError }) => {
    const texts = getLocalizedTexts().home.profile.userInfo.emailPage.error;
    let message: string = '';
    if (error === UpdateEmailError.AlreadyExists) message = texts.alreadyExists;
    else message = texts.default;
    return (
        <View style={styles.containerErrorMessage}>
            <Text style={styles.textErrorMessage}>{message}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: 450,
        borderRadius: 16,
        backgroundColor: 'white',
        overflow: 'hidden',
        padding: 40,
        justifyContent: 'center',
    },
    containerTextInput: {
        flexDirection: 'row',
    },
    containerErrorMessage: {
        backgroundColor: color.pippin,
        paddingVertical: 8,
        paddingHorizontal: 25,
        borderRadius: 10,
        marginTop: 24,
    },
    containerConfirmButton: {
        marginTop: 24,
    },
    containerCancelButton: {
        marginTop: 16,
    },
    textTitle: {
        marginHorizontal: 25,
        marginBottom: 16,
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.black,
        textAlign: 'center',
    },
    textInput: {
        height: 35,
        fontFamily: font.ambitSemiBold,
        color: color.black,
        fontSize: 16,
        flexGrow: 1,
        outlineWidth: 0,
    },
    textErrorMessage: {
        color: color.flamingo,
        fontFamily: font.ambitBold,
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
    textConfirmButton: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.white,
    },
    textCancelButton: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.black,
    },
    bottomBorder: {
        borderBottomWidth: 1,
        borderBottomColor: color.black,
    },
    bottomBorderInactive: {
        borderBottomWidth: 1,
        borderBottomColor: color.frenchGray,
    },
});
