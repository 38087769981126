// To name the colors we use this website: http://chir.ag/projects/name-that-color/

export const whiteIce: string = '#F0FCF3';
export const hintOfGreen: string = '#E1FFE8';
export const snowyMint = '#C6FFD2';
export const springGreen: string = '#0FEC8F';
export const caribbeanGreen: string = '#00e183';
export const malachite: string = '#01B829';
export const emerald: string = '#30DA55';
export const screamingGreen: string = '#50F173';

export const white: string = '#ffffff';
export const alabaster: string = '#F8F8F8';
export const wildSand: string = '#f5f5f5';
export const concrete: string = '#f2f2f2';
export const gallery: string = '#EBEBEB';
export const mercury: string = '#e8e8e8';
export const alto: string = '#cccccc';
export const silver: string = '#bbbbbb';
export const silverChalice: string = '#ADADAD';
export const grayLight: string = '#919191';
export const gray: string = '#858585';
export const doveGray = '#707070';
export const emperor: string = '#535353';
export const tundora: string = '#474747';
export const black: string = '#000000';

export const athensGray = '#F7F8FA';
export const athensGrayDark = '#ECEEF3';
export const athensGrayDarker = '#E2E5EB';
export const ghost = '#C9CCD3';
export const frenchGray = '#B7BCC7';
export const manatee: string = '#8F949D';
export const osloGray: string = '#7F8590';
export const boulder: string = '#747474';

export const rose: string = '#FF0060';
export const radicalRed: string = '#FF396D';
export const mandy: string = '#ed495e';
export const flamingo: string = '#F45741';
export const coral: string = '#FF7F50';
export const deepBlush: string = '#e68080';
export const azalea: string = '#f7cbdf';
export const fairPink: string = '#FFECEC';
export const pippin: string = '#FFE8E0';
export const sauvignon: string = '#FFF4F0';

export const lemonChiffon: string = '#fff8cc';
export const candlelight: string = '#ffd814';
export const peachOrange: string = '#ffd198';
export const scotchMist: string = '#FFF9DC';

export const facebook: string = '#3C5A99';
export const azure: string = '#0076ff';
export const easternBlue: string = '#20ADAD';
export const oysterBay: string = '#D0FFFF';
export const linkWater: string = '#DCE6F2';
export const tranquil: string = '#E8FFFF';
export const linkWaterLight: string = '#F5F8FC';
export const whisper: string = '#F2F3F8';

export const jacksonsPurple: string = '#2A2588';
export const jacksonsPurpleLight: string = '#1B1E80';
export const bilobaFlower: string = '#BB85F0';

export const electricViolet: string = '#8D3FEF';
export const blueChalk: string = '#F0DEFF';
export const magnolia: string = '#F8EFFF';

const color = {
    whiteIce,
    hintOfGreen,
    snowyMint,
    springGreen,
    caribbeanGreen,
    malachite,
    emerald,
    screamingGreen,
    white,
    alabaster,
    wildSand,
    concrete,
    gallery,
    mercury,
    alto,
    silver,
    silverChalice,
    grayLight,
    gray,
    emperor,
    tundora,
    black,
    athensGray,
    athensGrayDark,
    athensGrayDarker,
    ghost,
    frenchGray,
    manatee,
    osloGray,
    doveGray,
    rose,
    radicalRed,
    mandy,
    flamingo,
    coral,
    deepBlush,
    azalea,
    fairPink,
    pippin,
    sauvignon,
    lemonChiffon,
    candlelight,
    peachOrange,
    scotchMist,
    facebook,
    azure,
    easternBlue,
    oysterBay,
    linkWater,
    tranquil,
    linkWaterLight,
    whisper,
    jacksonsPurple,
    jacksonsPurpleLight,
    bilobaFlower,
    electricViolet,
    blueChalk,
    magnolia,
    boulder,
};

export default color;
