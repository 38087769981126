import * as React from 'react';
import { Auth } from 'aws-amplify';
import { useApolloClient } from '@apollo/react-hooks';

import { clearLocalStorageSafe } from './localStorage';
import { logoutUserFromIntercom } from './intercom';
import { deleteUserMutation } from '../../api/graphql/mutations/deleteUser';

export function useSignOut(): [() => void, boolean] {
    const apolloClient = useApolloClient();
    const [isSigningOut, setIsSigningOut] = React.useState(false);
    const signOut = async () => {
        setIsSigningOut(true);
        try {
            await Auth.signOut();
            logoutUserFromIntercom(apolloClient);
            await apolloClient.clearStore();
            clearLocalStorageSafe();
            window.location.reload();
        } catch (error) {
            console.log(error);
            setIsSigningOut(false);
        }
    };
    return [signOut, isSigningOut];
}

export function useDeleteUser(): () => Promise<void> {
    const apolloClient = useApolloClient();
    const [signOut] = useSignOut();
    const deleteUser = async () => {
        try {
            await apolloClient.mutate({ mutation: deleteUserMutation });
            await signOut();
        } catch (error) {
            console.log(error);
        }
    };
    return deleteUser;
}
