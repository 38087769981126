import gql from 'graphql-tag';

import { userStateFragment, UserState } from '../fragments/states';

export const userStatesQuery = gql`
    query StateQuery {
        user {
            states {
                ...UserState
            }
        }
    }
    ${userStateFragment}
`;

interface User {
    __typename: 'User';
    states: UserState[];
}

export const featureAndStateQuery = gql`
    query FeatureAndStateQuery {
        user {
            states {
                ...UserState
            }
        }
        appFeatures {
            items {
                featureId
                condition
            }
        }
    }
    ${userStateFragment}
`;

export interface AppFeature {
    __typename: 'AppFeature';
    featureId: string;
    condition: string;
}

interface AppFeatureList {
    __typename: 'AppFeatureList';
    items: AppFeature[];
}

export interface FeatureAndStateQueryResponse {
    user?: User;
    appFeatures?: AppFeatureList;
}
