import * as React from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import Button from '../../components/common/Button';
import { Browser, browserKind } from '../../lib/common/browser';

const pinPreviewIcon = { uri: '/assets/images/icons/pin-preview.svg' };
const pinIcon = { uri: '/assets/images/icons/pin.svg' };
const puzzleIcon = { uri: '/assets/images/icons/puzzle.svg' };
const confettiAnimation = { uri: '/assets/images/illustrations/mobile-safari-extension-installation/confettiAnimation.gif' };

export default function SignInSucceededPage() {
    const texts = getLocalizedTexts().auth.signIn.signInSucceeded;
    return (
        <View style={styles.container}>
            <BrowserExtensionInstructionsWidget />
            <View style={styles.containerMessage}>
                <Image source={confettiAnimation} style={styles.imageConfetti} />
                <Text style={styles.textTitle}>{texts.title}</Text>
                <Text style={styles.textSubtitle}>{texts.subtitle}</Text>
            </View>
        </View>
    );
}

function BrowserExtensionInstructionsWidget() {
    const [shouldShowBrowserExtensionInstructionsWidget, setShouldShowBrowserExtensionInstructionsWidget] = React.useState(
        browserKind !== Browser.safari
    );
    const onPress = () => setShouldShowBrowserExtensionInstructionsWidget(false);
    const texts = getLocalizedTexts().auth.signIn.signInSucceeded;
    if (!shouldShowBrowserExtensionInstructionsWidget) return null;
    return (
        <View style={styles.containerWidget}>
            <Image source={pinPreviewIcon} style={styles.imagePinPreview} />
            <View style={{ flexDirection: 'column', flex: 1 }}>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.textTitleWidget}>
                        {texts.widget.title}
                        <Image source={puzzleIcon} style={styles.imageWidget} />
                    </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.textSubtitleWidget}>
                        <Text>{texts.widget.subtitle.part1}</Text>
                        <Image source={pinIcon} style={styles.imageWidget} />
                        <Text>{texts.widget.subtitle.part2}</Text>
                    </Text>
                </View>
                <Button {...{ onPress }} style={styles.containerWidgetButton} textStyle={styles.textButtonWidget}>
                    {texts.widget.button}
                </Button>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerMessage: {
        position: 'absolute',
        top: '25%',
        alignItems: 'center',
        backgroundColor: color.white,
        width: 480,
        textAlign: 'center',
    },
    containerWidget: {
        flexDirection: 'row',
        position: 'absolute',
        top: 14,
        right: 16,
        width: 390,
        paddingTop: 16,
        paddingBottom: 14,
        paddingHorizontal: 16,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 12,
        elevation: 5,
        borderRadius: 16,
        backgroundColor: color.white,
    },
    containerWidgetButton: {
        backgroundColor: color.white,
        borderColor: color.black,
        height: 34,
        marginLeft: 1,
    },
    textTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 22,
        color: color.black,
        marginTop: 32,
        marginBottom: 21,
    },
    textSubtitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: 18,
        color: color.gray,
    },
    textTitleWidget: {
        marginLeft: 16,
        fontFamily: font.ambitBold,
        fontSize: 14,
        lineHeight: 20,
        color: color.black,
    },
    textSubtitleWidget: {
        marginBottom: 12,
        marginLeft: 16,
        fontFamily: font.ambitSemiBold,
        fontSize: 12,
        lineHeight: 17,
        color: color.black,
    },
    textButtonWidget: {
        fontSize: 12,
        fontFamily: font.ambitBlack,
        color: color.black,
    },
    imageConfetti: {
        width: 240,
        height: 240,
        resizeMode: 'contain',
    },
    imagePinPreview: {
        width: 125,
        height: 125,
        resizeMode: 'contain',
    },
    imageWidget: {
        width: 16,
        height: 16,
        position: 'relative',
        top: 4,
        marginHorizontal: 4,
        resizeMode: 'contain',
    },
});
