import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity, TextInput, View, Image } from 'react-native';
import { Auth } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { useWindowSize } from '../../style/utils';
import { getUserId } from '../../api/rest/user';
import { EmailAddressUnknown, GeneratedPassword } from '../../api/rest/errors';
import Button from '../../components/common/Button';
import HighlightedText from '../../components/common/HighlightedText';
import { getDevStackMode, toggleDevStackMode } from '../../lib/common/devStackMode';
import { logAmplitudeEvent } from '../../lib/events/amplitudeEvents';

const eyeIcon = { uri: '/assets/images/icons/eye.svg' };
const eyeSlashIcon = { uri: '/assets/images/icons/eye-slash.svg' };
const appleLogo = { uri: '/assets/images/logos/apple.svg' };
const googleLogo = { uri: '/assets/images/logos/google.svg' };
const facebookLogo = { uri: '/assets/images/logos/facebook.svg' };
const jokoLogo = { uri: '/assets/images/logos/logo-with-title.svg' };
const jokoLogoDevMode = { uri: '/assets/images/logos/logo-dev-mode.svg' };

const md5 = require('md5');

export const IS_USER_LOGGING_FROM_WEB_APP_LOCATION_STATE = 'isUserLoggingInFromWebApp';

export default function SignInPage() {
    const texts = getLocalizedTexts().auth.signIn;
    const [mfaRequest, setMfaRequest] = React.useState<CognitoSignInResponse | undefined>(undefined);
    const [mfaError, setMfaError] = React.useState<MfaError | undefined>(undefined);
    const [mfaSucceeded, setMfaSucceeded] = React.useState<boolean>(false);
    const isUserLoggingInFromWebApp = useLocation().state === IS_USER_LOGGING_FROM_WEB_APP_LOCATION_STATE;
    useOnMfaSucceeds(mfaSucceeded, isUserLoggingInFromWebApp);
    return (
        <>
            {mfaRequest ? (
                <MfaVerification
                    {...{ mfaRequest, setMfaError, setMfaSucceeded }}
                    clearMfaRequest={() => setMfaRequest(undefined)}
                />
            ) : (
                <View style={styles.container}>
                    <View style={styles.containerContent}>
                        <JokoLogo />
                        <HighlightedText style={styles.textTitle}>{texts.title}</HighlightedText>
                        <EmailSignInForm
                            {...{ setMfaRequest, mfaError, setMfaSucceeded, isUserLoggingInFromWebApp }}
                            clearMfaError={() => setMfaError(undefined)}
                        />
                        <SocialSignInButtons />
                        <SuggestToSignUpButton {...{ isUserLoggingInFromWebApp }} />
                    </View>
                </View>
            )}
        </>
    );
}

function useOnMfaSucceeds(mfaSucceeded: boolean, isUserLoggingInFromWebApp: boolean) {
    const history = useHistory();
    React.useEffect(() => {
        if (!mfaSucceeded) return;
        if (isUserLoggingInFromWebApp) history.push('/home');
        else history.push('/sign-in-succeeded');
        window.location.reload();
    }, [mfaSucceeded]);
}

function JokoLogo() {
    const devStackMode = getDevStackMode();
    return (
        <View style={styles.containerLogo}>
            <Image
                source={devStackMode ? jokoLogoDevMode : jokoLogo}
                style={[styles.imageLogo, { width: devStackMode ? 150 : 91 }]}
            />
        </View>
    );
}

function EmailSignInForm({
    setMfaRequest,
    mfaError,
    clearMfaError,
    setMfaSucceeded,
    isUserLoggingInFromWebApp,
}: {
    setMfaRequest: (value: CognitoSignInResponse) => void;
    mfaError: MfaError | undefined;
    clearMfaError: () => void;
    setMfaSucceeded: (value: boolean) => void;
    isUserLoggingInFromWebApp: boolean;
}) {
    const texts = getLocalizedTexts().auth.signIn.withEmail;
    const history = useHistory();
    const [signIn, isLoading, error] = useSignInWithEmail(setMfaRequest, setMfaSucceeded);
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const disabled = !email || !password;
    const onPress = () => {
        signIn(email, password);
        clearMfaError();
    };
    return (
        <>
            {!error && !mfaError ? null : <ErrorMessage {...{ error, mfaError }} />}
            <HighlightedText style={styles.textSubtitle}>{texts.subtitle}</HighlightedText>
            <EmailInput {...{ setEmail, isLoading }} />
            <PasswordInput {...{ setPassword, isLoading, onPress }} />
            <Button
                {...{ isLoading, onPress, disabled }}
                style={{
                    backgroundColor: disabled ? color.silverChalice : color.black,
                    borderColor: disabled ? color.silverChalice : color.black,
                    marginTop: 8,
                    marginBottom: 12,
                    height: 44,
                }}
                textStyle={styles.textButton}>
                {texts.button}
            </Button>
            <TouchableOpacity
                style={{ alignItems: 'center' }}
                onPress={() => {
                    history.push({
                        pathname: `/auth/reset-password?email=${encodeURIComponent(email)}`,
                        state: isUserLoggingInFromWebApp ? IS_USER_LOGGING_FROM_WEB_APP_LOCATION_STATE : undefined,
                    });
                    window.location.reload();
                }}>
                <Text style={styles.textResetPasswordButton}>{texts.resetPassword}</Text>
            </TouchableOpacity>
        </>
    );
}

interface CognitoSignInResponse {
    challengeName?: string;
}

enum SignInWithEmailError {
    WrongCredentials = 'WrongCredentials',
    GeneratedPassword = 'GeneratedPassword',
    Other = 'Other',
}

function useSignInWithEmail(
    setMfaRequest: (value: CognitoSignInResponse) => void,
    setMfaSucceeded: (value: boolean) => void
): [(email: string, password: string) => void, boolean, SignInWithEmailError | undefined] {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<SignInWithEmailError | undefined>();
    const signIn = async (email: string, password: string): Promise<void> => {
        if (email === 'switchstack') {
            toggleDevStackMode();
            window.location.reload();
        } else if (email.length > 0 && password.length > 0) {
            if (!isLoading) {
                logAmplitudeEvent({ name: 'Registration - Clicked Email Login' });
                setIsLoading(true);
                Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
                try {
                    const userId = await getUserId(email);
                    const authResponse: CognitoSignInResponse = await Auth.signIn(userId, md5(password));
                    if (!authResponse.challengeName) {
                        // No MFA is required
                        logAmplitudeEvent({ name: 'Registration - Email Login Succeeded' });
                        setMfaSucceeded(true);
                    } else if (authResponse.challengeName === 'CUSTOM_CHALLENGE') {
                        setMfaRequest(authResponse);
                        setIsLoading(false);
                    } else {
                        throw new Error('Unhandled authentication response');
                    }
                } catch (error) {
                    if (error instanceof EmailAddressUnknown || error.code === 'NotAuthorizedException')
                        setError(SignInWithEmailError.WrongCredentials);
                    else if (error instanceof GeneratedPassword) setError(SignInWithEmailError.GeneratedPassword);
                    else setError(SignInWithEmailError.Other);
                    setIsLoading(false);
                }
            }
        }
    };
    return [signIn, isLoading, error];
}

function MfaVerification({
    mfaRequest,
    clearMfaRequest,
    setMfaError,
    setMfaSucceeded,
}: {
    mfaRequest: CognitoSignInResponse;
    clearMfaRequest: () => void;
    setMfaError: (value: MfaError) => void;
    setMfaSucceeded: (value: boolean) => void;
}) {
    const texts = getLocalizedTexts().auth.signIn.withEmail.mfa;
    const size = useWindowSize();
    const [code, setCode] = React.useState<string>('');
    const [verifyMfaCode, isLoading] = useVerifyMfaCode(mfaRequest, clearMfaRequest, setMfaError, setMfaSucceeded);
    const onPress = () => verifyMfaCode(code);
    return (
        <View
            style={{
                position: 'absolute',
                backgroundColor: color.white,
                height: size.height,
                width: size.width,
            }}>
            <View style={styles.containerMfaPage}>
                <View style={styles.containerMfaPageContent}>
                    <JokoLogo />
                    <Text style={styles.textTitleMfa}>{texts.title}</Text>
                    <Text style={styles.textSubtitleMfa}>{texts.subtitle}</Text>
                    <CodeInput {...{ code, setCode, isLoading }} />
                    <View style={styles.containerMfaButton}>
                        <Button
                            {...{ onPress, isLoading }}
                            textStyle={styles.textButton}
                            height={44}
                            disabled={code.length === 0}>
                            {texts.button}
                        </Button>
                    </View>
                </View>
            </View>
        </View>
    );
}

enum MfaError {
    WrongCode = 'WrongCode',
    Other = 'Other',
}

function useVerifyMfaCode(
    mfaRequest: CognitoSignInResponse,
    clearMfaRequest: () => void,
    setMfaError: (value: MfaError) => void,
    setMfaSucceeded: (value: boolean) => void
): [(code: string) => void, boolean] {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const verifyMfaCode = async (code: string) => {
        if (!isLoading) {
            setIsLoading(true);
            try {
                await Auth.sendCustomChallengeAnswer(mfaRequest, code);
                logAmplitudeEvent({ name: 'Registration - Email Login Succeeded' });
                setMfaSucceeded(true);
                clearMfaRequest();
            } catch (error) {
                setIsLoading(false);
                clearMfaRequest();
                if (error.code === 'NotAuthorizedException') setMfaError(MfaError.WrongCode);
                else setMfaError(MfaError.Other);
            }
        }
    };
    return [verifyMfaCode, isLoading];
}

function EmailInput({ setEmail, isLoading }: { setEmail: (email: string) => void; isLoading: boolean }) {
    const texts = getLocalizedTexts().auth.signIn.withEmail;
    return (
        <View style={styles.containerTextInput}>
            <TextInput
                style={[styles.textInput, { marginRight: 16 }]}
                placeholder={texts.email}
                autoCorrect={false}
                autoCapitalize={'none'}
                keyboardType={'email-address'}
                returnKeyType="next"
                autoFocus={true}
                editable={!isLoading}
                onChangeText={setEmail}
                placeholderTextColor={color.silverChalice}
            />
        </View>
    );
}

function PasswordInput({
    setPassword,
    isLoading,
    onPress,
}: {
    setPassword: (password: string) => void;
    isLoading: boolean;
    onPress: () => void;
}) {
    const texts = getLocalizedTexts().auth.signIn.withEmail;
    const [shouldHidePassword, setShouldHidePassword] = React.useState<boolean>(true);
    return (
        <View style={styles.containerTextInput}>
            <TextInput
                style={styles.textInput}
                placeholder={texts.password}
                autoCorrect={false}
                autoCapitalize={'none'}
                secureTextEntry={shouldHidePassword}
                underlineColorAndroid="transparent"
                returnKeyType="go"
                editable={!isLoading}
                onChangeText={setPassword}
                onSubmitEditing={onPress}
                placeholderTextColor={color.silverChalice}
            />
            <TouchableOpacity
                onPress={() => setShouldHidePassword(!shouldHidePassword)}
                activeOpacity={0.8}
                style={styles.containerEyeIcon}>
                {shouldHidePassword ? (
                    <Image source={eyeIcon} style={styles.imageEyeIcon} />
                ) : (
                    <Image source={eyeSlashIcon} style={styles.imageEyeSlashIcon} />
                )}
            </TouchableOpacity>
        </View>
    );
}

function CodeInput({ code, setCode, isLoading }: { code: string; setCode: (code: string) => void; isLoading: boolean }) {
    const texts = getLocalizedTexts().auth.signIn.withEmail;
    return (
        <View style={styles.containerTextInput}>
            <TextInput
                style={styles.textInput}
                placeholder={texts.mfa.codePlaceholder}
                value={code}
                blurOnSubmit={false}
                autoCorrect={false}
                autoCapitalize={'words'}
                keyboardType={'default'}
                returnKeyType="next"
                autoFocus={true}
                editable={!isLoading}
                underlineColorAndroid="transparent"
                onChangeText={setCode}
                placeholderTextColor={color.silverChalice}
            />
        </View>
    );
}

const ErrorMessage = ({ error, mfaError }: { error?: SignInWithEmailError; mfaError?: MfaError }) => {
    const texts = getLocalizedTexts().auth.signIn.withEmail.error;
    let message: string = '';
    if (error === SignInWithEmailError.WrongCredentials) message = texts.wrongCredentials;
    else if (error === SignInWithEmailError.GeneratedPassword) message = texts.socialAccount;
    else if (mfaError === MfaError.WrongCode) message = texts.wrongMfaCode;
    else message = texts.default;
    return (
        <View style={styles.containerErrorMessage}>
            <Text style={styles.textErrorMessage}>{message}</Text>
        </View>
    );
};

function SocialSignInButtons() {
    const texts = getLocalizedTexts().auth.signIn;
    const history = useHistory();
    return (
        <>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View style={styles.containerDivider} />
                <Text style={styles.textDivider}>{texts.divider}</Text>
                <View style={styles.containerDivider} />
            </View>
            <Button
                onPress={() => {
                    logAmplitudeEvent({ name: 'Registration - Clicked Apple Login' });
                    history.push('/auth/sign-in/apple');
                }}
                style={{ backgroundColor: color.white, marginTop: 28, marginBottom: 12 }}
                textStyle={styles.textButtonSocial}
                height={44}
                icon={<Image source={appleLogo} style={styles.imageSocialIcon} />}>
                {texts.withApple.button}
            </Button>
            <Button
                onPress={() => {
                    logAmplitudeEvent({ name: 'Registration - Clicked Google Login' });
                    history.push('/auth/sign-in/google');
                }}
                style={{ backgroundColor: color.white, marginBottom: 12 }}
                textStyle={styles.textButtonSocial}
                height={44}
                icon={<Image source={googleLogo} style={styles.imageSocialIcon} />}>
                {texts.withGoogle.button}
            </Button>
            <Button
                onPress={() => {
                    logAmplitudeEvent({ name: 'Registration - Clicked Facebook Login' });
                    history.push('/auth/sign-in/facebook');
                }}
                style={{ backgroundColor: color.white, marginBottom: 16 }}
                textStyle={styles.textButtonSocial}
                height={44}
                icon={<Image source={facebookLogo} style={styles.imageSocialIcon} />}>
                {texts.withFacebook.button}
            </Button>
        </>
    );
}

function SuggestToSignUpButton({ isUserLoggingInFromWebApp }: { isUserLoggingInFromWebApp: boolean }) {
    const texts = getLocalizedTexts().auth.signIn;
    const history = useHistory();
    return (
        <View style={styles.containerSuggestToSignUpButton}>
            <Text style={styles.textSignUp}>{texts.signUp.text + ' '}</Text>
            <TouchableOpacity
                onPress={() =>
                    history.push({
                        pathname: '/auth/sign-up',
                        state: isUserLoggingInFromWebApp ? IS_USER_LOGGING_FROM_WEB_APP_LOCATION_STATE : undefined,
                    })
                }>
                <Text style={styles.textSignUpButton}>{texts.signUp.button}</Text>
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 390,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 12,
        elevation: 5,
        borderRadius: 16,
        backgroundColor: color.white,
    },
    containerContent: {
        maxWidth: 350,
    },
    containerLogo: {
        alignItems: 'center',
        marginTop: 24,
    },
    containerMfaPage: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color.white,
    },
    containerMfaPageContent: {
        width: 390,
        paddingHorizontal: 20,
        borderRadius: 16,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 12,
        backgroundColor: color.white,
    },
    containerMfaButton: {
        marginTop: 20,
        marginBottom: 24,
    },
    containerErrorMessage: {
        backgroundColor: color.fairPink,
        paddingVertical: 8,
        paddingHorizontal: 25,
        borderRadius: 10,
        marginTop: -8,
        marginBottom: 16,
    },
    containerTextInput: {
        flexDirection: 'row',
        height: 44,
        marginBottom: 12,
        alignItems: 'center',
        borderWidth: 2,
        borderColor: color.gallery,
        borderRadius: 8,
    },
    containerEyeIcon: {
        height: 24,
        width: 24,
        marginLeft: 8,
        marginRight: 10,
        justifyContent: 'center',
    },
    containerDivider: {
        flex: 1,
        borderBottomColor: color.silverChalice,
        borderBottomWidth: 1,
    },
    containerSuggestToSignUpButton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 20,
        marginBottom: 24,
    },
    textTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.black,
        marginVertical: 24,
    },
    textSubtitle: {
        fontFamily: font.ambitBold,
        fontSize: 16,
        color: color.black,
        marginBottom: 16,
    },
    textTitleMfa: {
        fontFamily: font.ambitBlack,
        fontSize: 24,
        color: color.black,
        marginTop: 24,
        marginBottom: 8,
    },
    textSubtitleMfa: {
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        color: color.black,
        marginBottom: 24,
    },
    textErrorMessage: {
        color: color.radicalRed,
        fontFamily: font.ambitBold,
        fontSize: 14,
        alignSelf: 'center',
        textAlign: 'center',
    },
    textInput: {
        height: 24,
        fontFamily: font.ambitSemiBold,
        color: color.black,
        fontSize: 16,
        flexGrow: 1,
        outlineWidth: 0,
        marginLeft: 16,
    },
    textButton: {
        fontSize: 14,
        color: color.white,
    },
    textButtonSocial: {
        fontSize: 14,
        color: color.black,
    },
    textResetPasswordButton: {
        textAlign: 'center',
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.black,
        marginBottom: 28,
        borderBottomWidth: 1,
        borderBottomColor: color.black,
    },
    textDivider: {
        fontFamily: font.ambitRegular,
        fontSize: 12,
        lineHeight: 17,
        marginHorizontal: 7,
        color: color.silverChalice,
    },
    textSignUp: {
        textAlign: 'center',
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.black,
    },
    textSignUpButton: {
        textAlign: 'center',
        fontFamily: font.ambitSemiBold,
        fontSize: 14,
        color: color.black,
        borderBottomWidth: 1,
        borderBottomColor: color.black,
    },
    imageLogo: {
        width: 91,
        height: 33,
        resizeMode: 'contain',
    },
    imageEyeIcon: {
        width: 19,
        height: 14,
        resizeMode: 'contain',
    },
    imageEyeSlashIcon: {
        width: 19,
        height: 17,
        resizeMode: 'contain',
    },
    imageSocialIcon: {
        width: 20,
        height: 20,
        resizeMode: 'contain',
    },
});
