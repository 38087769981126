import { getRestApiUrl } from '../config';
import { EmailAddressUnknown, GeneratedPassword, ConflictingEmail, MissingEmail } from './errors';

export enum Provider {
    Facebook = 'facebook',
    Apple = 'apple',
    Google = 'google',
}

export async function getUserId(email: string): Promise<string> {
    console.log(`API call: getting the user id for email ${email}...`);
    const headers: Headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    const url = `${await getRestApiUrl()}/user/id?email=${encodeURIComponent(email)}`;
    return fetch(url, {
        method: 'GET',
        headers: headers,
    })
        .then((response) => {
            console.log(`Response ${response.status} for API call 'get user id'`);
            if (response.status === 200) {
                return response.json();
            } else if (response.status === 422) {
                throw new EmailAddressUnknown();
            } else if (response.status === 409) {
                throw new GeneratedPassword();
            } else {
                throw new Error(`${response.status}`);
            }
        })
        .then((responseJSON) => responseJSON.userId);
}

export interface GetSocialIdentityCredentialsParams {
    provider: string;
    providerUserId: string;
    email?: string;
    secretToken?: string;
}

export interface GetSocialIdentityCredentialsResponse {
    userId: string;
    secretKey: string;
    new: boolean;
}

export async function getSocialIdentityCredentials(
    params: GetSocialIdentityCredentialsParams
): Promise<GetSocialIdentityCredentialsResponse> {
    const { provider, providerUserId, email, secretToken } = params;
    console.log(`API call: getting social identity credentials for user ${providerUserId} for provider ${provider}...`);
    const headers: Headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    const url =
        `${await getRestApiUrl()}/user/social-identity` +
        `?provider=${encodeURIComponent(provider)}` +
        `&providerUserId=${encodeURIComponent(providerUserId)}` +
        `${email ? `&email=${encodeURIComponent(email)}` : ''}` +
        `${secretToken ? `&secretToken=${encodeURIComponent(secretToken)}` : ''}`;
    return fetch(url, {
        method: 'GET',
        headers: headers,
    }).then((response) => {
        console.log(`Response ${response.status} for API call 'get social identity'`);
        if (response.status === 200) return response.json();
        else if (response.status === 409) throw new ConflictingEmail(email);
        else if (response.status === 422) throw new MissingEmail();
        else throw new Error(`${response.status}`);
    });
}
