import gql from 'graphql-tag';

import { HistoryRecord, historyRecordFragment } from '../fragments/history';

export const userHistoryQuery = gql`
    query UserHistoryQuery($showUncollected: Boolean, $showReferralPoints: Boolean) {
        user {
            history(showUncollected: $showUncollected, showReferralPoints: $showReferralPoints) {
                ...HistoryRecord
            }
        }
    }
    ${historyRecordFragment}
`;

interface User {
    __typename: 'User';
    history: HistoryRecord[];
}

export type UserHistoryQueryResponse = {
    __typename: 'Query';
    user: User;
};

export type UserHistoryQueryVariables = {
    showUncollected?: boolean;
    showReferralPoints?: boolean;
};
