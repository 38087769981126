/**
 * The `palette.ts` file defines the base color palettes with various shades, from lightest ('25') to darkest ('900').
 * Each palette is a collection of these shades for different colors.
 *
 * The `color.ts` file uses these palettes to define semantic tokens for UI elements, such as `primary`, `secondary`,
 * `positive`, and `warning`. These tokens make it easy to apply consistent colors based on their function or state. They represent colors from our brand book.
 *
 * Separating base colors from their application ensures better maintainability, as changes in `palette.ts` automatically
 * update the colors used in `color.ts`.
 */

/** The `ColorScale` type represents various shades of a color, where '25' is the lightest shade and '900' is the darkest */
type ColorScale = '900' | '800' | '700' | '600' | '500' | '400' | '300' | '200' | '100' | '50' | '25';
/** The `ColorAlphaScale` type represents different levels of color opacity, where '1' is the most transparent and '100' is fully opaque */
type ColorAlphaScale =
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '8'
    | '11'
    | '16'
    | '22'
    | '31'
    | '40'
    | '55'
    | '64'
    | '70'
    | '80'
    | '100';

type ColorPalette = {
    [Scale in ColorScale]: string; // This string is of type `#${string}`;
};

type AlphaColorPalette = {
    [Scale in ColorAlphaScale]: string; // This string is of type `rgba(${number}, ${number}, ${number}, ${number})`;
};

export type Color = string;

export const black = '#000000';
export const white = '#FFFFFF';

/** The neutral palette correspond to grey */
export const neutral: ColorPalette = {
    '900': '#212121',
    '800': '#3D3D3D',
    '700': '#595959',
    '600': '#747474',
    '500': '#909090',
    '400': '#ACACAC',
    '300': '#C8C8C8',
    '200': '#D5D5D5',
    '100': '#E3E3E3',
    '50': '#F1F1F1',
    '25': '#F8F8F8',
};

export const green: ColorPalette = {
    '900': '#00340D',
    '800': '#0B5C1F',
    '700': '#0A7122',
    '600': '#059024',
    '500': '#1BCB42',
    '400': '#0ADD38',
    '300': '#32F45D',
    '200': '#76FF93',
    '100': '#B2FFC1',
    '50': '#D7FFE0',
    '25': '#EEFFF0',
};

export const purple: ColorPalette = {
    '900': '#330566',
    '800': '#4F1A89',
    '700': '#601EAB',
    '600': '#701FD1',
    '500': '#8D3FEF',
    '400': '#A653F9',
    '300': '#BF82FE',
    '200': '#D7A6FF',
    '100': '#ECD4FF',
    '50': '#F4E8FF',
    '25': '#FBF5FF',
};

export const blue: ColorPalette = {
    '900': '#07292C',
    '800': '#17484A',
    '700': '#175558',
    '600': '#166C6F',
    '500': '#17878A',
    '400': '#20ADAD',
    '300': '#32D5D6',
    '200': '#5FF2F2',
    '100': '#A8F9F9',
    '50': '#D1FCFB',
    '25': '#EDFEFD',
};

export const red: ColorPalette = {
    '900': '#470808',
    '800': '#821A1A',
    '700': '#9D1717',
    '600': '#BF1616',
    '500': '#E22020',
    '400': '#F53E3E',
    '300': '#FD6C6C',
    '200': '#FFA2A2',
    '100': '#FFC8C8',
    '50': '#FFEEEE',
    '25': '#FEF2F2',
};

export const orange: ColorPalette = {
    '900': '#471708',
    '800': '#82321A',
    '700': '#9D3717',
    '600': '#BE3E17',
    '500': '#E24E20',
    '400': '#F5693E',
    '300': '#FD8E6C',
    '200': '#FFB199',
    '100': '#FFD5C8',
    '50': '#FFE8E1',
    '25': '#FEF5F2',
};

export const blackAlpha: AlphaColorPalette = {
    '1': 'rgba(0, 0, 0, 0.01)',
    '2': 'rgba(0, 0, 0, 0.02)',
    '3': 'rgba(0, 0, 0, 0.03)',
    '4': 'rgba(0, 0, 0, 0.04)',
    '5': 'rgba(0, 0, 0, 0.05)',
    '8': 'rgba(0, 0, 0, 0.08)',
    '11': 'rgba(0, 0, 0, 0.11)',
    '16': 'rgba(0, 0, 0, 0.16)',
    '22': 'rgba(0, 0, 0, 0.22)',
    '31': 'rgba(0, 0, 0, 0.31)',
    '40': 'rgba(0, 0, 0, 0.40)',
    '55': 'rgba(0, 0, 0, 0.55)',
    '64': 'rgba(0, 0, 0, 0.64)',
    '70': 'rgba(0, 0, 0, 0.70)',
    '80': 'rgba(0, 0, 0, 0.80)',
    '100': 'rgba(0, 0, 0, 1.00)',
};

export const whiteAlpha: AlphaColorPalette = {
    '1': 'rgba(255, 255, 255, 0.01)',
    '2': 'rgba(255, 255, 255, 0.02)',
    '3': 'rgba(255, 255, 255, 0.03)',
    '4': 'rgba(255, 255, 255, 0.04)',
    '5': 'rgba(255, 255, 255, 0.05)',
    '8': 'rgba(255, 255, 255, 0.08)',
    '11': 'rgba(255, 255, 255, 0.11)',
    '16': 'rgba(255, 255, 255, 0.16)',
    '22': 'rgba(255, 255, 255, 0.22)',
    '31': 'rgba(255, 255, 255, 0.31)',
    '40': 'rgba(255, 255, 255, 0.40)',
    '55': 'rgba(255, 255, 255, 0.55)',
    '64': 'rgba(255, 255, 255, 0.64)',
    '70': 'rgba(255, 255, 255, 0.70)',
    '80': 'rgba(255, 255, 255, 0.80)',
    '100': 'rgba(255, 255, 255, 1.00)',
};
