const gql = require('graphql-tag');

export const merchantFragment = gql`
    fragment Merchant on Merchant {
        merchantId
        name
    }
`;

export interface Merchant {
    __typename: 'Merchant';
    merchantId: string | null;
    name: string | null;
}

export const dataIdFromMerchant = (merchant: Merchant) => {
    return `Merchant:${merchant.merchantId}`;
};
