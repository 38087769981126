import * as React from 'react';
import {
    ActivityIndicator,
    Animated,
    Dimensions,
    Image,
    ImageURISource,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Modal,
    ScrollView,
} from 'react-native';
import { useLocation } from 'react-router';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { logEventWithoutAuthentication } from '../../api/rest/events';
import { localizeAsset, LocalizedAsset, Region } from '../../lib/auth/region';
import { logAmplitudeEventWithoutAuthentication } from '../../lib/events/amplitudeEvents';
import {
    UrlStep,
    useParseUrlParameters,
    setMobileSafariExtensionTermsOfServiceAndPrivacyPolicyConsentInLocalStorage,
    InstallationStep,
    useLogEvents,
    useInstallationStep,
    useRedirectToAppAtConclusionStepAfterTimeout,
    getCallbacks,
    useCloseObsoletePagesWhenSecondWaitingPageIsOpened,
    useShouldUseRevampedOnboardingFlow,
} from '../../lib/mobileSafariExtension/mobileSafariExtension';
import { browserKind, Browser } from '../../lib/common/browser';
import Button from '../../components/common/Button';
import ModalContainer from '../../components/common/ModalContainer';
import HighlightedText from '../../components/common/HighlightedText';
import { MobileSafariExtensionInstallationPageContentRevamped } from './MobileSafariExtensionInstallationPagesRevamped';

const logo = { uri: '/assets/images/logos/logo-with-title.svg' };
const widgetIllustrationGreenBackground: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/widget-green.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/widget-green-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/widget-green-us.png' },
};
const widgetIllustrationOrangeBackground: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/version-b-widget-orange.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/version-b-widget-orange-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/version-b-widget-orange-us.png' },
};
const progressBar1 = { uri: '/assets/images/illustrations/mobile-safari-extension-installation/progress-bar-1.svg' };
const progressBar2 = { uri: '/assets/images/illustrations/mobile-safari-extension-installation/progress-bar-2.svg' };
const progressBar3 = { uri: '/assets/images/illustrations/mobile-safari-extension-installation/progress-bar-3.svg' };
const progressBar4 = { uri: '/assets/images/illustrations/mobile-safari-extension-installation/progress-bar-4.svg' };
const progressBar5 = { uri: '/assets/images/illustrations/mobile-safari-extension-installation/progress-bar-5.svg' };
const instructionScreenshot1 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-1.png',
};
const instructionScreenshot2: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-2.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-2-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-2-us.png' },
};
const instructionScreenshot3: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-3.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-3-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-3-us.png' },
};
const instructionScreenshot4: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-4.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-4-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-4-us.png' },
};
const instructionScreenshot5: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-5.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-5-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-5-us.png' },
};
const instructionScreenshot6: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-6.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-6-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-6-us.png' },
};
const instructionSettingsScreenshot1 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-settings-1.png',
};
const instructionSettingsScreenshot2 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-settings-2.png',
};
const instructionBannerIllustration1 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-banner-1.png',
};
const instructionBannerIllustration2 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-banner-2.png',
};
const instructionBannerIllustration3 = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/instruction-banner-3.png',
};
const warningIllustration: LocalizedAsset = {
    [Region.FR]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/warning.png' },
    [Region.US]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/warning-us.png' },
    [Region.GB]: { uri: '/assets/images/illustrations/mobile-safari-extension-installation/warning-us.png' },
};
const greenChevronWithRoundBackground = {
    uri: '/assets/images/illustrations/mobile-safari-extension-installation/chevron-up-green-with-background.png',
};
const confettiAnimation = { uri: '/assets/images/illustrations/mobile-safari-extension-installation/confettiAnimation.gif' };
const checkMarkIcon = { uri: '/assets/images/icons/check-mark.svg' };
const arrowRightIcon = { uri: '/assets/images/icons/arrow-right-green.svg' };
const extensionIcon = { uri: '/assets/images/icons/mobile-safari-extension-icon.svg' };

function MobileSafariExtensionInstallationPage() {
    const location = useLocation();
    const urlParameters = useParseUrlParameters(location);
    useRedirectToAppAtConclusionStepAfterTimeout(urlParameters);
    const shouldUseRevampedOnboardingFlow = useShouldUseRevampedOnboardingFlow(urlParameters);
    if (browserKind !== Browser.safari && !urlParameters.isDevStackMode)
        return <WrongBrowserScreen {...{ ...urlParameters, browserKind }} />;
    else if (shouldUseRevampedOnboardingFlow === undefined) {
        return <WaitingScreen />;
    } else if (shouldUseRevampedOnboardingFlow) {
        return <MobileSafariExtensionInstallationPageContentRevamped {...{ ...urlParameters }} />;
    } else {
        return <MobileSafariExtensionInstallationPageContent {...{ ...urlParameters }} />;
    }
}

export default MobileSafariExtensionInstallationPage;

function MobileSafariExtensionInstallationPageContent({
    urlStep,
    isDevStackMode,
    userId,
    deviceId,
    isOnboarding,
    isBeforeOnboarding,
    isFlowWithSystemSettings,
}: {
    urlStep: UrlStep;
    isDevStackMode: boolean;
    userId: string | undefined;
    deviceId: string | undefined;
    isOnboarding: boolean;
    isBeforeOnboarding: boolean;
    isFlowWithSystemSettings: boolean;
}) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage;
    const [step, setStep] = useInstallationStep({ urlStep, userId, isDevStackMode });
    const [isTosConsentNeeded, setIsTosConsentNeeded] = React.useState<boolean>(isBeforeOnboarding);
    useLogEvents({
        urlStep,
        userId,
        deviceId,
        isDevStackMode,
        isOnboarding,
        isBeforeOnboarding,
        isFlowWithSystemSettings,
        isRevampedOnboardingFlow: false,
    });
    const {
        onPressStartActivation,
        onPressStartAuthorization,
        onPressCloseMissingAllWebsitesAuthorizationModal,
        onPressModifyAuthorization,
        onPressReturnToApp,
        onPressGoToGoogle,
    } = getCallbacks({
        step,
        setStep,
        userId,
        deviceId,
        isDevStackMode,
        isOnboarding,
        isBeforeOnboarding,
        isFlowWithSystemSettings,
    });
    useCloseObsoletePagesWhenSecondWaitingPageIsOpened(step);
    if (!step || step === InstallationStep.firstWaitingStep || step === InstallationStep.secondWaitingStep)
        return <WaitingScreen />;
    const shouldDisplayHeaderWithinComponent = step === InstallationStep.authorizeStep && isFlowWithSystemSettings;
    const styleContainer = shouldDisplayHeaderWithinComponent ? styles.containerWithHeaderInComponent : styles.container;
    return (
        <View style={styleContainer}>
            {shouldDisplayHeaderWithinComponent ? null : <Header {...{ step, shouldDisplayHeaderWithinComponent }} />}
            <Illustration {...{ step }} />
            <Title {...{ step, isBeforeOnboarding }} />
            {step === InstallationStep.activateStepPreamble ? (
                <View style={styles.containerButtons}>
                    <TosConsentButton
                        {...{
                            isBeforeOnboarding,
                            isTosConsentNeeded,
                            setIsTosConsentNeeded,
                            isDevStackMode,
                            userId,
                            deviceId,
                        }}
                    />
                    <Button onPress={onPressStartActivation} disabled={isTosConsentNeeded} textStyle={styles.textButton}>
                        {texts.activateStep.preamble.startButton}
                    </Button>
                </View>
            ) : step === InstallationStep.authorizeStepPreamble ? (
                <View style={styles.containerButtons}>
                    <Button onPress={onPressStartAuthorization} textStyle={styles.textButton}>
                        {texts.authorizeStep.preamble.startButton}
                    </Button>
                </View>
            ) : step === InstallationStep.activateStep ? (
                <ActivationInstructions />
            ) : step === InstallationStep.authorizeStep ||
              step === InstallationStep.missingAllWebsitesAuthorization ||
              step === InstallationStep.missingAllWebsitesAuthorizationAfterRetry ||
              step === InstallationStep.authorizeWithBannerStep ? (
                <AuthorizationInstructions
                    {...{
                        step,
                        setStep,
                        userId,
                        deviceId,
                        isDevStackMode,
                        isFlowWithSystemSettings,
                    }}
                    Header={<Header {...{ step, shouldDisplayHeaderWithinComponent }} />}
                />
            ) : null}
            {step === InstallationStep.missingAllWebsitesAuthorization ||
            step === InstallationStep.missingAllWebsitesAuthorizationAfterRetry ? (
                <MissingAllWebsitesAuthorizationModal
                    {...{
                        hasAlreadyRetried: step === InstallationStep.missingAllWebsitesAuthorizationAfterRetry,
                        onPressCloseMissingAllWebsitesAuthorizationModal,
                    }}
                />
            ) : step === InstallationStep.missingAuthorization ? (
                <View style={styles.containerButtons}>
                    <Button
                        onPress={onPressModifyAuthorization}
                        textStyle={styles.textButton}
                        style={styles.containerButtons}>
                        {texts.missingAuthorization.default.button}
                    </Button>
                    <Text style={styles.textClickableLink} onPress={onPressGoToGoogle}>
                        {texts.missingAuthorization.default.finishLaterButton}
                    </Text>
                </View>
            ) : null}
            {step === InstallationStep.conclusionStep ? (
                <View style={styles.containerButtons}>
                    <Button onPress={onPressReturnToApp} textStyle={styles.textButton}>
                        {isBeforeOnboarding ? texts.conclusion.connectInAppButton : texts.backToAppButton}
                    </Button>
                </View>
            ) : null}
        </View>
    );
}

function Header({
    step,
    shouldDisplayHeaderWithinComponent,
}: {
    step: InstallationStep;
    shouldDisplayHeaderWithinComponent: boolean;
}) {
    const styleHeader = shouldDisplayHeaderWithinComponent
        ? styles.containerHeaderWithHeaderInComponent
        : styles.containerHeader;
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage;
    return (
        <View style={styleHeader}>
            <Image source={logo} style={styles.imageLogo} />
            {step !== InstallationStep.conclusionStep ? (
                <>
                    <Text style={styles.textTitle}>{texts.header.title}</Text>
                    <ProgressBar {...{ step }} />
                </>
            ) : null}
        </View>
    );
}

function ProgressBar({ step }: { step: InstallationStep }) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage;
    const progressBar = { uri: '' };
    switch (step) {
        case InstallationStep.activateStepPreamble:
            progressBar.uri = progressBar1.uri;
            break;
        case InstallationStep.activateStep:
            progressBar.uri = progressBar2.uri;
            break;
        case InstallationStep.authorizeStepPreamble:
            progressBar.uri = progressBar3.uri;
            break;
        case InstallationStep.authorizeStep:
        case InstallationStep.authorizeWithBannerStep:
            progressBar.uri = progressBar4.uri;
            break;
        case InstallationStep.missingAllWebsitesAuthorization:
        case InstallationStep.missingAllWebsitesAuthorizationAfterRetry:
        case InstallationStep.missingAuthorization:
            progressBar.uri = progressBar5.uri;
            break;
    }
    const leftTextStyle =
        step !== InstallationStep.activateStepPreamble ? styles.textProgressBar : styles.textProgressBarGray;
    const rightTextStyle =
        step === InstallationStep.authorizeStep ||
        step === InstallationStep.authorizeWithBannerStep ||
        step === InstallationStep.conclusionStep
            ? styles.textProgressBar
            : styles.textProgressBarGray;
    if (!progressBar.uri) return null;
    return (
        <View style={{ alignItems: 'center' }}>
            <Image source={progressBar} style={styles.imageProgressBar} />
            <View style={{ flexDirection: 'row', width: normalizeWidth(210) }}>
                <View style={{ width: normalizeWidth(105) }}>
                    <Text style={leftTextStyle}>{texts.header.left}</Text>
                </View>
                <View style={{ width: normalizeWidth(105), paddingLeft: 5 }}>
                    <Text style={rightTextStyle}>{texts.header.right}</Text>
                </View>
            </View>
        </View>
    );
}

function Illustration({ step }: { step: InstallationStep }) {
    const illustration: ImageURISource = { uri: '' };
    switch (step) {
        case InstallationStep.activateStepPreamble:
            illustration.uri = localizeAsset(widgetIllustrationGreenBackground).uri;
            break;
        case InstallationStep.authorizeStepPreamble:
            illustration.uri = localizeAsset(widgetIllustrationGreenBackground).uri;
            break;
        case InstallationStep.conclusionStep:
            illustration.uri = confettiAnimation.uri;
            break;
        case InstallationStep.missingAuthorization:
            illustration.uri = localizeAsset(widgetIllustrationOrangeBackground).uri;
    }
    if (!illustration.uri) return null;
    const imageStyle = styles.imageIllustration;
    const containerStyle = styles.containerIllustration;
    return (
        <View style={containerStyle}>
            <Image source={illustration} style={imageStyle} />
        </View>
    );
}

function Title({ step, isBeforeOnboarding }: { step: InstallationStep; isBeforeOnboarding: boolean }) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage;
    if (
        step !== InstallationStep.authorizeStepPreamble &&
        step !== InstallationStep.conclusionStep &&
        step !== InstallationStep.missingAuthorization
    )
        return null;
    const title =
        step === InstallationStep.authorizeStepPreamble
            ? texts.authorizeStep.preamble.title
            : step === InstallationStep.conclusionStep
            ? texts.conclusion.title
            : texts.missingAuthorization.default.title;
    const subtitle =
        step === InstallationStep.authorizeStepPreamble
            ? texts.authorizeStep.preamble.subtitle
            : step === InstallationStep.conclusionStep
            ? isBeforeOnboarding
                ? texts.conclusion.connectInAppSubtitle
                : texts.conclusion.subtitle
            : texts.missingAuthorization.default.subtitle;
    const titleStyle = step === InstallationStep.missingAuthorization ? styles.textTitleBlack : styles.textTitle;
    return (
        <View style={styles.containerTitle}>
            <Text style={titleStyle}>{title}</Text>
            <Text style={styles.textSubtitle}>{subtitle} </Text>
        </View>
    );
}

function TosConsentButton({
    isBeforeOnboarding,
    isTosConsentNeeded,
    setIsTosConsentNeeded,
    isDevStackMode,
    userId,
    deviceId,
}: {
    isBeforeOnboarding: boolean;
    isTosConsentNeeded: boolean;
    setIsTosConsentNeeded: React.Dispatch<React.SetStateAction<boolean>>;
    isDevStackMode: boolean;
    userId?: string;
    deviceId?: string;
}) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.activateStep.preamble;
    const termsOfServiceUrl = 'joko://TermsOfService';
    const privacyPolicyUrl = 'joko://PrivacyPolicy';
    const onPressConsent = () => {
        // We store the consent in the local storage so that it can be accessed by the mobile Safari extension's content script
        // We also store the associated 'isDevStackMode' flag so that it can be accessed and used by the mobile Safari extension
        setMobileSafariExtensionTermsOfServiceAndPrivacyPolicyConsentInLocalStorage(isDevStackMode);
        setIsTosConsentNeeded(false);
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Consent ToS And PP',
                properties: { isRevampedOnboardingFlow: false },
            },
            { userId, deviceId }
        );
    };
    const viewTermsOfService = () => {
        window.location.href = termsOfServiceUrl;
    };
    const viewPrivacyPolicy = () => {
        window.location.href = privacyPolicyUrl;
    };
    if (!isBeforeOnboarding) return null;
    return (
        <TouchableOpacity style={styles.containerTosConsentButton} onPress={onPressConsent} activeOpacity={0.7}>
            <View
                style={[
                    styles.containerCheckMark,
                    isTosConsentNeeded ? styles.containerCheckMarkUnselected : styles.containerCheckMarkSelected,
                ]}>
                <Image source={checkMarkIcon} style={styles.imageCheckMarkIcon} />
            </View>
            <HighlightedText
                style={styles.textTosConsentButton}
                highlightedStyle={styles.textTosConsentButtonHighlighted}
                onPressHighlightedTexts={[viewTermsOfService, viewPrivacyPolicy]}>
                {texts.tosConsent}
            </HighlightedText>
        </TouchableOpacity>
    );
}

function ActivationInstructions() {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.activateStep.instructions;
    return (
        <View style={styles.containerInstructions}>
            <Text style={styles.textInstructionTitle}>{texts.title}</Text>
            <View style={styles.containerSeparator} />
            <View style={styles.containerInstructionsDetails}>
                <Text style={{ textAlign: 'center' }}>
                    <Text style={styles.textInstruction}>{texts.firstStep.title1}</Text>
                    <Image style={styles.imageInstructionIcon} source={extensionIcon} />
                    <Text style={styles.textInstruction}>{texts.firstStep.title2}</Text>
                </Text>
                <InstructionImage {...instructionScreenshot1} />
                <View style={{ marginHorizontal: 20, alignItems: 'center' }}>
                    <Text style={styles.textInstructionGray}>{texts.firstStep.subtitle}</Text>
                </View>
                <Text style={styles.textInstruction}>{texts.secondStep}</Text>
                <InstructionImage {...localizeAsset(instructionScreenshot2)} />
                <Text style={styles.textInstruction}>{texts.thirdStep}</Text>
                <InstructionImage {...localizeAsset(instructionScreenshot3)} />
            </View>
        </View>
    );
}

function AuthorizationInstructions({
    step,
    setStep,
    userId,
    deviceId,
    isDevStackMode,
    isFlowWithSystemSettings,
    Header,
}: {
    step: InstallationStep;
    setStep: (step: InstallationStep) => void;
    userId?: string;
    deviceId?: string;
    isDevStackMode?: boolean;
    isFlowWithSystemSettings: boolean;
    Header: React.ReactNode;
}) {
    if (step === InstallationStep.authorizeWithBannerStep)
        return <BannerAuthorizationInstructions {...{ setStep, userId, deviceId, isDevStackMode }} />;
    if (isFlowWithSystemSettings)
        return <SystemSettingsAuthorizationInstructions {...{ userId, deviceId, isDevStackMode, Header }} />;
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.authorizeStep.instructions;
    return (
        <View style={styles.containerAuthorizationInstructions}>
            <Text style={styles.textInstructionTitle}>{texts.title}</Text>
            <View style={styles.containerSeparator} />
            <View style={styles.containerInstructionsDetails}>
                <Text style={{ textAlign: 'center' }}>
                    <Text style={styles.textInstruction}>{texts.firstStep.title1}</Text>
                    <Image style={styles.imageInstructionIcon} source={extensionIcon} />
                    <Text style={styles.textInstruction}>{texts.firstStep.title2}</Text>
                </Text>
                <InstructionImage {...instructionScreenshot1} />
                <View style={{ marginHorizontal: 20, alignItems: 'center' }}>
                    <Text style={styles.textInstructionGray}>{texts.firstStep.subtitle}</Text>
                </View>
                <Text style={styles.textInstruction}>{texts.secondStep}</Text>
                <InstructionImage {...localizeAsset(instructionScreenshot4)} />
                <div id="alwaysAllowPopupInstruction" /> {/* This empty div is used as a scrolling tag */}
                <Text style={styles.textInstruction}>{texts.thirdStep}</Text>
                <InstructionImage {...localizeAsset(instructionScreenshot5)} />
                <div id="allowOnAllWebsitesPopupInstruction" /> {/* This empty div is used as a scrolling tag */}
                <Text style={styles.textInstruction}>{texts.fourthStep}</Text>
                <InstructionImage {...localizeAsset(instructionScreenshot6)} />
            </View>
        </View>
    );
}

function BannerAuthorizationInstructions({
    setStep,
    userId,
    deviceId,
    isDevStackMode,
}: {
    setStep: (step: InstallationStep) => void;
    userId?: string;
    deviceId?: string;
    isDevStackMode?: boolean;
}) {
    const onPressMissingAuthorizationBanner = () => {
        setStep(InstallationStep.authorizeStep);
        logEventWithoutAuthentication(
            userId,
            {
                type: 'clickedMissingMobileSafariExtensionAuthorizationBannerButton',
                payload: { isRevampedOnboardingFlow: false },
            },
            isDevStackMode
        );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Missing Authorization Banner Button',
                properties: { isRevampedOnboardingFlow: false },
            },
            { userId, deviceId }
        );
    };
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.authorizeStep.instructionsWithBanner;
    return (
        <View style={styles.containerAuthorizationInstructions}>
            <View style={{ height: normalizeHeight(10) }} />
            <Text style={styles.textBannerInstruction}>{texts.firstStep}</Text>
            <Image source={instructionBannerIllustration1} style={[styles.imageBannerInstruction1, { marginBottom: 0 }]} />
            <div id="missingBannerButton" /> {/* This empty div is used as a tag */}
            <TouchableOpacity
                onPress={onPressMissingAuthorizationBanner}
                activeOpacity={0.7}
                style={styles.containerMissingBannerButton}>
                <Text style={styles.textMissingAuthorizationBannerButton}>{texts.missingBannerButton}</Text>
                <Image source={arrowRightIcon} style={styles.imageArrowRightIcon} />
            </TouchableOpacity>
            <Text style={styles.textBannerInstruction}>{texts.secondStep}</Text>
            <Image source={instructionBannerIllustration2} style={styles.imageBannerInstruction2} />
            <Text style={styles.textBannerInstruction}>{texts.thirdStep}</Text>
            <Image source={instructionBannerIllustration3} style={styles.imageBannerInstruction2} />
            <WigglingArrowModal {...{ onPressMissingAuthorizationBanner }} />
        </View>
    );
}

// This modal displays a wiggling arrow animation, at a fixed position close to the top-right corner of the screen
// Since the modal covers the underlying screen, we duplicate the touchable CTA in the modal, at the appropriate position
function WigglingArrowModal({ onPressMissingAuthorizationBanner }: { onPressMissingAuthorizationBanner: () => void }) {
    const [animationTarget, setAnimationTarget] = React.useState(0); // This variable switches between 0 and 1
    const [animation, setAnimation] = React.useState(new Animated.Value(0)); // This animated variable is translated from the previous target to the new target
    const arrowTopPosition = animation.interpolate({ inputRange: [0, 1], outputRange: [5, 20] });
    const moveArrowTo = (newAnimationTarget: number) => {
        Animated.timing(animation, { toValue: newAnimationTarget, duration: 600, useNativeDriver: true }).start(() => {
            setAnimationTarget(1 - animationTarget); // At the end of a translation, we start a new translation in the opposite direction
        });
    };
    React.useEffect(() => {
        moveArrowTo(animationTarget);
    }, [animationTarget]);
    const missingBannerButtonTop = document.getElementById('missingBannerButton')?.getBoundingClientRect()?.top;
    return (
        <Modal transparent={true}>
            <Animated.View style={{ top: arrowTopPosition, right: normalizeWidth(25), position: 'absolute' }}>
                <View>
                    <Image source={greenChevronWithRoundBackground} style={styles.imageChevronIcon} />
                </View>
            </Animated.View>
            {!!missingBannerButtonTop ? (
                <TouchableOpacity
                    style={[styles.containerClickablePlaceholder, { top: missingBannerButtonTop }]}
                    onPress={onPressMissingAuthorizationBanner}
                />
            ) : null}
        </Modal>
    );
}

function SystemSettingsAuthorizationInstructions({
    userId,
    deviceId,
    isDevStackMode,
    Header,
}: {
    userId?: string;
    deviceId?: string;
    isDevStackMode?: boolean;
    Header: React.ReactNode;
}) {
    const onPressOpenSystemSettings = () => {
        logEventWithoutAuthentication(
            userId,
            {
                type: 'clickedOpenSystemSettingsMobileSafariExtensionInstallationFlow',
                payload: { isRevampedOnboardingFlow: false },
            },
            isDevStackMode
        );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Clicked Open System Settings',
                properties: { isRevampedOnboardingFlow: false },
            },
            { userId, deviceId }
        );
        window.location.replace('joko://MobileSafariExtensionSystemSettingsRedirection/');
    };
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.authorizeStep.instructionsWithSystemSettings;
    return (
        <View style={{ flex: 1, width: '100%' }}>
            <ScrollView overScrollMode="never">
                {Header}
                <View style={styles.containerAuthorizationInstructions}>
                    <Text style={styles.textInstructionTitleFlowWithSystemSettings}>{texts.title}</Text>
                    <View style={styles.containerSeparator} />
                    <View style={styles.containerInstructionsDetails}>
                        <Text style={styles.textInstruction}>{texts.firstStep}</Text>
                        <Text style={styles.textInstruction}>{texts.secondStep}</Text>
                        <InstructionImage {...instructionSettingsScreenshot1} />
                        <Text style={styles.textInstruction}>{texts.thirdStep}</Text>
                        <InstructionImage {...instructionSettingsScreenshot2} />
                    </View>
                </View>
            </ScrollView>
            <PulsatingButton {...{ onPressOpenSystemSettings, texts }} />
        </View>
    );
}

function PulsatingButton({ onPressOpenSystemSettings }: { onPressOpenSystemSettings: () => void }) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.authorizeStep.instructionsWithSystemSettings;
    return (
        <span
            style={{
                // This style cannot be easily factored out in the style sheet or in a css file
                padding: 0,
                width: '90%',
                position: 'fixed',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'center',
                bottom: 20,
                height: 56,
                borderRadius: 56,
                maxHeight: 56,
                borderWidth: 1.5,
                borderColor: color.black,
                backgroundColor: color.black,
                transition: 'opacity 0.3s',
            }}
            onTouchStart={(e) => {
                e.currentTarget.style.opacity = '0.8';
                e.currentTarget.style.backgroundColor = 'white';
            }}
            onTouchEnd={(e) => {
                e.currentTarget.style.opacity = '1';
            }}>
            <Button onPress={onPressOpenSystemSettings} textStyle={styles.textButton} style={{ zIndex: 3 }}>
                {texts.openSystemSettingsButton}
            </Button>
            <span
                style={{
                    // This style cannot be easily factored out in the style sheet or in a css file
                    zIndex: 2,
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    borderRadius: 56,
                    backgroundColor: '#000',
                    animation: 'pulsate 1s cubic-bezier(0,0,.2,1) infinite',
                }}
            />
        </span>
    );
}

function InstructionImage(source: ImageURISource) {
    return (
        <View style={styles.containerImageInstruction}>
            <Image source={source} style={styles.imageInstruction} />
        </View>
    );
}

function MissingAllWebsitesAuthorizationModal({
    hasAlreadyRetried,
    onPressCloseMissingAllWebsitesAuthorizationModal,
}: {
    hasAlreadyRetried: boolean;
    onPressCloseMissingAllWebsitesAuthorizationModal: (hasUserClickedModifyAuthorization: boolean) => void;
}) {
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.missingAuthorization;
    const onPressCloseModal = () => onPressCloseMissingAllWebsitesAuthorizationModal(false);
    const onPressModifyAuthorizationModal = () => onPressCloseMissingAllWebsitesAuthorizationModal(true);
    return (
        <ModalContainer cannotClose={false} onClose={onPressCloseModal} shouldCrossBeDiscrete>
            <View style={styles.containerMissingAllWebsitesAuthorizationModal}>
                <Image source={localizeAsset(warningIllustration)} style={styles.imageModal} />
                <Text style={styles.textMissingAllWebsitesAuthorizationModalTitle}>
                    {hasAlreadyRetried ? texts.allWebsitesAfterRetry.title : texts.allWebsitesBeforeRetry.title}
                </Text>
                <Text style={styles.textMissingAllWebsitesAuthorizationModalSubtitle}>
                    {hasAlreadyRetried ? texts.allWebsitesAfterRetry.subtitle : texts.allWebsitesBeforeRetry.subtitle}
                </Text>
                {!hasAlreadyRetried ? (
                    <View style={styles.containerMissingAllWebsitesAuthorizationModalButton}>
                        <Button
                            onPress={onPressModifyAuthorizationModal}
                            textStyle={styles.textButton}
                            height={normalizeHeight(40)}>
                            {texts.allWebsitesBeforeRetry.button}
                        </Button>
                    </View>
                ) : null}
            </View>
        </ModalContainer>
    );
}

function WrongBrowserScreen({
    userId,
    deviceId,
    isDevStackMode,
    browserKind,
    isOnboarding,
    isBeforeOnboarding,
}: {
    userId?: string;
    deviceId?: string;
    isDevStackMode: boolean;
    browserKind: Browser;
    isOnboarding: boolean;
    isBeforeOnboarding: boolean;
}) {
    React.useEffect(() => {
        logEventWithoutAuthentication(
            userId,
            {
                type: 'viewedWrongBrowserPageMobileSafariExtension',
                payload: { browserKind, isOnboarding, isBeforeOnboarding, isRevampedOnboardingFlow: false },
            },
            isDevStackMode
        );
        logAmplitudeEventWithoutAuthentication(
            {
                name: 'Mobile Safari Extension - Viewed Wrong Browser Page',
                properties: { browserKind, isOnboarding, isBeforeOnboarding, isRevampedOnboardingFlow: false },
            },
            { userId, deviceId }
        );
    }, []);
    const texts = getLocalizedTexts().mobileSafariExtensionInstallationPage.wrongBrowser;
    return (
        <View style={styles.container}>
            <Image source={logo} style={[styles.imageLogo, { marginBottom: normalizeHeight(40) }]} />
            <View style={styles.containerIllustration}>
                <Image source={localizeAsset(widgetIllustrationOrangeBackground)} style={styles.imageIllustration} />
            </View>
            <View style={[styles.containerTitle, { marginTop: normalizeHeight(25) }]}>
                <Text style={[styles.textTitle, { marginBottom: normalizeHeight(20) }]}>{texts.title}</Text>
                <Text style={styles.textSubtitle}>{texts.subtitle} </Text>
            </View>
        </View>
    );
}

function WaitingScreen() {
    return (
        <View style={styles.containerWaitingScreen}>
            <ActivityIndicator size="large" color={color.alto} />
        </View>
    );
}

const { height } = Dimensions.get('window');
const DEVICE_HEIGHT_RATIO = Dimensions.get('window').height / 548;
const DEVICE_WIDTH_RATIO = Dimensions.get('window').width / 300;
const IS_SMALL_SCREEN = height < 800;

function normalizeHeight(size: number, ratioEffect: number = 1, maxSize?: number): number {
    const normalizedSize = Math.round(((DEVICE_HEIGHT_RATIO - 1) * ratioEffect + 1) * size);
    if (maxSize) return Math.min(normalizedSize, maxSize);
    else return normalizedSize;
}

function normalizeWidth(size: number, ratioEffect: number = 1, maxSize?: number): number {
    const normalizedSize = Math.round(((DEVICE_WIDTH_RATIO - 1) * ratioEffect + 1) * size);
    if (maxSize) return Math.min(normalizedSize, maxSize);
    else return normalizedSize;
}

const styles = StyleSheet.create({
    container: {
        height,
        alignItems: 'center',
        paddingTop: 40,
    },
    containerWithHeaderInComponent: {
        height,
        alignItems: 'center',
    },
    containerTitle: {
        marginTop: normalizeHeight(15),
        marginHorizontal: 25,
    },
    containerIllustration: {
        marginVertical: IS_SMALL_SCREEN ? normalizeHeight(8) : normalizeHeight(16),
    },
    containerButtons: {
        marginTop: IS_SMALL_SCREEN ? 10 : 20,
        width: '100%',
        paddingHorizontal: 20,
        rowGap: IS_SMALL_SCREEN ? 10 : 15,
    },
    containerHeader: {
        alignItems: 'center',
        marginBottom: 10,
    },
    containerHeaderWithHeaderInComponent: {
        alignItems: 'center',
        marginBottom: 10,
        paddingTop: 40,
    },
    containerSeparator: {
        width: '80%',
        borderBottomColor: color.frenchGray,
        borderBottomWidth: 0.2,
        marginVertical: 5,
    },
    containerInstructions: {
        flex: 1,
        marginHorizontal: 40,
        alignItems: 'center',
    },
    containerAuthorizationInstructions: {
        flex: 1,
        marginHorizontal: 40,
        alignItems: 'center',
        marginBottom: normalizeHeight(50),
    },
    containerClickablePlaceholder: {
        position: 'absolute',
        height: normalizeWidth(15),
        width: '100%',
        outlineStyle: 'none',
    },
    containerInstructionsDetails: {
        alignItems: 'center',
        paddingBottom: 50,
    },
    containerImageInstruction: {
        width: normalizeWidth(240),
        height: normalizeWidth(120),
        borderColor: color.frenchGray,
        borderWidth: 0.2,
        borderRadius: 5,
        marginTop: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerMissingBannerButton: {
        justifyContent: 'center',
        alignItems: 'center',
        outlineStyle: 'none',
        flexDirection: 'row',
        marginBottom: normalizeHeight(15),
    },
    containerMissingAllWebsitesAuthorizationModal: {
        width: normalizeWidth(250),
        borderRadius: 16,
        backgroundColor: 'white',
        overflow: 'hidden',
        alignItems: 'center',
        paddingVertical: 25,
        paddingHorizontal: 15,
    },
    containerMissingAllWebsitesAuthorizationModalButton: {
        width: '90%',
        marginTop: 5,
    },
    containerCheckMark: {
        justifyContent: 'center',
        alignItems: 'center',
        height: normalizeHeight(16),
        width: normalizeHeight(16),
        borderRadius: normalizeHeight(16),
        marginRight: 10,
    },
    containerCheckMarkSelected: {
        backgroundColor: color.emerald,
    },
    containerCheckMarkUnselected: {
        borderWidth: 2,
        borderColor: color.silverChalice,
    },
    containerTosConsentButton: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: IS_SMALL_SCREEN ? normalizeHeight(16) : normalizeHeight(25),
        outlineStyle: 'none',
    },
    containerWaitingScreen: {
        flex: 1,
        minHeight: '100lvh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textTitleBlack: {
        fontFamily: font.ambitBlack,
        fontSize: normalizeHeight(18),
        lineHeight: normalizeHeight(24),
        color: color.black,
        textAlign: 'center',
    },
    textTitle: {
        fontFamily: font.ambitBold,
        fontSize: normalizeHeight(18),
        lineHeight: normalizeHeight(24),
        color: color.black,
        textAlign: 'center',
    },
    textSubtitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeHeight(16),
        lineHeight: normalizeHeight(24),
        color: color.gray,
        textAlign: 'center',
        marginTop: normalizeHeight(10),
    },
    textButton: {
        fontSize: normalizeHeight(13),
        fontFamily: font.ambitBlack,
        textTransform: 'uppercase',
    },
    textProgressBar: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeWidth(10),
        color: color.black,
    },
    textProgressBarGray: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeWidth(10),
        color: color.gray,
    },
    textInstructionTitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeHeight(18),
        color: color.black,
        textAlign: 'center',
        marginVertical: 15,
    },
    textInstructionTitleFlowWithSystemSettings: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeHeight(16),
        color: color.black,
        textAlign: 'center',
        marginVertical: 15,
    },
    textInstruction: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeHeight(13),
        color: color.black,
        textAlign: 'center',
        marginTop: 25,
    },
    textClickableLink: {
        fontFamily: font.ambitBold,
        fontSize: normalizeHeight(13),
        color: color.black,
        textAlign: 'center',
        alignSelf: 'center',
        padding: 5,
    },
    textInstructionGray: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeHeight(13),
        color: color.gray,
        textAlign: 'center',
        marginTop: 5,
    },
    textBannerInstruction: {
        fontFamily: font.ambitBold,
        fontSize: normalizeWidth(14),
        color: color.black,
        textAlign: 'center',
        marginTop: normalizeHeight(35),
    },
    textMissingAuthorizationBannerButton: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeWidth(10),
        color: color.emerald,
        textAlign: 'center',
    },
    textMissingAllWebsitesAuthorizationModalTitle: {
        fontFamily: font.ambitBold,
        fontSize: normalizeHeight(18),
        lineHeight: normalizeHeight(24),
        color: color.black,
        textAlign: 'center',
        marginVertical: normalizeHeight(5),
    },
    textMissingAllWebsitesAuthorizationModalSubtitle: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeHeight(14),
        lineHeight: normalizeHeight(19),
        color: color.gray,
        textAlign: 'center',
        marginBottom: 15,
    },
    textTosConsentButton: {
        fontFamily: font.ambitSemiBold,
        fontSize: normalizeHeight(13),
        color: color.black,
        textAlign: 'left',
        paddingTop: normalizeHeight(1),
    },
    textTosConsentButtonHighlighted: {
        fontFamily: font.ambitSemiBold,
        color: color.emerald,
    },
    imageLogo: {
        width: normalizeHeight(90),
        height: normalizeHeight(30),
        resizeMode: 'contain',
        alignSelf: 'center',
        marginBottom: 20,
    },
    imageIllustration: {
        width: normalizeHeight(225),
        height: normalizeHeight(175),
        resizeMode: 'contain',
        borderRadius: 175,
    },
    imageProgressBar: {
        width: normalizeWidth(220),
        height: normalizeWidth(20),
        resizeMode: 'contain',
        alignSelf: 'center',
    },
    imageInstruction: {
        width: normalizeWidth(210),
        height: normalizeWidth(100),
        resizeMode: 'contain',
    },
    imageBannerInstruction1: {
        width: normalizeWidth(250),
        height: normalizeWidth(50),
        resizeMode: 'contain',
        marginVertical: normalizeWidth(10),
    },
    imageBannerInstruction2: {
        width: normalizeWidth(160),
        height: normalizeWidth(80),
        resizeMode: 'contain',
        marginVertical: normalizeWidth(10),
    },
    imageInstructionIcon: {
        marginHorizontal: 3,
        width: normalizeHeight(14),
        height: normalizeHeight(14),
        resizeMode: 'contain',
        bottom: -normalizeHeight(3),
    },
    imageActivationPreambleBanner: {
        width: normalizeHeight(25),
        height: normalizeHeight(25),
        resizeMode: 'contain',
        marginRight: normalizeWidth(10),
    },
    imageModal: {
        width: normalizeWidth(210),
        height: normalizeWidth(100),
        resizeMode: 'contain',
        marginVertical: 15,
    },
    imageCheckMarkIcon: {
        height: normalizeHeight(8),
        width: normalizeHeight(8),
        resizeMode: 'contain',
    },
    imageChevronIcon: {
        height: normalizeHeight(45),
        width: normalizeHeight(45),
        resizeMode: 'contain',
    },
    imageArrowRightIcon: {
        height: normalizeWidth(10),
        width: normalizeWidth(10),
        marginLeft: normalizeWidth(6),
    },
});
