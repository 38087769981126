import * as React from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useReactiveVar, WatchQueryFetchPolicy } from '@apollo/client';
import * as _ from 'lodash';
import moment from 'moment';

import { getLocalizedTexts } from '../../Locales';
import { EnrichedMerchantOffer, EnrichedUserOffer } from '../../api/graphql/fragments/enrichedOffers';
import { RateForDisplay, MerchantOffer, OfferType } from '../../api/graphql/fragments/offers';
import { ApolloClient } from '../../api/graphql/client';
import { activateUserOffer } from '../../api/graphql/mutations/activateUserOffer';
import { favoriteUserOffer } from '../../api/graphql/mutations/favoriteUserOffer';
import { unfavoriteUserOffer } from '../../api/graphql/mutations/unfavoriteUserOffer';
import {
    merchantOfferQuery,
    MerchantOfferQueryResponse,
    MerchantOfferQueryVariables,
} from '../../api/graphql/queries/offers';
import {
    EnrichedUserOfferQueryResponse,
    EnrichedUserOfferQueryVariable,
    enrichedUserOfferQuery,
    MinimalUserOffer,
    MinimalNonOngoingUserOffersQueryResponse,
    MinimalNonOngoingUserOffersQueryVariables,
    minimalNonOngoingUserOffersQuery,
} from '../../api/graphql/queries/enrichedOffers';
import { lastMinimalNonOngoingUserOffersQueryAtVar } from '../../api/graphql/reactiveVariables';
import { PartialMerchantOffer } from '../../api/rest/offers';
import { logAmplitudeEvent } from '../events/amplitudeEvents';
import { logUserEventUtil } from '../events/userEvents';
import { getUserId } from '../common/cognito';
import { getDevStackMode } from '../common/devStackMode';
import { logGoogleTagManagerEvent, GoogleTagManagerEventName } from '../common/tags';
import { AffiliateLinkLoadingTriggeringEventType } from './affiliateLinkLoading';

export type CashbackRate = {
    rateType: RateType;
    nonBoostedCashback: number;
    boostedCashback: number;
    computationType: ComputationType;
    valueMinText: string | null;
    description: string | null;
};

export enum ComputationType {
    variable = 'variable',
    fixed = 'fixed',
}

export enum RateType {
    single = 'single',
    multiple = 'multiple',
}

type PointsRate = {
    rateType: RateType;
    nonBoostedPoints: number;
    boostedPoints: number;
    computationType: ComputationType;
    valueMinText: string;
    description: string | null;
};

export function getOfferSingleCashbackRate(
    offer: EnrichedMerchantOffer | MerchantOffer | PartialMerchantOffer,
    shouldUseValueMinCondition?: boolean
): CashbackRate {
    return convertPointsRateInCashbackRate(getOfferSinglePointsRate(offer, shouldUseValueMinCondition));
}

function getOfferSinglePointsRate(
    offer: EnrichedMerchantOffer | MerchantOffer | PartialMerchantOffer,
    shouldUseValueMinCondition?: boolean
): PointsRate {
    const texts = getLocalizedTexts().home.offers.offerDetails;
    const { computationType, nonBoostedPoints, boostedPoints } = getOfferPointsInfo(offer);
    let valueMinText: string = ``;
    if (shouldUseValueMinCondition) {
        let valueMin: number | undefined | null;
        if (offer.__typename === 'EnrichedMerchantOffer')
            valueMin = offer?.enrichedConditions?.transactionEligibility?.valueMin;
        else if (offer.__typename === 'MerchantOffer' || offer.__typename === 'PartialMerchantOffer')
            valueMin = offer?.conditions?.transactionEligibility?.valueMin;
        if (valueMin) valueMinText += ` ${texts.cashback.valueMinCondition({ valueMin })}`;
    }
    return {
        rateType: RateType.single,
        nonBoostedPoints,
        boostedPoints,
        valueMinText,
        computationType,
        description: null,
    };
}

export function useMultipleCashbackRates(
    offer: EnrichedMerchantOffer | MerchantOffer | PartialMerchantOffer | undefined,
    shouldMergeCashbackRates: boolean
) {
    return React.useMemo(() => {
        if (!offer) return [];
        return getOfferMultipleCashbackRates(offer, shouldMergeCashbackRates);
    }, [offer, shouldMergeCashbackRates]);
}

export function getOfferMultipleCashbackRates(
    offer: EnrichedMerchantOffer | MerchantOffer | PartialMerchantOffer,
    shouldMergeCashbackRates: boolean
): CashbackRate[] {
    const multiplePointsRates = getOfferMultiplePointsRates(offer, shouldMergeCashbackRates);
    return multiplePointsRates.map((pointsRate) => convertPointsRateInCashbackRate(pointsRate));
}

function getOfferMultiplePointsRates(
    offer: EnrichedMerchantOffer | MerchantOffer | PartialMerchantOffer,
    shouldMergeCashbackRates: boolean
): PointsRate[] {
    if (!offer.multipleRatesForDisplay) return [];
    const ratesForDisplay = offer.multipleRatesForDisplay
        .filter((rate) => (rate.points || rate.fixedPoints) && rate.text)
        .map((rate) => processRateForDisplay(rate))
        .sort(
            (firstRate, secondRate) =>
                Math.max(secondRate.nonBoostedPoints, secondRate.boostedPoints) -
                Math.max(firstRate.nonBoostedPoints, firstRate.boostedPoints)
        );
    if (!shouldMergeCashbackRates) return ratesForDisplay;
    /**
     * We group multiple rates when multiple have the same nonBoostedCashback or boostedCashback values
     * by merging descriptions together and by separating the displayed text with an interpunct (U+2027)
     */
    let groupedCashbackRates: PointsRate[] = [];
    for (const rate of ratesForDisplay) {
        const currentRateGroup = parseRateGroup(rate);
        const rateValueToUseForGrouping = rate.boostedPoints > 0 ? rate.boostedPoints : rate.nonBoostedPoints;
        const existingSameRate = groupedCashbackRates.find((groupedRate) => {
            const existingRateGroup = parseRateGroup(groupedRate);
            const existingRateValueToUseForGrouping =
                groupedRate.boostedPoints > 0 ? groupedRate.boostedPoints : groupedRate.nonBoostedPoints;
            return rateValueToUseForGrouping === existingRateValueToUseForGrouping && currentRateGroup === existingRateGroup;
        });
        const category = parseRateCategory(rate);
        if (existingSameRate && category)
            existingSameRate.description = `${existingSameRate.description} \u2027 ${category}`;
        else groupedCashbackRates.push(rate);
    }
    groupedCashbackRates = groupedCashbackRates.sort(
        (firstRate, secondRate) =>
            Math.max(secondRate.nonBoostedPoints, secondRate.boostedPoints) -
            Math.max(firstRate.nonBoostedPoints, firstRate.boostedPoints)
    );
    return groupedCashbackRates;
}

function parseRateGroup(rate: PointsRate): string | undefined {
    if (rate.description?.includes(':')) return rate.description.split(':')[0].trim();
    return undefined;
}

function parseRateCategory(rate: PointsRate): string | null {
    if (rate.description?.includes(':')) return rate.description.split(':')[1].trim();
    return rate.description;
}

function processRateForDisplay(rate: RateForDisplay): PointsRate {
    const { computationType, nonBoostedPoints, boostedPoints } = getOfferPointsInfo(rate);
    return {
        rateType: RateType.multiple,
        nonBoostedPoints,
        boostedPoints,
        valueMinText: ``,
        computationType,
        description: rate.text,
    };
}

function getOfferPointsInfo(offer: EnrichedMerchantOffer | MerchantOffer | PartialMerchantOffer | RateForDisplay): {
    computationType: ComputationType;
    nonBoostedPoints: number;
    boostedPoints: number;
} {
    if (offer.points) {
        if (offer.nonBoostedPoints)
            return {
                computationType: ComputationType.variable,
                nonBoostedPoints: offer.nonBoostedPoints,
                boostedPoints: offer.points,
            };
        else
            return {
                computationType: ComputationType.variable,
                nonBoostedPoints: offer.points,
                boostedPoints: 0,
            };
    } else {
        if (offer.nonBoostedFixedPoints)
            return {
                computationType: ComputationType.fixed,
                nonBoostedPoints: offer.nonBoostedFixedPoints,
                boostedPoints: offer.fixedPoints ?? 0,
            };
        else
            return {
                computationType: ComputationType.fixed,
                nonBoostedPoints: offer.fixedPoints ?? 0,
                boostedPoints: 0,
            };
    }
}

function convertPointsRateInCashbackRate(pointsRate: PointsRate): CashbackRate {
    // 1000 points correspond to 100% of variable cashback, and to 1€ of fixed cashback
    const pointsDividedBy = pointsRate.computationType === ComputationType.variable ? 10 : 1000;
    return {
        rateType: pointsRate.rateType,
        nonBoostedCashback: pointsRate.nonBoostedPoints / pointsDividedBy,
        boostedCashback: pointsRate.boostedPoints / pointsDividedBy,
        computationType: pointsRate.computationType,
        valueMinText: pointsRate.valueMinText,
        description: pointsRate.description,
    };
}

export function activateUserOfferUtil(
    client: ApolloClient,
    offer: MerchantOffer | EnrichedMerchantOffer | Pick<MerchantOffer, 'offerId' | 'type'>
) {
    const { offerId } = offer;
    logAmplitudeEvent({ name: 'Offers - Activated Offer', properties: { type: offer.type, offerId } });
    activateUserOffer(client, offerId, offer.type);
}

export function useActivateOffer() {
    const apolloClient = useApolloClient() as ApolloClient;
    const activateOffer = (offer: MerchantOffer | EnrichedMerchantOffer | Pick<MerchantOffer, 'offerId' | 'type'>) =>
        activateUserOfferUtil(apolloClient, offer);
    return activateOffer;
}

export function useOpenAffiliateLink(triggeringEventType: AffiliateLinkLoadingTriggeringEventType) {
    const apolloClient = useApolloClient() as ApolloClient;
    const openAffiliateLink = (userOffer: EnrichedUserOffer) => {
        const userId = getUserId();
        const { offer } = userOffer;
        if (userId && offer.type === OfferType.Online) {
            if (!userOffer.active) activateUserOfferUtil(apolloClient, offer);
            logGoogleTagManagerEvent({ event: GoogleTagManagerEventName.clickedAffiliateLink });
            const triggeringEventPlatform = 'webApp';
            const triggeringEventTimestamp = Math.round(Date.now() / 1000);
            window.open(
                `/online-offer-activation/${userId}/${offer.offerId}` +
                    (getDevStackMode() ? '/dev' : '') +
                    `?triggeringEventPlatform=${triggeringEventPlatform}` +
                    `&triggeringEventType=${triggeringEventType}` +
                    `&triggeringEventTimestamp=${triggeringEventTimestamp}`
            );
        }
    };
    return openAffiliateLink;
}

export function useMerchantOffer(
    offerId: string,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
): MerchantOffer | undefined {
    const queryResponse = useQuery<MerchantOfferQueryResponse, MerchantOfferQueryVariables>(merchantOfferQuery, {
        variables: { offerId },
        fetchPolicy,
    });
    const offer: MerchantOffer | undefined = React.useMemo(() => queryResponse.data?.offer || undefined, [queryResponse]);
    return offer;
}

export function useUserOffer(
    offerId: string | undefined,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
): EnrichedUserOffer | undefined {
    const queryResponse = useQuery<EnrichedUserOfferQueryResponse, EnrichedUserOfferQueryVariable>(enrichedUserOfferQuery, {
        variables: { offerId: offerId || '' },
        fetchPolicy,
    });
    const userOffer: EnrichedUserOffer | undefined = React.useMemo(
        () => queryResponse.data?.user?.enrichedOffer || undefined,
        [queryResponse]
    );
    return userOffer;
}

const MINIMAL_NON_ONGOING_USER_OFFERS_QUERY_REFETCH_FREQUENCY_SEC = 60;

export function useSearchableUserOffers(): MinimalUserOffer[] | undefined {
    const lastMinimalNonOngoingUserOffersQueryAt = useReactiveVar(lastMinimalNonOngoingUserOffersQueryAtVar);
    const queryResponse = useQuery<MinimalNonOngoingUserOffersQueryResponse, MinimalNonOngoingUserOffersQueryVariables>(
        minimalNonOngoingUserOffersQuery,
        {
            variables: { devMode: false },
            fetchPolicy: 'cache-first',
        }
    );
    React.useEffect(() => {
        if (
            !lastMinimalNonOngoingUserOffersQueryAt ||
            lastMinimalNonOngoingUserOffersQueryAt <
                moment().unix() - MINIMAL_NON_ONGOING_USER_OFFERS_QUERY_REFETCH_FREQUENCY_SEC
        ) {
            queryResponse?.refetch();
            lastMinimalNonOngoingUserOffersQueryAtVar(moment().unix());
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const searchableUserOffers: MinimalUserOffer[] | undefined = React.useMemo(
        () => queryResponse.data?.user?.enrichedOffers?.filter(isOfferDisplayed),
        [queryResponse]
    );
    return searchableUserOffers;
}

export type MinimalUserOfferMap = { [offerId: string]: MinimalUserOffer };

export function useSearchableUserOfferMap(): MinimalUserOfferMap | undefined {
    const searchableUserOffers = useSearchableUserOffers();
    const searchableUserOfferMap: MinimalUserOfferMap | undefined = React.useMemo(() => {
        if (searchableUserOffers === undefined) return undefined;
        return _.keyBy(searchableUserOffers, 'offerId');
    }, [searchableUserOffers]);
    return searchableUserOfferMap;
}

export function isOfferDisplayed(userOffer: MinimalUserOffer | EnrichedUserOffer): boolean {
    if (
        userOffer.offer.type !== OfferType.Online ||
        userOffer.offer.matchToAffiliateTransaction !== true ||
        userOffer.offer.offerId.startsWith('uber-') // We use app-to-app tracking for Uber offers, which is not compatible with the web app
    )
        return false;
    return true;
}

export function useOnPressFavoriteOfferButton(userOffer: EnrichedUserOffer | undefined) {
    const apolloClient = useApolloClient() as ApolloClient;
    const onPress = () => {
        if (userOffer) {
            userOffer.favorited ? unfavoriteUserOffer(apolloClient, userOffer) : favoriteUserOffer(apolloClient, userOffer);
            logUserEventUtil(apolloClient, {
                type: userOffer.favorited ? 'unfavoritedOffer' : 'favoritedOffer',
                payload: {
                    type: userOffer.offer.type,
                    offerId: userOffer.offerId,
                },
            });
        }
    };
    return onPress;
}

const STANDARD_CONDITION_IDS_TO_EXCLUDE = ['card-linked', 'affiliate-link'];

/** @deprecated - New conditions are filtered directly on an offer-basis in Joko Admin */
export function getFilteredEnrichedConditions(userOffer: EnrichedUserOffer) {
    const conditions = (userOffer.offer.enrichedConditions?.conditions || []).filter(
        ({ standardConditionId }) => !standardConditionId || !STANDARD_CONDITION_IDS_TO_EXCLUDE.includes(standardConditionId)
    );
    return conditions;
}
