import * as React from 'react';

const MAX_MOBILE_SCREEN_WIDTH = 500;
export const isMobileDevice = window.innerWidth < MAX_MOBILE_SCREEN_WIDTH;

export type WindowSize = { width: number | undefined; height: number | undefined };

export function useWindowSize(): WindowSize {
    const [windowSize, setWindowSize] = React.useState<WindowSize>({ width: undefined, height: undefined });
    React.useEffect(() => {
        function handleResize() {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}

export type ViewportSize = { width: number; height: number };

export function useViewportSize(): ViewportSize {
    const [viewportSize, setViewportSize] = React.useState<ViewportSize>({
        width: getViewportWidth(),
        height: getViewportHeight(),
    });
    React.useEffect(() => {
        function handleResize() {
            setViewportSize({ width: getViewportWidth(), height: getViewportHeight() });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return viewportSize;
}

function getViewportHeight() {
    if ('visualViewport' in window && window.visualViewport?.height) return window.visualViewport.height;
    else return window.innerHeight;
}

function getViewportWidth() {
    if ('visualViewport' in window && window.visualViewport?.width) return window.visualViewport.width;
    else return window.innerWidth;
}
