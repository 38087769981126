import React from 'react';

import { clearSessionId } from './sessions';

export const DEV_STACK_MODE_LOCAL_STORAGE_KEY = '@AuthenticationStore:devStackMode';

export function getDevStackMode(): boolean {
    try {
        const item: string | null = localStorage.getItem(DEV_STACK_MODE_LOCAL_STORAGE_KEY);
        return item === 'activated';
    } catch {
        return false;
    }
}

export function useIsDevStackMode() {
    const [isDevStack, setIsDevStack] = React.useState<boolean | undefined>();
    React.useEffect(() => {
        try {
            const item: string | null = localStorage.getItem(DEV_STACK_MODE_LOCAL_STORAGE_KEY);
            setIsDevStack(item === 'activated');
        } catch {
            setIsDevStack(false);
        }
    }, []);
    return { isDevStack };
}

export function toggleDevStackMode(): void {
    const devStackMode = getDevStackMode();
    if (devStackMode) localStorage.setItem(DEV_STACK_MODE_LOCAL_STORAGE_KEY, 'deactivated');
    else localStorage.setItem(DEV_STACK_MODE_LOCAL_STORAGE_KEY, 'activated');
    clearSessionId();
}
