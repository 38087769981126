import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BrowserRouter as Router, Redirect, Route, useRouteMatch } from 'react-router-dom';

import IntroPanel from '../../pages/auth/IntroPanel';
import SignInPage from '../../pages/auth/SignInPage';
import FacebookAuthPage from '../../pages/auth/FacebookAuthPage';
import AppleAuthPage from '../../pages/auth/AppleAuthPage';
import GoogleAuthPage from '../../pages/auth/GoogleAuthPage';
import SignUpPage from '../../pages/auth/SignUpPage';
import EmailSignUpPage from '../../pages/auth/EmailSignUpPage';
import ResetPasswordPage from '../../pages/auth/ResetPasswordPage';

function AuthNavigator() {
    let { path } = useRouteMatch();
    return (
        <Router>
            <Route exact path={path}>
                <Redirect to={{ pathname: `${path}/sign-in`, search: window.location.search }} />
            </Route>
            <Route exact path={`${path}/sign-in`}>
                <View style={styles.containerSignIn}>
                    <SignInPage />
                </View>
            </Route>
            <Route path={`${path}/sign-in/facebook`}>
                <FacebookAuthPage />
            </Route>
            <Route path={`${path}/sign-in/apple`}>
                <AppleAuthPage />
            </Route>
            <Route path={`${path}/sign-in/google`}>
                <GoogleAuthPage />
            </Route>
            <Route exact path={`${path}/sign-up`}>
                <View style={styles.container}>
                    <View style={styles.containerColumn}>
                        <IntroPanel />
                    </View>
                    <View style={styles.containerColumn}>
                        <SignUpPage />
                    </View>
                </View>
            </Route>
            <Route exact path={`${path}/sign-up/email`}>
                <View style={styles.container}>
                    <View style={styles.containerColumn}>
                        <IntroPanel />
                    </View>
                    <View style={styles.containerColumn}>
                        <EmailSignUpPage />
                    </View>
                </View>
            </Route>
            <Route path={`${path}/sign-up/facebook`}>
                <Redirect to={{ pathname: `${path}/sign-in/facebook`, search: window.location.search }} />
            </Route>
            <Route path={`${path}/sign-up/apple`}>
                <Redirect to={{ pathname: `${path}/sign-in/apple`, search: window.location.search }} />
            </Route>
            <Route path={`${path}/sign-up/google`}>
                <Redirect to={{ pathname: `${path}/sign-in/google`, search: window.location.search }} />
            </Route>
            <Route exact path={`${path}/reset-password`}>
                <ResetPasswordPage />
            </Route>
        </Router>
    );
}

export default AuthNavigator;

const styles = StyleSheet.create({
    containerSignIn: {
        flex: 1,
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    containerColumn: {
        flex: 1,
    },
});
