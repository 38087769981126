import gql from 'graphql-tag';

import { ApolloClient } from '../client';
import { dataIdFromEnrichedUserOffer, EnrichedUserOffer } from '../fragments/enrichedOffers';
import { favoriteOffersQuery, FavoriteOffersQueryResponse } from '../queries/enrichedOffers';

const unfavoriteUserOfferMutation = gql`
    mutation UnfavoriteUserOffer($offerId: String!) {
        unfavoriteUserOffer(offerId: $offerId) {
            offerId
            favorited
        }
    }
`;

interface UnfavoriteUserOfferMutationResponse {
    __typename: 'Mutation';
    unfavoriteUserOffer: {
        __typename: 'UserOffer';
        offerId: string;
        favorited: boolean | undefined;
    } | null;
}

interface UnfavoriteUserOfferMutationVariables {
    offerId: string;
}

export function unfavoriteUserOffer(client: ApolloClient, userOffer: EnrichedUserOffer) {
    const offerId = userOffer.offerId;
    client.writeFragment<{
        __typename: 'EnrichedUserOffer';
        offerId: string;
        favorited: boolean | undefined;
    }>({
        id: dataIdFromEnrichedUserOffer({ offerId }),
        fragment: gql`
            fragment UnfavoritedEnrichedUserOffer on EnrichedUserOffer {
                offerId
                favorited
            }
        `,
        data: {
            __typename: 'EnrichedUserOffer',
            offerId: offerId,
            favorited: false,
        },
    });
    client.mutate<UnfavoriteUserOfferMutationResponse, UnfavoriteUserOfferMutationVariables>({
        mutation: unfavoriteUserOfferMutation,
        fetchPolicy: 'no-cache',
        variables: { offerId },
    });
    setTimeout(() => updateFavoriteOffersQueryResponseInCache(client, offerId), 500);
}

function updateFavoriteOffersQueryResponseInCache(client: ApolloClient, offerId: string) {
    try {
        const dataInCache = client.readQuery<FavoriteOffersQueryResponse>({
            query: favoriteOffersQuery,
        });
        const favoriteOffers = dataInCache?.user?.enrichedOffers;
        if (favoriteOffers)
            client.writeQuery<FavoriteOffersQueryResponse>({
                query: favoriteOffersQuery,
                data: {
                    __typename: 'Query',
                    user: {
                        __typename: 'User',
                        enrichedOffers: favoriteOffers.filter((offer) => offer.offerId !== offerId || offer.favorited),
                    },
                },
            });
    } catch {}
}
