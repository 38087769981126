import gql from 'graphql-tag';

import { ApolloClient } from '../client';

export const logUserEventMutation = gql`
    mutation LogUserEvent($event: LogUserEventInput!) {
        logUserEvent(event: $event)
    }
`;

interface LogUserEventMutationResponse {
    __typename: 'Mutation';
    logUserEvent: string | null;
}

export type LogUserEventInput = {
    type: string;
    active?: boolean;
    payload?: string;
    timestamp?: number;
};

export function logUserEvent(client: ApolloClient, event: LogUserEventInput) {
    return client.mutate<LogUserEventMutationResponse, { event: LogUserEventInput }>({
        mutation: logUserEventMutation,
        variables: { event },
    });
}
