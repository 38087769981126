import gql from 'graphql-tag';

export const userPointsFragment = gql`
    fragment UserPoints on UserPoints {
        balance
        total
    }
`;

export interface UserPoints {
    __typename: 'UserPoints';
    balance: number;
    total: number;
}

export const dataIdFromUserPoints = (userPoints?: UserPoints) => {
    return `UserPoints:current`;
};
