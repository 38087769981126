import JWT from 'jsonwebtoken';
import jwkToPem from 'jwk-to-pem';

import { getRestApiUrl } from '../../api/config';

/**
 * The `user-web-app` google authentication flow follows the OpenID Connect protocol.
 * As part of this protocol, the provider can use an identity document.
 * This document is available at the following URI and contains key-value pairs which provide details about the provider's configuration.
 * More information is available here: https://developers.google.com/identity/openid-connect/openid-connect#discovery
 */
const GOOGLE_IDENTITY_DOCUMENT_URL = 'https://accounts.google.com/.well-known/openid-configuration';

/**
 * For more information about the specific fields, see: https://openid.net/specs/openid-connect-discovery-1_0.html#ProviderMetadata
 */
export interface GoogleIdentityDocument {
    issuer: string;
    authorization_endpoint: string;
    device_authorization_endpoint: string;
    token_endpoint: string;
    userinfo_endpoint: string;
    revocation_endpoint: string;
    jwks_uri: string;
    response_types_supported: string[];
    subject_types_supported: string[];
    id_token_signing_alg_values_supported: string[];
    scopes_supported: string[];
    token_endpoint_auth_methods_supported: string[];
    claims_supported: string[];
    code_challenge_methods_supported: string[];
    grant_types_supported: string[];
}

export async function getSocialIdentityDocument(): Promise<GoogleIdentityDocument> {
    console.log('Google API: fetching identity document...');
    const identityDocumentResponse = await fetch(GOOGLE_IDENTITY_DOCUMENT_URL);
    console.log('Google API: identity document fetched');
    return identityDocumentResponse.json();
}

export const GOOGLE_OAUTH_CLIENT_ID = '424755138228-4827vp1dnem3ve06dra346mtflgqggma.apps.googleusercontent.com';

export async function exchangeGoogleCodeForIdToken(code: string, redirectUrl: string): Promise<string> {
    try {
        console.log('Google API: exchanging user code for tokens...');
        const headers = new Headers();
        headers.append('Accept', 'application/json');
        const url = new URL(`${getRestApiUrl()}user/exchange-google-code`);
        url.searchParams.append('googleClientId', GOOGLE_OAUTH_CLIENT_ID);
        url.searchParams.append('redirectUrl', redirectUrl);
        url.searchParams.append('code', code);
        const tokenResponse = await fetch(url.toString(), { method: 'GET', headers });
        const tokenResponseJson = await tokenResponse.json();
        console.log('Google API: user code exchanged');
        return tokenResponseJson.id_token;
    } catch (error) {
        throw new GoogleLoginError(error);
    }
}

export class GoogleLoginError extends Error {
    __proto__: GoogleLoginError;
    message: string;
    constructor(message: string) {
        super(`Google login (${message})`);
        this.__proto__ = GoogleLoginError.prototype;
        this.message = message;
    }
}

interface GoogleIdToken extends JWT.JwtPayload {
    sub: string;
    at_hash?: string;
    azp?: string;
    email?: string;
    email_verified?: boolean;
    family_name?: string;
    given_name?: string;
    name?: string;
    picture?: string;
    hd?: string;
    locale?: string;
    nonce?: string;
    profile?: string;
}

export async function verifyAndDecodeGoogleJsonWebToken(
    idJsonWebToken: string,
    identityDocument: GoogleIdentityDocument
): Promise<GoogleIdToken> {
    const decodedIdToken = JWT.decode(idJsonWebToken, { complete: true });
    if (!decodedIdToken?.header?.kid) throw new Error("Google login: missing 'kid' key in token header");
    console.log('Google API: obtaining public keys to verify integrity of Json Web Token received...');
    const googlePublicKeys = await fetch(identityDocument.jwks_uri)
        .then((response) => response.json())
        .then((json) => json.keys);
    const googlePublicKey = await matchGooglePublicKey(decodedIdToken.header.kid, googlePublicKeys);
    const verifiedIdToken = JWT.verify(idJsonWebToken, googlePublicKey, { algorithms: ['RS256'] });
    if (!verifiedIdToken || !verifiedIdToken.sub || typeof verifiedIdToken.sub !== 'string')
        throw new Error(`Google login: missing 'sub' key in verified JWT Token`);
    return verifiedIdToken as GoogleIdToken;
}

async function matchGooglePublicKey(keyId: string, googlePublicKeys: jwkToPem.JWK[]): Promise<string> {
    console.log('Google login: matching public key...');
    const matchingKey = googlePublicKeys.find((key: any) => key?.kid === keyId);
    if (!matchingKey) throw new Error('Google login: no matching key found');
    console.log('Google login: public key matched');
    return jwkToPem(matchingKey);
}
